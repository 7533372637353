import React from 'react';
import { ResponsivePie } from '@nivo/pie';

import Loading from '../../components/Loading';
import Tooltip from '../../components/Tooltip';
import { formatNum } from '../../utils/helpers';
import { STIFFNESS, DUMPING, NIVO_THEMED } from '../../VARS';

const FrequencyPie = ({
  type,
  data,
  chartAgainst,
  colorBy,
  onHover,
  onOut,
  onClick,
}) => {
  if (!data.length)
    return type === 'regions' ? (
      <div className="loading is-pie-chart">
        <Loading />
      </div>
    ) : null;

  return (
    <div
      className={`${type}-pie ${type}-pie__is-${chartAgainst}`}
      style={{
        height: window.innerWidth < 1280 ? '200px' : '240px',
      }}
    >
      <ResponsivePie
        data={data}
        innerRadius={type === 'zero' ? 0.7 : type === 'regions' ? 0.6 : 0.8}
        margin={
          type === 'zero'
            ? {
                top: 50,
                right: 45,
                bottom: 70,
                left: 55,
              }
            : type === 'regions'
            ? {
                top: 20,
                right: 15,
                bottom: 40,
                left: 25,
              }
            : {
                top: 33,
                right: 28,
                bottom: 53,
                left: 38,
              }
        }
        padAngle={0.5}
        fit={false}
        colors={colorBy}
        enableRadialLabelss={type === 'lists' ? true : false}
        enableRadialLabels={false}
        radialLabel="label"
        radialLabelsSkipAngle={0}
        radialLabelsTextColor="black"
        radialLabelsLinkOffset={2}
        radialLabelsLinkDiagonalLength={20}
        radialLabelsLinkHorizontalLength={10}
        enableSlicesLabelss={type === 'regions' ? true : false}
        enableSlicesLabels={type === 'zero' ? false : true}
        sliceLabel="label"
        slicesLabelsSkipAngle={type === 'regions' ? 25 : 0}
        slicesLabelsTextColor="black"
        motionStiffness={STIFFNESS}
        motionDamping={DUMPING}
        theme={NIVO_THEMED}
        // onMouseEnter={onHover}
        // onMouseLeave={onOut}
        tooltip={({ id /* region name */, value }) => (
          <Tooltip title={id}>
            <div className="columns">
              <div className="column">
                <div className={`charttip__figure is-${chartAgainst}`}>
                  {formatNum(value)}
                  {chartAgainst !== 'Employees' && chartAgainst !== 'Operations'
                    ? ' M€'
                    : ''}
                </div>
                <div className="charttip__caption">{chartAgainst}</div>
              </div>
            </div>
          </Tooltip>
        )}
        onMouseEnter={onHover}
        onMouseLeave={onOut}
        onClick={onClick}
      />
    </div>
  );
};

export default FrequencyPie;
