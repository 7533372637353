import React from 'react';

// https://docs.google.com/document/d/16rabgVr0K8lPNo53fNn0Gv8idap9d00ebUR2hKxS3vE/edit
const banks = [
  ['1', 1, 'HSBC Holdings Plc', 'UK'],
  ['2', 2, 'BNP Paribas SA', 'France'],
  ['3', 3, 'Crédit Agricole Group', 'France'],
  ['4', 4, 'Deutsche Bank AG', 'Germany'],
  ['5', 5, 'Banco Santander SA', 'Spain'],
  ['6', 6, 'Barclays Plc', 'UK'],
  ['7', 7, 'Société Générale SA', 'France'],
  ['8', 8, 'Groupe BPCE', 'France'],
  ['9', 9, 'Lloyds Banking Group Plc', 'UK'],
  ['10', 10, 'ING Groep NV', 'Netherlands'],
  ['11', 11, 'UniCredit SpA', 'Italy'],
  ['12', 12, 'Royal Bank of Scotland Group Plc', 'UK'],
  ['13', 13, 'Intesa Sanpaolo SpA', 'Italy'],
  ['14', 14, 'Crédit Mutuel Group', 'France'],
  ['17', 15, 'Banco Bilbao Vizcaya Argentaria SA', 'Spain'],
  ['18', 16, 'Rabobank', 'Netherlands'],
  ['19', 17, 'Nordea Group', 'Finland'],
  ['20', 18, 'Standard Chartered Plc', 'UK'],
  ['21', 19, 'DZ Bank AG', 'Germany'],
  ['22', 20, 'Danske Bank A/S', 'Denmark'],
  ['23', 21, 'Commerzbank AG', 'Germany'],
  ['26', 22, 'ABN AMRO Group NV', 'Netherlands'],
  ['28', 23, 'KBC Group NV', 'Belgium'],
  ['29', 24, 'Svenska Handelsbanken AB', 'Sweden'],
  ['31', 25, 'Nationwide Building Society', 'UK'],
  ['32', 26, 'Skandinaviska Enskilda Banken AB', 'Sweden'],
  ['33', 27, 'Landesbank Baden-Wuerttemberg', 'Germany'],
  ['35', 28, 'Swedbank AB', 'Sweden'],
  ['36', 29, 'Banco de Sabadell SA', 'Spain'],
  ['37', 30, 'Bankia SA (BFA Sociedad Tenedora de Acciones)', 'Spain'],
  ['38', 31, 'Erste Group Bank AG', 'Austria'],
  ['39', 32, 'Bayerische Landesbank', 'Germany'],
  ['41', 33, 'Nykredit AS', 'Denmark'],
  ['44', 34, 'Belfius Banque SA', 'Belgium'],
  ['45', 35, 'Norddeutsche Landesbank Girozentrale', 'Germany'],
  // ['47', 36, 'Landesbank Hessen-Thueringen Girozentrale', 'Germany'],
  ['47', 36, 'Helaba', 'Germany'],
  ['49', 37, 'Banca Monte dei Paschi di Siena SpA', 'Italy'],
  ['50', 38, 'OP Financial Group', 'Finland'],
  ['> 50', 39, 'KfW IPEX', 'Germany'],
];

const BankListTable = () => {
  return (
    <table className="table is-narrow is-hoverable is-fullwidth">
      <thead>
        <tr>
          <th>Rank</th>
          <th>Number on the dataset</th>
          <th>Bank</th>
          <th>Country of headquarter</th>
        </tr>
      </thead>
      <tbody>
        {banks.map(bank => (
          <tr key={bank[0]}>
            <td>{bank[0]}</td>
            <td>{bank[1]}</td>
            <td>{bank[2]}</td>
            <td>{bank[3]}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default BankListTable;
