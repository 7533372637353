import React, { Component } from 'react';

import _ from '../../utils/lodashImporter';
import { formatNum } from '../../utils/helpers';

class AggregateNumbers extends Component {
  constructor(props) {
    super(props);

    this.year = null;
    this.data = { '*': [] };
  }

  _mapData(data, selectedBank) {
    const mappedData = [
      {
        key: 'Turnover',
      },
      {
        key: 'Profit',
      },
      {
        key: 'Tax on profit or loss',
      },
      {
        key: 'Employees',
      },
    ];

    let bankData;
    if (selectedBank !== '*') {
      bankData = _.find(data, { bank: selectedBank });
    }

    if (selectedBank === '*') {
      mappedData.forEach(serie => {
        serie.total = data.reduce(
          (sum, bank) =>
            sum +
            bank.Totals[serie.key.replace('Tax on profit or loss', 'Tax')],
          0
        );
      });
    } else {
      mappedData.forEach(serie => {
        serie.total =
          bankData.Totals[serie.key.replace('Tax on profit or loss', 'Tax')];
      });
    }

    return mappedData;
  }

  render() {
    if (!this.props.data) return null;

    // memoize data on first render, and on year change
    if (
      (!this.data[this.props.selectedBank] ||
        !this.data[this.props.selectedBank].length ||
        this.year !== this.props.year) &&
      this.props.data
    ) {
      this.data[this.props.selectedBank] = this._mapData(
        this.props.data,
        this.props.selectedBank
      );
      this.year = this.props.year;
    }

    return (
      <div>
        {this.data[this.props.selectedBank].map((serie, index) => {
          const marginTop = index === 0 ? 30 : 0;
          const height = index === 3 ? 90 : 60;
          return (
            <div
              className={`aggregate-number aggregate-number__${serie.key}`}
              style={{
                height: `${height}px`,
                marginTop: `${marginTop}px`,
                marginLeft: '-0.5rem',
              }}
              key={serie.key}
            >
              <strong>
                {formatNum(serie.total)}{' '}
                {serie.key !== 'Employees' ? <span>M€</span> : null}
              </strong>
              <small>
                {serie.key}{' '}
                {/*this.props.selectedBank !== '*' ? (
                  <span>
                    {formatPerc(
                      serie.total /
                        this.data['*'].find(
                          allSerie => allSerie.key === serie.key
                        ).total
                    )}{' '}
                    of total
                  </span>
                        ) : null*/}
              </small>
            </div>
          );
        })}
      </div>
    );
  }
}

export default AggregateNumbers;
