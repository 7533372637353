import React, { Component } from 'react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    console.error(error, info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <article className="message is-warning is-small">
          <div className="message-header">
            <p>Whoops</p>
          </div>
          <div className="message-body content">
            <p>
              Something went wrong.
              <br />
              Please try to force-reload this page:
            </p>
            <ul>
              <li>
                On <b>Windows</b>, press{' '}
                <span className="tag">
                  <b>Ctrl</b>
                </span>{' '}
                +{' '}
                <span className="tag">
                  <b>F5</b>
                </span>
              </li>
              <li>
                On <b>Mac</b>, hold down{' '}
                <span className="tag">
                  <b>⌘ Cmd</b>
                </span>
                ,{' '}
                <span className="tag">
                  <b>⇧ Shift</b>
                </span>{' '}
                and{' '}
                <span className="tag">
                  <b>R</b>
                </span>
              </li>
              <li>On other devices simply refresh the page</li>
            </ul>
          </div>
        </article>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
