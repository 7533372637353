import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const ModalAnomalies = ({ isOpen, onClose }) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          key="modal"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ type: 'spring', stiffness: 100, damping: 16 }}
          style={{ zIndex: 40, position: 'relative' }}
        >
          <div className="modal is-active">
            <div className="modal-background"></div>
            <div className="modal-card">
              <header className="modal-card-head">
                <p className="modal-card-title">Reporting Anomalies</p>
                <button
                  className="delete"
                  aria-label="close"
                  onClick={onClose}
                ></button>
              </header>
              <section className="modal-card-body">
                <table className="stylish-table table">
                  <thead>
                    <tr>
                      <th />
                      <th>2018</th>
                      <th>2019</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map(row => (
                      <tr>
                        {row.map((cell, idx) => {
                          if (idx === 0) return <th>{cell}</th>;
                          if (typeof cell === 'string') return <td>{cell}</td>;
                          return (
                            <td>
                              <ol>
                                {cell.map(item => (
                                  <li>{item}</li>
                                ))}
                              </ol>
                            </td>
                          );
                        })}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </section>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default ModalAnomalies;

const data = [
  [
    'Santander',
    [
      'Including the information relating to a branch in the Cayman Islands the profits of which are taxed in full in Brazil. The contribution of this branch profit before tax from continuing operations 2018 is EUR 613 million.',
      'Including the information on a branch in the Bahamas the profits of which are taxed in full in Mexico. In 2018 the contribution of this branch to operating profit before tax from continuing operations was EUR - 2 million.',
      'Including the information relating to the branch, closed on 31 December, in the UK and is taxed both in the UK and in Portugal. In 2018 the contribution of this branch to profit before tax from continuing operations was EUR 32 million.',
    ],
    'Including the information relating to a branch in the Cayman Islands the profits of which are taxed in full in Brazil. The contribution of this branch profit before tax from continuing operations 2019 is EUR 691 million. ',
  ],
  [
    'Erste',

    'Information on number of employees in FTE does not include those employed in savings banks subsidiaries.',
    'Same as 2018',
  ],
  [
    'NBS',
    '',
    'The numbers for UK profit include the bank‘s profits for Isle of Man.',
  ],
  [
    'Standard Chartered',
    [
      'Hong Kong includes the results of 23 entities which were incorporated in the Marshall Islands, 14 entities which were incorporated in Singapore, 4 entities which were incorporated in the British Virgin Islands and 3 entities which were incorporated in Malaysia, but which are managed and controlled in Hong Kong.',
      'Singapore includes the results of 6 entities which were incorporated in the Cayman Islands and 2 entities which were incorporated in Jersey, but which are managed and controlled in Singapore.',
      'UK includes 5 entities incorporated in the Netherlands, 1 entity incorporated in Jersey and 1 entity incorporated in the Isle of Man. These are managed and controlled in the UK.',
      'Ireland includes the results of 1 entity incorporated in the Isle of Man which is managed and controlled in Ireland.',
    ],
    [
      'Hong Kong includes the results of 23 entities which were incorporated in the Marshall Islands, 3 entities which were incorporated in Malaysia and 2 entities which were incorporated in the British Virgin Islands, but which are managed and controlled in Hong Kong.',
      'Singapore includes the results of 5 entities which were incorporated in the Cayman Islands, 2 entities which were incorporated in Jersey and 1 entity incorporated in Korea, but which are managed and controlled in Singapore.',
      'UK includes 5 entities incorporated in the Netherlands, 1 entity incorporated in Jersey and 1 entity incorporated in the Isle of Man. These are managed and controlled in the UK.',
      'Ireland includes the results of 1 entity incorporated in the Isle of Man which is managed and controlled in Ireland. ',
    ],
  ],
  [
    'Société Generale',
    [
      'Income from the entity located in Bermuda is taxed in France.',
      'Income from the entity located in Curacao is taxed in France.',
      'Income from entities located in Cayman Islands is taxed in the United States and in Japan.',
    ],
    'Same as 2018',
  ],
];
