import React from 'react';
import Loader from 'react-loader-spinner';

import { COLOR_TURNOVER } from '../VARS';

const Loading = () => {
  return <Loader type="Grid" color={COLOR_TURNOVER} height={20} width={20} />;
};

export default Loading;
