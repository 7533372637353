import React, { Component } from 'react';
import { ResponsiveScatterPlot } from '@nivo/scatterplot';
// import { ThemeProvider } from '@nivo/core';

import Loading from '../../components/Loading';
import Info from '../../components/Info';
import Tooltip from '../../components/Tooltip';
import _ from '../../utils/lodashImporter';
import d3 from '../../utils/d3Importer';
import { formatNum, formatPerc } from '../../utils/helpers';
import {
  COLOR_BLACK,
  COLOR_GRAY_DARKER,
  COLOR_PROFITABILITY,
  COLOR_PRODUCTIVITY,
  COLOR_TAXATION,
  COMPUTE,
  STIFFNESS,
  DUMPING,
  NIVO_THEMED,
} from '../../VARS';
import circleSize from '../../images/circle-size.svg';

class BanksScatter extends Component {
  constructor(props) {
    super(props);
    this.scatterRef = React.createRef();

    this.state = {
      zoomedIn: false,
      prevChartAgainst: null,
    };

    // defaults
    this.series = [
      'Bank',
      'Country',
      'Gray list country',
      'Black list country',
    ];
    this.colorList = [
      'transparent',
      'transparent',
      COLOR_GRAY_DARKER,
      COLOR_BLACK,
    ];
    this.colorByDefault = ({ id }) => {
      const index = this.series.indexOf(id);
      return this.colorList[index].replace('1)', '0.85)');
    };

    // store computed
    this.year = null;
    this.mappedData = {};
    // this.annotations = {};
    this.colorBy = this.colorByDefault;
    this.domain = {
      x: ['auto', 'auto'],
      y: ['auto', 'auto'],
      prevBank: '*',
      prevAgainst: null,
      prevZoom: false,
    };

    // store input
    this.hoveredBank = null;

    this.handleZoomToggle = this.handleZoomToggle.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    // Reset any parts of state that are tied to that 'chartAgainst'
    if (props.chartAgainst !== state.prevChartAgainst) {
      return {
        prevChartAgainst: props.chartAgainst,
        zoomedIn: false,
      };
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    // Fix tooltip bug staying visible when clicking on a bank
    try {
      let tooltip;
      if (
        this.props.chartAgainst !== prevProps.chartAgainst ||
        this.props.selectedBank !== prevProps.selectedBank
      ) {
        tooltip = this.scatterRef.current.firstChild.firstChild.lastChild;
        if (tooltip && tooltip.matches('div')) {
          tooltip.firstChild.style.opacity = '0';
        }
      }
    } catch (error) {
      // console.error(error);
    }

    // Force zoom out
    if (this.props.selectedBank !== prevProps.selectedBank) {
      this.setState({ zoomedIn: false });
    }
  }

  handleZoomToggle() {
    this.setState({ zoomedIn: !this.state.zoomedIn });
  }

  _getDomain(chartAgainst, selectedBank, zoomedIn, year) {
    // Don't always recompute
    if (
      this.domain.prevBank === selectedBank &&
      this.domain.prevAgainst === chartAgainst &&
      this.domain.prevZoom === zoomedIn &&
      this.domain.prevYear === year
    )
      return this.domain;

    let data, extentX, extentY;

    // Compute extent
    data = this.mappedData[chartAgainst][selectedBank];

    // data[0]: 'Banks' - data[1]: '(white) Countries
    if (selectedBank === '*') {
      extentX = d3.extent(data[0].data, bank => bank.x);
      extentY = d3.extent(data[0].data, bank => bank.y);
    } else {
      extentX = d3.extent(data[1].data, bank => bank.x);
      extentY = d3.extent(data[1].data, bank => bank.y);
    }

    // extentY = d3.extent(data[1].data.concat(data[2].data), bank => bank.y);

    // Zoomed in?
    if (zoomedIn) {
      extentX[1] = extentX[1] * 0.25;
      extentY[1] = extentY[1] * 0.5;
    }

    // Add some air below 0
    if (extentX[0] >= 0)
      extentX[0] = -(Math.abs(extentX[0] - extentX[1]) * 0.05);
    if (extentY[0] >= 0)
      extentY[0] = -(Math.abs(extentY[0] - extentY[1]) * 0.05);

    if (zoomedIn) {
      extentY[0] = -(extentY[1] * 0.05);
    }

    // Update cached
    this.domain.x = extentX;
    this.domain.y = extentY;
    this.domain.prevBank = selectedBank;
    this.domain.prevAgainst = chartAgainst;
    this.domain.prevZoom = zoomedIn;
    this.domain.prevYear = year;

    return this.domain;
  }

  _mapData(data, chartAgainst, selectedBank /*, cases = []*/) {
    // Shorthands
    const dividend = COMPUTE[chartAgainst].dividend;
    const divisor = COMPUTE[chartAgainst].divisor;

    // Build the array holding the summary for each bank
    const mapped = [
      {
        id: 'Bank',
        data: [],
      },
      {
        id: 'Country',
        data: [],
      },
      {
        id: 'Gray list country',
        data: [],
      },
      {
        id: 'Black list country',
        data: [],
      },
      {
        id: '0 Employees',
        data: [],
      },
    ];
    let countries;

    if (selectedBank === '*') {
      mapped[0].data = data.map(bank => ({
        label: bank.bank,
        size: 10 + bank.countries.length / 2,
        x: bank.Totals[divisor] / bank.countries.length, // average
        y:
          chartAgainst === 'Productivity'
            ? (bank.Totals[dividend] * 1) / bank.Totals[divisor]
            : bank.Totals[dividend] / bank.Totals[divisor],
      }));
    } else {
      countries = _.find(data, { bank: selectedBank }).countries;
      countries.forEach(country => {
        const metaCountry = _.find(this.props.countryMeta, {
          name: country.country,
        });
        const list =
          country.country === 'Other'
            ? 'others'
            : metaCountry
            ? metaCountry.list
            : false;
        if (!list) {
          console.warn(
            '[TaxTracker] Could not find at which list country "' +
              country.country +
              '" belongs'
          );
          return;
        }

        // no Infinity/NaN
        if (country.datapoints[divisor] === 0) {
          mapped[4].data.push({
            label: country.country,
            dividend: country.datapoints[dividend],
          });
          return;
        }

        const item = {
          label: country.name,
          size: 8,
          x: country.datapoints[divisor],
          y:
            chartAgainst === 'Productivity'
              ? (country.datapoints[dividend] * 1) / country.datapoints[divisor]
              : country.datapoints[dividend] / country.datapoints[divisor],
        };
        switch (list) {
          case 'others':
            mapped[1].data.push(item);
            break;

          case 'grey':
            mapped[2].data.push(item);
            break;

          case 'black':
            mapped[3].data.push(item);
            break;

          default:
            break;
        }
      });
    }

    // Add cases & annotations if in "All banks" view
    // if (selectedBank === '*' && cases.length) {
    //   this.annotations[chartAgainst] = [];
    //   cases.forEach(bank => {
    //     // Reverse engineer 'cause I'm losing control
    //     const countryName = bank.name.split(' in ')[1];
    //     const country = _.find(this.props.countryMeta, { name: countryName });
    //     const list =
    //       countryName === 'Other' ? 'others' : country ? country.list : false;
    //     if (!list) {
    //       console.error(
    //         '[AppError] Could not find at which list country "' +
    //           countryName +
    //           '" belongs'
    //       );
    //       return;
    //     }
    //     const item = {
    //       label: bank.name,
    //       size: 8,
    //       x: bank.datapoints[divisor],
    //       y:
    //         chartAgainst === 'Productivity'
    //           ? (bank.datapoints[dividend] * 1) / bank.datapoints[divisor]
    //           : bank.datapoints[dividend] / bank.datapoints[divisor],
    //     };

    //     switch (list) {
    //       case 'others':
    //         mapped[1].data.push(item);
    //         break;

    //       case 'grey':
    //         mapped[2].data.push(item);
    //         break;

    //       case 'black':
    //         mapped[3].data.push(item);
    //         break;

    //       default:
    //         break;
    //     }

    //     // Add annotation
    //     this.annotations[chartAgainst].push({
    //       type: 'label',
    //       x: bank.datapoints[divisor],
    //       y:
    //         chartAgainst === 'Productivity'
    //           ? (bank.datapoints[dividend] * 1) / bank.datapoints[divisor]
    //           : bank.datapoints[dividend] / bank.datapoints[divisor],
    //       dx: 0,
    //       dy: 0,
    //       note: {
    //         label: bank.name,
    //         orientation: 'leftRight',
    //         wrap: 200,
    //         align: 'top',
    //         padding: 8,
    //       },
    //     });
    //   });
    // }

    return mapped;
  }

  render() {
    const {
      year,
      data,
      chartAgainst,
      // cases,
      height,
      selectedBank,
    } = this.props;

    if (!data)
      return (
        <div className="loading is-scatter-chart">
          <Loading />
        </div>
      );

    // Map/format data so that ScatterPlot likes it
    if (!this.mappedData[chartAgainst]) {
      this.mappedData[chartAgainst] = {};
    }
    // memoize data on first render, and on year change
    if (!this.mappedData[chartAgainst][selectedBank] || this.year !== year) {
      this.mappedData[chartAgainst][selectedBank] = this._mapData(
        data,
        chartAgainst,
        selectedBank
        // cases
      );
    }

    // Also, always map for "All banks" 'cause we'll be interrogating it
    // memoize data on first render, and on year change
    if (!this.mappedData[chartAgainst]['*'] || this.year !== year) {
      this.mappedData[chartAgainst]['*'] = this._mapData(
        data,
        chartAgainst,
        '*'
        // cases
      );
    }

    this.year = year;

    // memoize color list
    let mainColor = '#fff';
    switch (chartAgainst) {
      case 'Productivity':
        mainColor = COLOR_PRODUCTIVITY.replace('1)', '0.85)');
        break;

      case 'Profitability':
        mainColor = COLOR_PROFITABILITY.replace('1)', '0.85)');
        break;

      case 'Taxation':
        mainColor = COLOR_TAXATION.replace('1)', '0.85)');
        break;

      default:
        break;
    }
    this.colorList = [
      mainColor,
      mainColor,
      COLOR_GRAY_DARKER.replace('1)', '0.85)'),
      COLOR_BLACK.replace('1)', '0.85)'),
    ];

    // memoize hovered
    /* if (this.hoveredBank !== hoveredBank) {
      this.hoveredBank = hoveredBank;
      this.colorBy =
        hoveredBank === null
          ? this.colorByDefault
          : (hoveredBank => {
              return datum => {
                console.log(datum);
                const index = this.series.indexOf(datum.id);
                let color = this.colorList[index];

                const bankName = / in /.test(datum.id)
                  ? data.id.match(/^([\w\s]+) in /)[1]
                  : data.id;

                if (bankName !== hoveredBank) {
                  color = color.replace('1)', '0.5)');
                } else {
                  color = color.replace('1)', '0.85)');
                }

                return color;
              };
            })(this.hoveredBank);
    } */

    // Some "groups" could be empty: adapt accordingly
    const finalData = [];
    const finalColors = [];
    for (let i = 0; i < 4; i++) {
      if (this.mappedData[chartAgainst][selectedBank][i].data.length) {
        finalData.push(this.mappedData[chartAgainst][selectedBank][i]);
        finalColors.push(this.colorList[i]);
      }
    }

    const domain = this._getDomain(
      chartAgainst,
      selectedBank,
      this.state.zoomedIn,
      year
    );

    const classes =
      selectedBank === '*'
        ? 'overlayed-container scatter-risk-banks'
        : 'overlayed-container scatter-risk-banks is-countries';

    const AreaLayer =
      chartAgainst !== 'Taxation'
        ? () => null
        : ({ xScale, yScale, innerWidth }) => {
            return (
              <g transform={`translate(${xScale(0)} ${yScale(0.05)})`}>
                <g style={{ mixBlendMode: 'multiply' }}>
                  <rect
                    width={innerWidth - xScale(0)}
                    height={yScale(0) - yScale(0.05)}
                    fill="url(#paint0_linear)"
                    fillOpacity="0.25"
                  />
                </g>
                <defs>
                  <linearGradient
                    id="paint0_linear"
                    x1="293.5"
                    y1="0"
                    x2="293.5"
                    y2="59"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#568DC5" />
                    <stop offset="1" stopColor="#568DC5" stopOpacity="0" />
                  </linearGradient>
                </defs>
                <g transform={`translate(${innerWidth - xScale(0) - 2} -23)`}>
                  <g filter="url(#filter0_d)">
                    <path d="M4 23H10V17L4 23Z" fill="#F9F9F9" />
                    <path
                      d="M10 7C10 4.79086 11.7909 3 14 3H76C78.2091 3 80 4.79086 80 7V19C80 21.2091 78.2091 23 76 23H10V7Z"
                      fill="#F9F9F9"
                    />
                    <text
                      style={{
                        fontFamily: NIVO_THEMED.fontFamily,
                        fontSize: 12,
                        fontWeight: 'bold',
                      }}
                      fill="#4F4F4F"
                      textAnchor="middle"
                      dominantBaseline="middle"
                      x="45"
                      y="14"
                    >
                      &lt; 5%
                    </text>
                  </g>
                  <defs>
                    <filter
                      id="filter0_d"
                      x="0"
                      y="0"
                      width="84"
                      height="28"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      />
                      <feOffset dy="1" />
                      <feGaussianBlur stdDeviation="2" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow"
                        result="shape"
                      />
                    </filter>
                  </defs>
                </g>
              </g>
            );
          };

    const nonVisualizable = this.mappedData[chartAgainst][selectedBank][4].data;
    const nonVisualizableNonOther = nonVisualizable.filter(
      item => item.label !== 'Other'
    );

    return (
      // <ThemeProvider theme={NIVO_THEMED}>
      <div
        className={classes}
        style={{ height: `${height}px` }}
        ref={this.scatterRef}
      >
        <ResponsiveScatterPlot
          data={finalData}
          colors={finalColors}
          height={Math.floor(height)}
          nodeSize={d => (d.size ? d.size : 10)}
          margin={{
            top: 82,
            right: 80,
            bottom: 80,
            left: 80,
          }}
          enableGridX={true}
          enableGridY={true}
          axisBottom={{
            orient: 'bottom',
            tickSize: 0,
            tickPadding: 25,
            tickRotation: 0,
            tickValues: 2,
            format: formatNum,
            legend: null,
          }}
          axisLeft={{
            orient: 'left',
            tickSize: 0,
            tickPadding: 20,
            tickRotation: 0,
            tickValues: 2,
            format: chartAgainst === 'Productivity' ? formatNum : formatPerc,
            legend: null,
          }}
          markers={[
            {
              axis: 'x',
              value: 0,
              lineStyle: {
                stroke: 'rgba(0, 0, 0, 0.2)',
                strokeWidth: 2,
              },
              legend: chartAgainst
                .toUpperCase()
                .replace('TAXATION', 'EFFECTIVE TAX RATE'),
              textStyle: {
                fontSize: '11px',
                fontWeight: 'bold',
                fill: '#4d4d4d',
              },
              legendOffsetY: 0,
              legendOffsetX: -11,
              legendPosition: 'top-right',
              legendOrientation: 'vertical',
            },
            {
              axis: 'y',
              value: 0,
              lineStyle: {
                stroke: 'rgba(0, 0, 0, 0.2)',
                strokeWidth: 2,
              },
              legend: COMPUTE[chartAgainst].divisor.toUpperCase(),
              textStyle: {
                fontSize: '11px',
                fontWeight: 'bold',
                fill: '#4d4d4d',
              },
              legendOffsetY: -11,
              legendOffsetX: 0,
              legendPosition: 'top-right',
            },
          ]}
          motionStiffness={STIFFNESS}
          motionDamping={DUMPING}
          xScale={{
            type: 'linear',
            min: domain.x[0],
            max: domain.x[1],
          }}
          yScale={{
            type: 'linear',
            min: domain.y[0],
            max: domain.y[1],
          }}
          useMesh={false}
          // annotations={
          //   selectedBank === '*' ? this.annotations[chartAgainst] : []
          // }
          tooltip={({ node }) => {
            const {
              data: { label, x, y },
            } = node;
            const isLocalBank = / in /.test(label);
            let bankName,
              bank,
              countryName,
              countryMeta = {
                nominalTR2015: 0,
                nominalTR2016: 0,
                nominalTR2017: 0,
                nominalTR2018: 0,
                nominalTR2019: 0,
              };
            if (isLocalBank) {
              // get bank data
              bankName = label.match(/^(.+) in /)[1];
              try {
                bank = _.find(this.mappedData[chartAgainst]['*'][0].data, {
                  label: bankName,
                });
              } catch (error) {
                bank = { y: 0, x: 0, label: '' };
                console.warn(
                  `[TaxTracker] bank ${bankName} not found in this.mappedData[chartAgainst]['*'][0].data`
                );
              }

              // In Taxation view, compare to nominal TR
              if (chartAgainst === 'Taxation') {
                countryName = label.match(/^(.+) in (.+)$/)[2];
                countryMeta = _.find(this.props.countryMeta, {
                  name: countryName,
                });
              }
            }
            return (
              <div style={NIVO_THEMED.tooltip.container}>
                <Tooltip title={label} theme={NIVO_THEMED}>
                  {/* row 1 */ isLocalBank ? (
                    <div className="columns">
                      <div className="column is-one-third">
                        <div
                          className={`charttip__figure is-large is-${chartAgainst}`}
                        >
                          {chartAgainst === 'Productivity'
                            ? formatNum(y)
                            : formatPerc(y)}
                        </div>
                        <div className="charttip__caption">
                          {chartAgainst === 'Productivity'
                            ? chartAgainst + ' (M€/FTE)'
                            : chartAgainst === 'Taxation'
                            ? 'Effective tax rate'
                            : chartAgainst}
                        </div>
                      </div>
                      <div className="column is-two-thirds">
                        <div
                          className={`charttip__figure is-large is-${chartAgainst}`}
                        >
                          {chartAgainst === 'Taxation'
                            ? formatPerc(countryMeta[`nominalTR${year}`])
                            : chartAgainst === 'Productivity'
                            ? formatNum(bank.y)
                            : formatPerc(bank.y)}
                        </div>
                        <div className="charttip__caption">
                          {chartAgainst === 'Taxation'
                            ? `Nominal tax rate in ${countryName} in ${year}`
                            : `${chartAgainst} for ${bank.label}`}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="columns">
                      <div className="column">
                        <div
                          className={`charttip__figure is-large is-${chartAgainst}`}
                        >
                          {chartAgainst === 'Productivity'
                            ? formatNum(y)
                            : formatPerc(y)}
                        </div>
                        <div className="charttip__caption">
                          {chartAgainst === 'Productivity'
                            ? chartAgainst + ' (M€/FTE)'
                            : chartAgainst === 'Taxation'
                            ? 'Effective tax rate'
                            : chartAgainst}
                        </div>
                      </div>
                    </div>
                  )}

                  {/* row 2 */ isLocalBank ? (
                    <div className="columns">
                      <div className="column is-one-third">
                        <div
                          className={`charttip__figure is-${COMPUTE[chartAgainst].dividend}`}
                        >
                          {formatNum(x * y)}
                          {COMPUTE[chartAgainst].dividend !== 'Employees'
                            ? ' M€'
                            : null}
                        </div>
                        <div className="charttip__caption">
                          {COMPUTE[chartAgainst].dividend.replace(
                            'Tax',
                            'Taxes paid'
                          )}
                        </div>
                      </div>
                      <div className="column is-two-thirds">
                        <div
                          className={`charttip__figure is-${COMPUTE[chartAgainst].dividend}`}
                        >
                          {formatNum(bank.x * bank.y)}
                          {COMPUTE[chartAgainst].dividend !== 'Employees'
                            ? ' M€'
                            : null}
                        </div>
                        <div className="charttip__caption">
                          {`Average* ${COMPUTE[chartAgainst].dividend
                            .replace('Tax', 'Taxes paid')
                            .toLowerCase()} by ${bank.label}`}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="columns">
                      <div className="column is-half">
                        <div
                          className={`charttip__figure is-${COMPUTE[chartAgainst].dividend}`}
                        >
                          {formatNum(x * y)}
                          {COMPUTE[chartAgainst].dividend !== 'Employees'
                            ? ' M€'
                            : null}
                        </div>
                        <div className="charttip__caption">
                          {'Average* ' +
                            COMPUTE[chartAgainst].dividend.replace(
                              'Tax',
                              'Taxes paid'
                            )}
                        </div>
                      </div>
                      <div className="column is-half">
                        <div
                          className={`charttip__figure is-${COMPUTE[chartAgainst].divisor}`}
                        >
                          {formatNum(x)}
                          {COMPUTE[chartAgainst].divisor !== 'Employees'
                            ? ' M€'
                            : null}
                        </div>
                        <div className="charttip__caption">
                          {'Average* ' +
                            COMPUTE[chartAgainst].divisor.replace(
                              'Tax',
                              'Taxes paid'
                            )}
                        </div>
                      </div>
                    </div>
                  )}

                  {/* row 3 */ isLocalBank ? (
                    <div className="columns">
                      <div className="column is-one-third">
                        <div
                          className={`charttip__figure is-${COMPUTE[chartAgainst].divisor}`}
                        >
                          {formatNum(x)}
                          {COMPUTE[chartAgainst].divisor !== 'Employees'
                            ? ' M€'
                            : null}
                        </div>
                        <div className="charttip__caption">
                          {COMPUTE[chartAgainst].divisor.replace(
                            'Tax',
                            'Taxes paid'
                          )}
                        </div>
                      </div>
                      <div className="column is-two-thirds">
                        <div
                          className={`charttip__figure is-${COMPUTE[chartAgainst].divisor}`}
                        >
                          {formatNum(bank.x)}
                          {COMPUTE[chartAgainst].divisor !== 'Employees'
                            ? ' M€'
                            : null}
                        </div>
                        <div className="charttip__caption">
                          {`Average* ${COMPUTE[chartAgainst].divisor
                            .replace('Tax', 'Taxes paid')
                            .toLowerCase()} by ${bank.label}`}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="columns">
                      <div className="column">
                        <div className="charttip__caption">
                          <span>*Average: per country of operation</span>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* row 4 */ isLocalBank ? (
                    <div className="columns">
                      <div className="column">
                        <div className="charttip__caption">
                          <span>*Average: per country of operation</span>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </Tooltip>
              </div>
            );
          }}
          onMouseEnter={this.props.onHover}
          onMouseLeave={this.props.onOut}
          onClick={this.props.onClick}
          theme={NIVO_THEMED}
          layers={[
            'grid',
            'axes',
            AreaLayer,
            'nodes',
            'markers',
            'mesh',
            'legends',
          ]}
        />

        <div className="scatter-legend">
          <div className="scatter-legend-item">
            <img
              src={circleSize}
              alt="Legend: The size of the circle indicates countries of operation"
            />
            Countries of operation
          </div>
        </div>

        {chartAgainst === 'Productivity' && nonVisualizableNonOther.length && (
          <div className={`infinity-disclaimer is-${chartAgainst}`}>
            The following countries of operation cannot be visualized, as the
            bank declares profits without having any employees in them:
            {nonVisualizableNonOther.map((item, i) => (
              <span
                key={item.label}
                className="tooltip is-tooltip-top"
                data-tooltip={`${formatNum(
                  item.dividend
                )} M€ in profit declared here.`}
              >
                <b>{item.label}</b>
                {nonVisualizableNonOther.length === i + 1 ? '.' : ','}
              </span>
            ))}
          </div>
        )}
        <button
          className="button is-small is-zoom"
          onClick={this.handleZoomToggle}
        >
          <svg viewBox="0 0 16 16" height="12" width="12">
            <path d="M10.066 0c-3.269 0-5.93 2.66-5.93 5.931 0 0.783 0.16 1.526 0.436 2.209l-4.572 4.572 3.285 3.287 4.574-4.576c0.684 0.277 1.426 0.438 2.207 0.438 3.271 0 5.934-2.66 5.934-5.929 0.001-3.272-2.662-5.933-5.933-5.933zM10.066 9.864c-2.166 0-3.931-1.765-3.931-3.931 0-2.168 1.766-3.933 3.931-3.933 2.17 0 3.935 1.765 3.935 3.933 0 2.165-1.765 3.931-3.936 3.931z" />
          </svg>{' '}
          {this.state.zoomedIn ? 'Zoom Out' : 'Zoom In'}
        </button>
        <Info
          position="left"
          text={
            chartAgainst === 'Productivity'
              ? 'This graph shows the productivity of banks operating in different jurisdictions. It highlights individual operations in countries, where banks report outstanding profitability, and compares them to the average productivity of all the operations of the banks. For comparison, productivity indicators of banks’ full operations can be found in the middle of the chart. The individual operations per jurisdiction can be found on the left and top side. You can access more information by moving your cursor over each operation.'
              : chartAgainst === 'Profitability'
              ? 'This graph shows the profitability ratios of banks operating in different jurisdictions. It highlights individual operations in countries, where banks report outstanding profitability, compared with their average profitability. For comparison, the average profitability indicators of individual banks can be found in the middle of the chart and individual operations on the left and top side. You can access more information by moving your cursor over of each operation.'
              : 'This graph shows banks’ effective tax rates in each jurisdiction  of operation and compares them to the nominal tax rates of the respective jurisdictions. Tax rates might vary for multiple reasons, for instance corporation tax payable in a given year doesn’t necessarily relate to the profits earned in the same year and can be the result of accruals. It is hence possible that relatively high corporation tax can be paid when profits are low and vice-versa. This graph only visualises the data presented in country-by-country reports. The nominal tax rates are based on OECD corporate tax data.'
          }
        />
      </div>
      // </ThemeProvider>
    );
  }
}

export default BanksScatter;
