import React, { Component } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { ResponsivePie } from '@nivo/pie';

import Loading from '../../components/Loading';
import Tooltip from '../../components/Tooltip';
import _ from '../../utils/lodashImporter';
import { formatNum, formatPerc } from '../../utils/helpers';
import {
  COLOR_EMPLOYEES,
  COLOR_TAX,
  COLOR_PROFIT,
  COLOR_TURNOVER,
  STIFFNESS,
  DUMPING,
  NIVO_THEMED,
} from '../../VARS';

const marginFirst = {
  top: 30,
  right: 80,
  bottom: 0,
  left: 50,
};
const margin = {
  top: 0,
  right: 80,
  bottom: 0,
  left: 50,
};
const padding = 0.1;
const keys = ['Turnover', 'Profit', 'Tax', 'Employees'];
const minValue = -10000;
const maxValue = 500000;
const colors = [COLOR_TURNOVER, COLOR_PROFIT, COLOR_TAX, COLOR_EMPLOYEES];
const colorByDefault = ({ indexValue }) => {
  const index = keys.indexOf(indexValue);
  return colors[index];
};

class AggregateBar extends Component {
  constructor(props) {
    super(props);

    // store computed
    this.year = null;
    this.data = { '*': [] };
    this.keys = { '*': {} };
    this.colorBy = colorByDefault;

    // store input
    this.hoveredBank = null;
  }

  _mapData(data, selectedBank) {
    const mappedData = [
      {
        key: 'Turnover',
      },
      {
        key: 'Profit',
      },
      {
        key: 'Tax',
      },
      {
        key: 'Employees',
        pieData: [], // Not elegant, but quick and it works.
      },
    ];
    mappedData.Min = 0;
    mappedData.Max = 0;
    let iterateOver = data;

    if (selectedBank !== '*') {
      iterateOver = _.find(data, { bank: selectedBank }).countries;
    }

    if (selectedBank === '*') {
      mappedData.forEach(serie => {
        serie.type = 'bank';
        iterateOver.forEach(bank => {
          const value = bank.Totals[serie.key];
          if (serie.key !== 'Employees') {
            serie[bank.bank] = value;
          } else {
            // Not elegant, but quick and it works.
            serie.pieData.push({
              id: bank.bank,
              value: value,
              indexValue: 'Employees',
              label: `${bank.bank} - Employees`,
              // this is for the hover handler
              data: {
                key: 'Employees',
                indexValue: bank.bank,
                type: 'bank',
              },
            });
          }
        });
      });
    } else {
      mappedData.forEach(serie => {
        serie.type = 'country';
        iterateOver.forEach(country => {
          const value = country.datapoints[serie.key];
          if (serie.key === 'Turnover') {
            mappedData.Max += value;
          }
          if (serie.key === 'Profit' && value < 0) {
            mappedData.Min += value;
          }
          if (serie.key !== 'Employees') {
            serie[country.country] = value;
          } else {
            // Not elegant, but quick and it works.
            serie.pieData.push({
              id: country.country,
              value: value,
              indexValue: 'Employees',
              label: `${country.country} - Employees`,
              // this is for the hover handler
              data: {
                key: 'Employees',
                indexValue: country.country,
                type: 'country',
              },
            });
          }
        });
      });
    }

    return mappedData;
  }

  // Sort bars by value (we need to sort the array of keys that we feed to ResponsiveBar)
  _mapKeys(selectedBank) {
    const mappedKeys = {};

    if (selectedBank === '*') {
      keys.forEach((key, i) => {
        const banks = this.data['*'][i];
        const banksArray = Object.keys(banks);
        banksArray.sort((a, b) =>
          a === 'key' || a === 'type'
            ? 1
            : b === 'key' || b === 'type'
            ? -1
            : Math.abs(banks[a]) - Math.abs(banks[b])
        );
        mappedKeys[key] = banksArray;
      });
    } else {
      keys.forEach((key, i) => {
        const countries = this.data[selectedBank][i];
        const countriesArray = Object.keys(countries);
        countriesArray.sort((a, b) =>
          a === 'key' || a === 'type'
            ? 1
            : b === 'key' || b === 'type'
            ? -1
            : Math.abs(countries[a]) - Math.abs(countries[b])
        );
        mappedKeys[key] = countriesArray;
      });
    }

    return mappedKeys;
  }

  render() {
    const { year, data, selectedBank, hoveredBank, hoveredType } = this.props;

    if (!data)
      return (
        <div className="loading is-pie-chart">
          <Loading />
        </div>
      );

    // memoize data on first render, and on year change
    if (
      (!this.data[selectedBank] ||
        !this.data[selectedBank].length ||
        this.year !== year) &&
      data
    ) {
      this.data[selectedBank] = this._mapData(data, selectedBank);
      this.keys[selectedBank] = this._mapKeys(selectedBank);
      this.year = year;
    }

    // memoize hovered
    if (this.hoveredBank !== hoveredBank) {
      this.hoveredBank = hoveredBank;
      this.colorBy =
        hoveredBank === null
          ? colorByDefault
          : (hoveredBank => {
              return datum => {
                let color = 'white';
                const index = keys.indexOf(datum.indexValue);
                color = colors[index];

                if (
                  datum.id !== hoveredBank &&
                  hoveredType === datum.data.type
                ) {
                  color = color.replace('1)', '0.6)');
                }

                return color;
              };
            })(this.hoveredBank);
    }

    // create tooltip function
    const tooltip = ({ id /* bank name */ }) => {
      let bank;
      let country;
      let values;
      let keys;

      if (selectedBank === '*') {
        bank = data.find(bank => bank.bank === id);
        values = Object.values(bank.Totals);
        keys = Object.keys(bank.Totals);
      } else {
        bank = data.find(bank => bank.bank === selectedBank);
        country = bank.countries.find(country => country.country === id);
        values = Object.values(country.datapoints);
        keys = Object.keys(country.datapoints);
      }
      return (
        <Tooltip title={id}>
          {values.map((value, id) => {
            if (id % 2 === 1) return null; // skip odds

            const value1 = value;
            const value2 = values[id + 1] ? values[id + 1] : 0;

            return (
              <div className="columns" key={id}>
                <div className="column is-half">
                  <div className={`charttip__figure is-${keys[id]}`}>
                    {formatNum(value1)}
                    {keys[id] !== 'Employees' ? ' M€' : ''}
                  </div>
                  <div className="charttip__caption">
                    {selectedBank !== '*' ? (
                      <span>
                        {formatPerc(value1 / bank.Totals[keys[id]])} of total
                        <br />
                      </span>
                    ) : null}
                    {keys[id].replace('Tax', 'Taxes paid')}
                  </div>
                </div>
                <div className="column is-half">
                  <div className={`charttip__figure is-${keys[id + 1]}`}>
                    {formatNum(value2)}
                    {keys[id + 1] !== 'Employees' ? ' M€' : ''}
                  </div>
                  <div className="charttip__caption">
                    {selectedBank !== '*' ? (
                      <span>
                        {formatPerc(value2 / bank.Totals[keys[id + 1]])} of
                        total
                        <br />
                      </span>
                    ) : null}
                    {keys[id + 1].replace('Tax', 'Taxes paid')}
                  </div>
                </div>
              </div>
            );
          })}
        </Tooltip>
      );
    };

    return (
      <div>
        {this.data[selectedBank].map((serie, index) => {
          if (serie.key !== 'Employees') {
            const height = index === 0 ? 60 + marginFirst.top : 60;
            return (
              <div
                className={`aggregate-bar aggregate-bar__${serie.key}`}
                style={{ height: `${height}px` }}
                key={serie.key}
              >
                <ResponsiveBar
                  data={[serie]}
                  keys={this.keys[selectedBank][serie.key]}
                  indexBy="key"
                  margin={index === 0 ? marginFirst : margin}
                  padding={padding}
                  padAngle={0.1}
                  height={height}
                  borderWidth={0.5}
                  borderColor="white"
                  layout="horizontal"
                  colors={this.colorBy}
                  axisTop={
                    index === 0
                      ? {
                          orient: 'top',
                          tickSize: 0,
                          tickPadding: 10,
                          tickRotation: 0,
                          tickValues: 3,
                          tickValueFormat: formatNum,
                          legend: '',
                        }
                      : null
                  }
                  axisLeft={null}
                  axisBottom={null}
                  enableGridY={false}
                  enableLabel={false}
                  markers={[
                    {
                      axis: 'x',
                      value: 0,
                      lineStyle: {
                        stroke: '#666',
                        strokeWidth: 1,
                      },
                    },
                  ]}
                  minValue={
                    selectedBank === '*'
                      ? minValue
                      : this.data[selectedBank].Min
                  }
                  maxValue={
                    selectedBank === '*'
                      ? maxValue
                      : this.data[selectedBank].Max
                  }
                  motionStiffness={STIFFNESS}
                  motionDamping={DUMPING}
                  theme={NIVO_THEMED}
                  onMouseEnter={this.props.onHover}
                  onMouseLeave={this.props.onOut}
                  tooltip={tooltip}
                />
              </div>
            );
          } // END <Bar>'s
          else {
            return (
              <div
                className={`aggregate-pie aggregate-pie__${serie.key}`}
                style={{
                  height: '80px',
                  width: '80px',
                  marginLeft: `${margin.left - 30}px`,
                  marginTop: '5px',
                }}
                key={serie.key}
              >
                <ResponsivePie
                  data={serie.pieData}
                  innerRadius={0.625}
                  padAngle={0.5}
                  fit={false}
                  colors={this.colorBy}
                  enableRadialLabels={false}
                  enableSlicesLabels={false}
                  motionStiffness={STIFFNESS}
                  motionDamping={DUMPING}
                  theme={NIVO_THEMED}
                  onMouseEnter={this.props.onHover}
                  onMouseLeave={this.props.onOut}
                  tooltip={tooltip}
                />
              </div>
            );
          }
        })}
      </div>
    );
  }
}

export default AggregateBar;
