import React from 'react';
import TrayCarousel from '../../../components/TrayCarousel';

const Carousel2015 = ({ handleClick }) => {
  return (
    <TrayCarousel slidesToShow={1}>
      <div className="did-you-know__trivia">
        Almost €500m was declared in profits in 0% nominal tax jurisdictions -{' '}
        <button className="a" onClick={handleClick}>
          Cayman Islands
        </button>
        ,{' '}
        <button className="a" onClick={handleClick}>
          Guernsey
        </button>
        ,{' '}
        <button className="a" onClick={handleClick}>
          Isle of Man
        </button>
        ,{' '}
        <button className="a" onClick={handleClick}>
          Bermuda
        </button>
        ,{' '}
        <button className="a" onClick={handleClick}>
          Bahrain
        </button>
        ,{' '}
        <button className="a" onClick={handleClick}>
          Bahamas
        </button>{' '}
        and{' '}
        <button className="a" onClick={handleClick}>
          Vanuatu
        </button>
        . However this number has decreased since 2015.
      </div>
      <div className="did-you-know__trivia">
        Eight banks operated in at least one of the EU-blacklisted tax havens,
        shifting over half a billion euro in profits to them over the past five
        years.
      </div>
      <div className="did-you-know__trivia">
        An average bank employee in{' '}
        <button className="a" onClick={handleClick}>
          Saudi Arabia
        </button>{' '}
        created a staggering €3.47m in profits. This makes the Saudi Arabia
        employees over 16 times more productive than employees based in Sweden,
        and almost 60 times more productive than those in Germany.
      </div>
      <div className="did-you-know__trivia">
        <button className="a" onClick={handleClick}>
          Jersey
        </button>
        , a jurisdiction of less than a hundred thousand citizens, produced
        larger profits (€311.96m) than Greece, Slovenia, Bosnia and Herzegovina,
        Albania, Macedonia, Montenegro and Moldova combined (€271m).
      </div>
      <div className="did-you-know__trivia">
        The total value of all banks' profits in{' '}
        <button className="a" onClick={handleClick}>
          France
        </button>{' '}
        (almost €1.5 billion) is only slightly higher than in{' '}
        <button className="a" onClick={handleClick}>
          Hong Kong, China
        </button>{' '}
        (almost €1.2 billion), despite having total turnover three times as
        large and employing 7 times more staff in France than Hong Kong.
      </div>
      <div className="did-you-know__trivia">
        <button className="a" onClick={handleClick}>
          Malta
        </button>
        's profitability amounted to almost 71%, making Malta the second most
        profitable European country.
      </div>
    </TrayCarousel>
  );
};

export default Carousel2015;
