import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';

// import d3 from '../utils/d3Importer';
import ErrorBoundary from '../ErrorBoundary';
import TrayOverallRisk from './overallRisk/TrayOverallRisk';
import AnomalousBanksBar from './overallRisk/AnomalousBanksBar';
import BanksScatter from './overallRisk/BanksScatter';
import RiskCountriesBar from './overallRisk/RiskCountriesBar';
import Select from '../components/Select';
import YearSelector from '../components/YearSelector';
// import mostN from '../utils/mostN';
//import sample from '../utils/sample';
import { /*COMPUTE,*/ BASE_TITLE, GA_TRACKING_ID } from '../VARS';
import { GAPageView } from '../utils/helpers';

class OverallRisk extends Component {
  constructor(props) {
    super(props);

    this.state = {
      explainerVisible: false,
      chartAgainst: 'Productivity', // Productivity|Profitability|Taxation
      selectedBank: '*', // or bank name
      hoveredBank: null, // or bank name
    };

    this.chartableAgainst = ['Productivity', 'Profitability', 'Taxation'];

    this.year = null;
    this.banks = [];
    this.countries = [];
    // this.cases = {};

    this.shouldTrackPageView = false;

    // bnd
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleTrayClick = this.handleTrayClick.bind(this);
    this.handleClickAgainst = this.handleClickAgainst.bind(this);
    this.handleHover = this.handleHover.bind(this);
    this.handleOut = this.handleOut.bind(this);
  }

  /* _getCases(data, chartAgainst) {
    // Shorthands
    const dividend = COMPUTE[chartAgainst].dividend;
    const divisor = COMPUTE[chartAgainst].divisor;

    // Calculate the mean for the 'whole' banks
    const mean = d3.mean(data, bank =>
      bank.Totals[dividend] === 0
        ? 0
        : bank.Totals[dividend] / bank.Totals[divisor]
    );

    // Go through each bank and find the ~40~+5/8+ that are the furthest
    //
    // 1. For each bank, go through each of its country HQ
    // 2. For each HQ, get the deviation
    const sample = chartAgainst === 'Taxation' ? 5 : 8;
    const MostN = new mostN(
      sample,
      (a, b) => {
        if (Math.abs(a) < Math.abs(b)) return -1;
        if (Math.abs(a) > Math.abs(b)) return 1;
        return 0;
      },
      value =>
        value.datapoints[dividend] === 0
          ? 0 - mean
          : value.datapoints[dividend] / value.datapoints[divisor] - mean
    );

    data.forEach(bank => {
      bank.countries.forEach(country => {
        // Ignore +/-Infinity values :/
        if (country.datapoints[divisor] === 0) return;

        // Some values were left empty. Warn:
        if (country.datapoints[divisor] === null) {
          console.error(
            '[AppError] missing value for "' +
              country.name +
              '" (divisor ' +
              divisor +
              ')'
          );
          return;
        }

        MostN.addAndCompute(country);
      });
    });
    return MostN.most;

    // Return a random sample of ~5~+8+
    // const provisional = MostN.most;
    // return sample(provisional, 8);
  }*/

  handleChange(e) {
    if (this.state[e.currentTarget.name]) {
      this.setState({ [e.currentTarget.name]: e.currentTarget.value });
    }
    e.target.blur();
  }

  handleHover(data) {
    // onMouseOut
    if (data === null) {
      this.setState({ hoveredBank: null });
      return;
    }

    // If data.indexValue is present then it's the histogram,
    // otherwise it's the scatterplot.
    let hoveredBank = data.indexValue ? data.indexValue : data.data.label;
    try {
      hoveredBank = / in /.test(hoveredBank)
        ? hoveredBank.match(/^([\w\s]+) in /)[1]
        : hoveredBank;
    } catch (error) {
      // nothing
    }
    this.setState({ hoveredBank });
  }

  handleOut() {
    this.handleHover(null);
  }

  handleClick(data) {
    // If data.indexValue is present then it's the histogram,
    // otherwise it's the scatterplot.
    let selectedBank = data.indexValue ? data.indexValue : data.data.label;
    if (/ in /.test(selectedBank))
      selectedBank = selectedBank.match(/^(.+) in /)[1];
    if (selectedBank === this.state.selectedBank) {
      this.setState({ selectedBank: '*' });
    } else {
      this.setState({ selectedBank });
    }
  }

  handleTrayClick(e, chartAgainst) {
    const selectedBank = e.currentTarget.textContent;
    const newState = { selectedBank };
    if (this.chartableAgainst.indexOf(chartAgainst) >= 0) {
      newState.chartAgainst = chartAgainst;
    }
    this.setState(newState);
  }

  handleClickAgainst(e) {
    if (this.state[e.currentTarget.name]) {
      this.setState({ [e.currentTarget.name]: e.currentTarget.value });
    }
  }

  componentDidMount() {
    this.shouldTrackPageView = true;
  }

  render() {
    // We need this "fake initial value" because we changed how yearly data
    // is sent down from App.js - what happens now is that we send the data for 3 years.
    // This hack is so that this component keeps working as is, and won't need a
    // rewrite for the time being.
    const data =
      this.props.data && this.props.data[this.props.year]
        ? this.props.data[this.props.year]
        : {
            byBank: null,
            byCountry: null,
            countryMeta: null,
          };

    // Build the simple list of banks to populate the Select
    if ((!this.banks.length || this.year !== this.props.year) && data.byBank) {
      this.banks = data.byBank.map(bank => bank.bank);
    }

    // Build the list of cases to pass down to our charts
    // if (
    //   (!this.cases[this.state.chartAgainst] || this.year !== this.props.year) &&
    //   data.byBank
    // ) {
    //   this.cases[this.state.chartAgainst] = this._getCases(
    //     data.byBank,
    //     this.state.chartAgainst
    //   );
    // }

    this.year = this.props.year;

    return (
      <Fragment>
        <Helmet
          onChangeClientState={(newState, addedTags, removedTags) => {
            if (this.shouldTrackPageView) {
              this.shouldTrackPageView = false;
              GAPageView(
                this.props.location,
                this.props.history,
                GA_TRACKING_ID
              );
            }
          }}
        >
          <title>Overall Risk Indicators | {BASE_TITLE}</title>
        </Helmet>

        <YearSelector year={this.props.year} loadYear={this.props.loadYear} />
        <TrayOverallRisk
          year={this.props.year}
          location={this.props.location}
          handleClick={this.handleTrayClick}
        />
        <section className="hero">
          <div className="hero-body">
            <div className="container">
              {/******************** TITLE ********************/}
              <div className="columns">
                <div className="column">
                  <h1 className="title">Overall Risk Indicators</h1>
                </div>
              </div>

              {/******************** CONTROLS ********************/}
              <div className="columns">
                <div className="column">
                  <div className="buttons has-addons chart-against">
                    {this.chartableAgainst.map(against => (
                      <button
                        name="chartAgainst"
                        key={against}
                        value={against}
                        className={
                          this.state.chartAgainst === against
                            ? 'button is-primary is-selected is-' + against
                            : 'button is-' + against
                        }
                        onClick={this.handleClickAgainst}
                      >
                        {against}
                      </button>
                    ))}
                  </div>
                  <Select
                    label="For"
                    labelInline={true}
                    name="selectedBank"
                    defaultName="All banks"
                    defaultValue="*"
                    values={this.banks}
                    value={this.state.selectedBank}
                    onChange={this.handleChange}
                  />
                </div>
              </div>

              {/******************** CHARTS ********************/}
              <div className="columns">
                <div className="column is-one-quarter">
                  <ErrorBoundary>
                    <AnomalousBanksBar
                      height={1025}
                      data={data.byBank}
                      year={this.props.year}
                      // cases={this.cases[this.state.chartAgainst]}
                      chartAgainst={this.state.chartAgainst}
                      selectedBank={this.state.selectedBank}
                      hoveredBank={this.state.hoveredBank}
                      onHover={this.handleHover}
                      onOut={this.handleOut}
                      onClick={this.handleClick}
                    />
                  </ErrorBoundary>
                  {this.state.chartAgainst === 'Productivity' && (
                    <p className="definition">
                      <br />
                      <dfn>FTE</dfn>: Average full-time equivalent.
                    </p>
                  )}
                </div>
                <div className="column">
                  <ErrorBoundary>
                    <BanksScatter
                      height={625}
                      data={data.byBank}
                      year={this.props.year}
                      chartAgainst={this.state.chartAgainst}
                      // cases={this.cases[this.state.chartAgainst]}
                      countryMeta={data.countryMeta}
                      selectedBank={this.state.selectedBank}
                      hoveredBank={this.state.hoveredBank}
                      onHover={this.handleHover}
                      onOut={this.handleOut}
                      onClick={this.handleClick}
                    />
                  </ErrorBoundary>
                </div>
                <div className="column is-one-fifth">
                  <ErrorBoundary>
                    <RiskCountriesBar
                      height="525"
                      data={data.byCountry}
                      year={this.props.year}
                      chartAgainst={this.state.chartAgainst}
                      highlightedBank={this.state.selectedBank}
                      countryMeta={data.countryMeta}
                    />
                  </ErrorBoundary>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}

export default OverallRisk;
