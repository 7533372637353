import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import iconBP from '../images/icon-BP.svg';
import iconCP from '../images/icon-CP.svg';
import iconOR from '../images/icon-OR.svg';
import iconAC from '../images/icon-AC.svg';
import { BASE_TITLE, GA_TRACKING_ID } from '../VARS';
import { GAPageView } from '../utils/helpers';

class Home extends Component {
  constructor(props) {
    super(props);
    this.shouldTrackPageView = false;
  }

  componentDidMount() {
    this.shouldTrackPageView = true;
  }

  render() {
    return (
      <Fragment>
        <Helmet
          onChangeClientState={(newState, addedTags, removedTags) => {
            if (this.shouldTrackPageView) {
              this.shouldTrackPageView = false;
              GAPageView(
                this.props.location,
                this.props.history,
                GA_TRACKING_ID
              );
            }
          }}
        >
          <title>{BASE_TITLE}</title>
        </Helmet>
        <div className="hero is-fullheight is-home">
          <section className="hero-header is-home-intro">
            <div className="container">
              <div className="content">
                <div className="columns">
                  <div className="column is-5">
                    <p className="home-title-addendum">
                      Transparency International EU
                    </p>
                    <h1 className="title is-home-title">
                      Corporate Tax Tracker
                    </h1>
                  </div>
                  <div className="column two-columns-text">
                    <p>
                      Welcome to Transparency International EU’s Corporate Tax
                      Tracker platform, a tool to examine publicly available key
                      financial data of the largest European banks.
                    </p>
                    <p>
                      The financial operations of large multinational companies
                      remain an opaque area, far from public scrutiny. The
                      public does not get much of a picture of the profits made
                      and taxes paid by the largest corporations. Despite
                      multiple leaks and studies assessing the severity of
                      corporate tax avoidance, little public information on
                      companies’ tax affairs is available.
                    </p>
                    <p>
                      One of the rare lighthouses in this financial secrecy is
                      the banking sector in the European Union, which has been
                      required to publish key financial information on a
                      country-by-country basis since 2015, including information
                      on their turnover, profits, taxes paid and number of
                      full-time employees. This information provides citizens
                      with a glimpse into how large EU-based banks operate,
                      where they operate, and how their money moves.
                    </p>
                    <p>
                      The Corporate Tax Tracker platform allows anyone to
                      compare and analyse banks’ activities and payments in
                      different jurisdictions of operation. The visualisation of
                      the data aims at providing more transparency into the
                      corporate tax world by visualising{' '}
                      <strong>
                        how much banks earn and pay in taxes in countries of
                        their operation
                      </strong>
                      .
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="hero-body is-home-launchers">
            <div className="container">
              <div className="content">
                <h2>Interactive Charts</h2>
              </div>
              <div className="content">
                <div className="columns">
                  <div className="column is-one-fourth">
                    <p>
                      <img src={iconBP} alt="" />
                    </p>
                    <p>
                      <Link to="/bank-profiles" className="button is-white">
                        Bank Profiles
                      </Link>
                    </p>
                    <p>
                      This page provides basic financial information on the
                      largest European banks, including their turnover, profits,
                      taxes paid and number of employees in different
                      jurisdictions. You can examine their tax related
                      information by selecting individual banks.
                    </p>
                  </div>
                  <div className="column is-one-fourth">
                    <p>
                      <img src={iconCP} alt="" />
                    </p>
                    <p>
                      <Link to="/country-profiles" className="button is-white">
                        Country Profiles
                      </Link>
                    </p>
                    <p>
                      This page gives an overview on which countries are the
                      main operational locations of the largest European banks.
                      It also shows how many of these countries are currently in
                      the EU’s blacklist and grey list of tax havens, and
                      highlights each banks’ most profitable countries of
                      operation.
                    </p>
                  </div>
                  <div className="column is-one-fourth">
                    <p>
                      <img src={iconOR} alt="" />
                    </p>
                    <p>
                      <Link to="/overall-risk" className="button is-white">
                        Overall Risk Indicators
                      </Link>
                    </p>
                    <p>
                      This page highlights profit shifting risk indicators such
                      as high profits compared to turnover or high profits
                      compared to number of employees in certain jurisdictions.
                      It also gives an overview on how banks’ tax rates do not
                      necessarily correlate with their turnover and profits, and
                      illustrates how many banks declare high profits in
                      countries with no employees.
                    </p>
                  </div>
                  <div className="column is-one-fourth">
                    <p>
                      <img src={iconAC} alt="" />
                    </p>
                    <p>
                      <Link to="/annual-comparison" className="button is-white">
                        Annual Comparison
                      </Link>
                    </p>
                    <p>
                      This page visualises the banks’ annual trends by showing
                      how their behavioural changes throughout the years affect
                      the risk indicators we used in the previous page. It
                      provides an overview of how the productivity,
                      profitability, and effective tax rates of the largest
                      European banks have evolved in their reported countries of
                      operation.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Fragment>
    );
  }
}

export default Home;
