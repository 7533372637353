import React, { PureComponent } from 'react';
import Datamap from '../../react-datamaps';

import Loading from '../../components/Loading';
import Tooltip from '../../components/Tooltip';
import _ from '../../utils/lodashImporter';
import d3 from '../../utils/d3Importer';
import { formatNum } from '../../utils/helpers';
import { COLOR_LIGHT } from '../../VARS';

let Fuse;
if (process.env.NODE_ENV === 'development') {
  Fuse = require('fuse.js');
}

const islandCountries = [
  {
    country: 'American Samoa',
    lat: -14.061724,
    long: -170.6686163,
  },
  {
    country: 'Anguilla',
    lat: 18.3427216,
    long: -68.377115,
  },
  {
    country: 'Antigua and Barbuda',
    lat: 17.3263581,
    long: -62.2903837,
  },
  {
    country: 'Bahamas',
    lat: 26.736731,
    long: -77.4954103,
  },
  {
    country: 'Bahrain',
    lat: 25.9406805,
    long: 50.3073928,
  },
  {
    country: 'Barbados',
    lat: 13.1883343,
    long: -59.6052952,
  },
  {
    country: 'Bermuda',
    lat: 32.3192794,
    long: -64.8364396,
  },
  {
    country: 'British Virgin Islands',
    lat: 18.5219483,
    long: -64.7114341,
  },
  {
    country: 'Brunei',
    lat: 4.5500764,
    long: 114.4393901,
  },
  {
    country: 'Cabo Verde',
    lat: 16.0228557,
    long: -25.1098134,
  },
  {
    country: 'Cayman Islands',
    lat: 19.5068872,
    long: -81.1346912,
  },
  {
    country: 'Cook Islands',
    lat: -15.5726087,
    long: -165.4324103,
  },
  {
    country: 'Curacao',
    lat: 12.2081584,
    long: -69.2131867,
  },
  {
    country: 'Falkland Islands',
    lat: -51.7223317,
    long: -60.6489386,
  },
  {
    country: 'Fiji',
    lat: -16.5421849,
    long: 177.2178666,
  },
  {
    country: 'French Polynesia',
    lat: -17.3366804,
    long: -153.6171488,
  },
  {
    country: 'Gibraltar',
    lat: 36.129508,
    long: -5.3708096,
  },
  {
    country: 'Guam',
    lat: 13.4513245,
    long: 144.5079474,
  },
  {
    country: 'Guernsey',
    lat: 49.5656156,
    long: -2.5597813,
  },
  {
    country: 'Hong Kong, China',
    lat: 22.3526738,
    long: 113.9876161,
  },
  {
    country: 'Isle of Man',
    lat: 54.2274815,
    long: -4.8523104,
  },
  {
    country: 'Jersey',
    lat: 49.2123289,
    long: -2.2007894,
  },
  {
    country: 'Luxembourg',
    lat: 49.8149618,
    long: 5.8531468,
  },
  {
    country: 'Macao, China',
    lat: 22.1618283,
    long: 113.5351333,
  },
  {
    country: 'Maldives',
    lat: 3.1097163,
    long: 70.9963281,
  },
  {
    country: 'Malta',
    lat: 35.9423686,
    long: 14.2382672,
  },
  {
    country: 'Marshall Islands',
    lat: 9.5715208,
    long: 161.733762,
  },
  {
    country: 'Mauritius',
    lat: -20.2030942,
    long: 56.5543209,
  },
  {
    country: 'Monaco',
    lat: 43.7378511,
    long: 7.4170977,
  },
  {
    country: 'Nauru',
    lat: -0.4737715,
    long: 166.4018127,
  },
  {
    country: 'Niue',
    lat: -19.0539204,
    long: -169.9322444,
  },
  {
    country: 'Palau',
    lat: 5.4403651,
    long: 130.8003761,
  },
  {
    country: 'Saint Kitts and Nevis',
    lat: 17.249879,
    long: -62.8366836,
  },
  {
    country: 'Saint Lucia',
    lat: 13.9131461,
    long: -61.1105999,
  },
  {
    country: 'Saint Maarten',
    lat: 18.0291322,
    long: -63.0941194,
  },
  {
    country: 'Samoa',
    lat: -13.7498528,
    long: -172.6647416,
  },
  {
    country: 'Seychelles',
    lat: -7.0850077,
    long: 48.9440562,
  },
  {
    country: 'Singapore',
    lat: 1.3139961,
    long: 103.7041655,
  },
  {
    country: 'Trinidad and Tobago',
    lat: 10.6975474,
    long: -61.7735128,
  },
  {
    country: 'US Virgin Islands',
    lat: 18.067274,
    long: -65.3004934,
  },
  {
    country: 'Vanuatu',
    lat: -16.6586878,
    long: 166.0365343,
  },
];

class WorldMap extends PureComponent {
  constructor(props) {
    super(props);
    this.year = null;
    this.mappedData = {};
    this.mappedBubbles = {};
    // account for smaller desktop/tablets
    this.factor = 1;
    this.resizeFactor = () => {
      this.factor = window.innerWidth < 1280 ? 0.85 : 1;
    };
    this.fullScale = 125;
    this.zoom = {
      'Sub-Saharan Africa': {
        center: [0, 0],
        rotate: [-20, -2],
        scale: 280,
      },
      'EU Member States': {
        center: [0, 0],
        rotate: [-20, -53],
        scale: 550,
      },
      'Rest of Europe & Central Asia': {
        center: [0, 0],
        rotate: [-20, -53],
        scale: 550,
      },
      'North America': {
        // US, Canada, Bermuda
        center: [0, 0],
        rotate: [110, -53],
        scale: 350,
      },
      'Central & South America': {
        center: [0, 0],
        rotate: [80, 12],
        scale: 250,
      },
      'Middle East & North Africa': {
        center: [0, 0],
        rotate: [-25, -28],
        scale: 550,
      },
      'Asia-Pacific & Oceania': {
        center: [0, 0],
        rotate: [-110, -10],
        scale: 200,
      },
    };
  }

  componentDidMount() {
    this.resizeFactor();
    window.addEventListener('resize', this.resizeFactor);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeFactor);
  }

  _mapData(countryData, countryMeta, chartAgainst, mainColor) {
    this.mappedData[chartAgainst] = {};
    this.mappedBubbles[chartAgainst] = [];

    const palette = d3
      .scaleLinear()
      .domain(
        d3.extent(countryData, country =>
          chartAgainst === 'Operations'
            ? country.banks.length
            : country.Totals[chartAgainst]
        )
      )
      .range([
        mainColor.replace(',1)', ',0.3)'),
        mainColor.replace(',1)', ',0.9)'),
      ]);

    countryMeta.forEach(meta => {
      if (meta.code === '#N/A') {
        console.warn(
          `[AppWarning] country "${meta.name}" has an undefined ISO code #N/A`
        );
        return;
      }

      const country = _.find(countryData, { country: meta.name });
      if (!country) {
        if (process.env.NODE_ENV !== 'development') return;
        console.error(
          `[AppError] could not find country "${meta.name}" in`,
          countryData
        );
        if (Fuse !== undefined) {
          const fuse = new Fuse(countryData, {
            keys: ['country'],
            shouldSort: true,
          });
          const result = fuse.search(meta.name);
          console.log(`... did you mean`, result);
        }
        return;
      }

      const value =
        chartAgainst === 'Operations'
          ? country.banks.length
          : country.Totals[chartAgainst];

      this.mappedData[chartAgainst][meta.code] = {
        numberOfThings: value,
        fillColor: palette(value),
        chartAgainst: chartAgainst,
        list: meta.list,
        region: meta.region,
        name: meta.name,
      };

      // FIX "China with HK"
      if (meta.name === 'China, P.R.: Mainland') {
        this.mappedData[chartAgainst][meta.code].reportedChinaWithHK =
          country.reportedChinaWithHK;
      }

      // Is it an island country?
      let islandCountry = islandCountries.find(
        island => island.country === meta.name
      );
      if (islandCountry) {
        this.mappedBubbles[chartAgainst].push({
          numberOfThings: value,
          fillColor: palette(value),
          fillKey: meta.code,
          chartAgainst: chartAgainst,
          list: meta.list,
          region: meta.region,
          name: meta.name,
          radius: 7,
          latitude: islandCountry.lat,
          longitude: islandCountry.long,
        });
      }
    });
  }

  render() {
    const {
      year,
      countryData,
      countryMeta,
      chartAgainst,
      selectedRegion,
      selectedList,
      mainColor,
      handleClick,
    } = this.props;

    if (!countryData)
      return (
        <div className="loading is-map">
          <Loading />
        </div>
      );

    // memoize data on first render, and on year change
    if ((!this.mappedData[chartAgainst] || this.year !== year) && countryData) {
      this._mapData(countryData, countryMeta, chartAgainst, mainColor);
      this.year = year;
    }

    // Filter
    const data = {};
    const fills = {};
    _.forOwn(this.mappedData[chartAgainst], (value, key) => {
      if (
        (selectedRegion !== '*' && selectedRegion !== value.region) ||
        (selectedList !== '*' && selectedList !== value.list)
      ) {
        return;
      }
      data[key] = value;
      fills[key] = value.fillColor;
    });
    fills.defaultFill = COLOR_LIGHT;

    const bubbles = [];
    this.mappedBubbles[chartAgainst].forEach((value /*, i*/) => {
      if (
        (selectedRegion !== '*' && selectedRegion !== value.region) ||
        (selectedList !== '*' && selectedList !== value.list)
      ) {
        return;
      }
      bubbles.push(value);
    });

    return (
      <div className="world-map">
        <Datamap
          responsive
          fills={fills}
          data={data}
          bubbles={bubbles}
          geographyConfig={{
            popupTemplate: (geo, data) => {
              return data ? (
                <div className="geo-charttip">
                  <Tooltip title={data.name}>
                    <div className="columns">
                      <div className="column">
                        <div
                          className={`charttip__figure is-${data.chartAgainst}`}
                        >
                          {formatNum(data.numberOfThings)}
                          {data.chartAgainst !== 'Employees' &&
                          data.chartAgainst !== 'Operations'
                            ? ' M€'
                            : ''}
                        </div>
                        <div className="charttip__caption">
                          {data.chartAgainst}
                          {data.chartAgainst === 'Operations' &&
                            data.reportedChinaWithHK && (
                              <span>
                                <br />
                                <br />*{data.reportedChinaWithHK} operations
                                were
                                <br /> reported as China with Hong Kong
                              </span>
                            )}
                          <br />
                          <br />
                          <span>
                            <i>
                              Click or double-click to
                              <br /> reveal operating banks
                            </i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </Tooltip>
                </div>
              ) : null;
            },
            highlightFillColor: geo => geo['fillColor'] || COLOR_LIGHT,
            highlightBorderColor: geo =>
              (geo['fillColor'] &&
                geo['fillColor'].replace(/, ?0\.\d+\)$/, ',1)')) ||
              COLOR_LIGHT,
            borderWidth: 1,
            highlightBorderWidth: 1,
          }}
          bubblesConfig={{
            popupTemplate: (geo, data) => {
              return data ? (
                <div className="geo-charttip">
                  <Tooltip title={data.name}>
                    <div className="columns">
                      <div className="column">
                        <div
                          className={`charttip__figure is-${data.chartAgainst}`}
                        >
                          {formatNum(data.numberOfThings)}
                          {data.chartAgainst !== 'Employees' &&
                          data.chartAgainst !== 'Operations'
                            ? ' M€'
                            : ''}
                        </div>
                        <div className="charttip__caption">
                          {data.chartAgainst}
                          <br />
                          <br />
                          <span>
                            <i>
                              Click or double-click to
                              <br /> reveal operating banks
                            </i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </Tooltip>
                </div>
              ) : null;
            },
            highlightFillColor: geo => geo['fillColor'] || COLOR_LIGHT,
            highlightBorderColor: geo =>
              (geo['fillColor'] &&
                geo['fillColor'].replace(/, ?0\.\d+\)$/, ',1)')) ||
              COLOR_LIGHT,
            borderWidth: 1,
            highlightBorderWidth: 1,
            animate: false,
          }}
          updateChoroplethOptions={{ reset: true }}
          setProjection={element => {
            const zoom = this.zoom[selectedRegion] || {
              center: [0, 25],
              rotate: [0, 0],
              scale: this.fullScale * this.factor,
            };
            const projection = d3
              .geoLarrivee()
              .center(zoom.center)
              .rotate(zoom.rotate)
              .scale(zoom.scale * this.factor)
              .translate([element.offsetWidth / 2, element.offsetHeight / 2]);
            const path = d3.geoPath().projection(projection);

            return { path: path, projection: projection };
          }}
          onDone={datamap => {
            datamap.svg
              .selectAll('.datamaps-subunit, .datamaps-bubble')
              .on('click', geo => {
                // console.log('[geoclick]', geo);
                let name;
                if (geo.properties) {
                  switch (geo.properties.name) {
                    case 'United States of America':
                      name = 'United States';
                      break;

                    case 'Republic of Serbia':
                      name = 'Serbia';
                      break;

                    case 'United Republic of Tanzania':
                      name = 'Tanzania';
                      break;

                    case 'China':
                      name = 'China, P.R.: Mainland';
                      break;

                    case 'Taiwan':
                      name = 'Taiwan Province of China';
                      break;

                    case 'South Korea':
                      name = 'Korea, Rep.';
                      break;

                    case 'Macedonia':
                      name = 'North Macedonia';
                      break;

                    case 'Republic of the Congo':
                      name = 'Congo, Republic of';
                      break;

                    default:
                      name = geo.properties.name;
                      break;
                  }
                } else {
                  name = geo.name;
                }
                handleClick(name, geo);
              });
          }}
        />
      </div>
    );
  }
}

export default WorldMap;
