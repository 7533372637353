import React from 'react';
import TrayCarousel from '../../../components/TrayCarousel';

const Carousel2015 = ({ handleClick }) => {
  return (
    <TrayCarousel slidesToShow={1}>
      <div className="did-you-know__trivia">
        12 large European banks had operations in The{' '}
        <button className="a" onClick={handleClick}>
          Cayman Islands
        </button>
        .
      </div>
      <div className="did-you-know__trivia">
        <button className="a" onClick={handleClick}>
          Singapore
        </button>{' '}
        is one of the most common locations for the largest European banks. In
        2016 approximately half of the largest banks had operations in the
        country.
      </div>
      <div className="did-you-know__trivia">
        Numerous European banks have operations in the Channel Islands. In 2016,
        12 banks had operations in{' '}
        <button className="a" onClick={handleClick}>
          Jersey
        </button>{' '}
        and 9 in{' '}
        <button className="a" onClick={handleClick}>
          Guernsey
        </button>
        .
      </div>
      <div className="did-you-know__trivia">
        11 banks in{' '}
        <button className="a" onClick={handleClick}>
          Cayman Islands
        </button>
        , 7 banks in{' '}
        <button className="a" onClick={handleClick}>
          United Arab Emirates
        </button>{' '}
        and 5 banks in{' '}
        <button className="a" onClick={handleClick}>
          Ireland
        </button>{' '}
        did not pay tax there.
      </div>
    </TrayCarousel>
  );
};

export default Carousel2015;
