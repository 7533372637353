import React, { useEffect } from 'react';
import Nuka from 'nuka-carousel';

const TrayCarousel = ({ children, slidesToShow }) => {
  // Kind of fix and visually patch Carousel height bug...   -.-
  // THE HORROR!!! 😱
  useEffect(() => {
    setTimeout(() => {
      let resizeEvent;
      if (typeof Event === 'function') {
        resizeEvent = new Event('resize');
      } else {
        // IE11
        resizeEvent = document.createEvent('Event');
        resizeEvent.initEvent('resize', true, true);
      }
      window.dispatchEvent(resizeEvent);
      document
        .querySelector('.did-you-know .slider-list')
        .classList.add('is-loaded');
      document
        .querySelector('.did-you-know .slider-control-bottomcenter')
        .classList.add('is-loaded');
    }, 0);
  }, []);

  return (
    <Nuka
      className="did-you-know"
      slidesToShow={slidesToShow ? slidesToShow : 2}
      heightMode="max"
      renderCenterLeftControls={null}
      renderCenterRightControls={null}
    >
      {children}
    </Nuka>
  );
};

export default TrayCarousel;
