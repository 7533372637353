import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import ErrorBoundary from '../ErrorBoundary';
import TrayAnnualComparison from './annualComparison/TrayAnnualComparison';
import BanksAverageBar from './annualComparison/BanksAverageBar';
import AnnualComparisonLine from './annualComparison/AnnualComparisonLine';
import BanksInTaxHavensBar from './annualComparison/BanksInTaxHavensBar';
import Select from '../components/Select';
import YearSelector from '../components/YearSelector';
import { BASE_TITLE, GA_TRACKING_ID } from '../VARS';
import { GAPageView } from '../utils/helpers';
import spread from '../images/spread.png';

const AnnualComparison = ({ year, data, loadYear, location, history }) => {
  // state
  const [chartAgainst, setChartAgainst] = useState('Productivity'); // Productivity|Profitability|Taxation
  const [selectedBank, setSelectedBank] = useState('*'); // or bank name
  const [hoveredBank, setHoveredBank] = useState(null); // or bank name
  const [hoveredPoint, setHoveredPoint] = useState(null);

  // memos
  const banks = useMemo(() => {
    if (!data || !data[year]) return [];
    return data[year].byBank.map(bank => bank.bank);
  }, [year, data]);

  const chartableAgainst = ['Productivity', 'Profitability', 'Taxation'];

  const shouldTrackPageView = useRef(false);
  useEffect(() => {
    shouldTrackPageView.current = true;
  }, []);

  const handleChange = e => {
    const actuators = {
      selectedBank: setSelectedBank,
    };
    actuators[e.currentTarget.name](e.currentTarget.value);
    e.target.blur();
  };

  const handleHover = data => {
    // onMouseOut
    if (data === null) {
      setHoveredBank(null);
      setHoveredPoint(null);
      return;
    }

    // onMouseHover: If data.data.key is present then it's the AggregateBar,
    // otherwise it's AllBanks. Also, are we hovering a bank or a country?
    let hoveredBank = data.indexValue ? data.indexValue : data.serieId;
    try {
      hoveredBank = / in /.test(hoveredBank)
        ? hoveredBank.match(/^([\w\s]+) in /)[1]
        : hoveredBank;
    } catch (error) {
      // nothing
    }
    setHoveredBank(hoveredBank);
    if (data.x !== null && data.y !== null && data.serieId) {
      setHoveredPoint({ x: data.x, y: data.y });
    } else {
      setHoveredPoint(null);
    }
  };

  const handleOut = () => {
    if (hoveredBank === null) return;
    handleHover(null);
  };

  const handleClick = data => {
    // If data.indexValue is present then it's the histogram,
    // otherwise it's the scatterplot. @TODO check this!
    let clickedBank = data.indexValue ? data.indexValue : data.serieId;
    if (clickedBank === selectedBank) {
      setSelectedBank('*');
    } else {
      setSelectedBank(clickedBank);
    }
  };

  const handleTrayClick = e => {
    const selectedBank = e.currentTarget.textContent;
    setSelectedBank(selectedBank);
  };

  const handleClickAgainst = e => {
    const actuators = {
      chartAgainst: setChartAgainst,
    };
    actuators[e.currentTarget.name](e.currentTarget.value);
  };

  return (
    <>
      <Helmet
        onChangeClientState={(newState, addedTags, removedTags) => {
          if (shouldTrackPageView.current) {
            shouldTrackPageView.current = false;
            GAPageView(location, history, GA_TRACKING_ID);
          }
        }}
      >
        <title>Annual Comparison | {BASE_TITLE}</title>
      </Helmet>

      <YearSelector hideSelector={true} />
      <TrayAnnualComparison
        year={year}
        location={location}
        handleClick={handleTrayClick}
      />
      <section className="hero">
        <div className="hero-body">
          <div className="container">
            {/******************** TITLE ********************/}
            <div className="columns">
              <div className="column">
                <h1 className="title">Annual Comparison</h1>
              </div>
            </div>

            {/******************** CONTROLS ********************/}
            <div className="columns">
              <div className="column">
                <div className="buttons has-addons chart-against">
                  {chartableAgainst.map(against => (
                    <button
                      name="chartAgainst"
                      key={against}
                      value={against}
                      className={
                        chartAgainst === against
                          ? 'button is-primary is-selected is-' + against
                          : 'button is-' + against
                      }
                      onClick={handleClickAgainst}
                    >
                      {against}
                    </button>
                  ))}
                </div>
                <Select
                  label="For"
                  labelInline={true}
                  name="selectedBank"
                  defaultName="All banks"
                  defaultValue="*"
                  values={banks}
                  value={selectedBank}
                  onChange={handleChange}
                />
              </div>
            </div>

            {/******************** CHARTS ********************/}
            <div className="columns">
              <div className="column is-one-quarter">
                <ErrorBoundary>
                  <BanksAverageBar
                    height={1025}
                    data={data}
                    chartAgainst={chartAgainst}
                    selectedBank={selectedBank}
                    hoveredBank={hoveredBank}
                    onHover={handleHover}
                    onOut={handleOut}
                    onClick={handleClick}
                  />
                </ErrorBoundary>
                {chartAgainst === 'Productivity' && (
                  <p className="definition">
                    <br />
                    <dfn>FTE</dfn>: Average full-time equivalent.
                  </p>
                )}
              </div>
              <div className="column">
                <ErrorBoundary>
                  <AnnualComparisonLine
                    height={625}
                    data={data}
                    chartAgainst={chartAgainst}
                    selectedBank={selectedBank}
                    hoveredBank={hoveredBank}
                    hoveredPoint={hoveredPoint}
                    onHover={handleHover}
                    onOut={handleOut}
                    onClick={handleClick}
                  />
                  <div className="annual-comparison-legend">
                    <img src={spread} alt="" />
                    <span>
                      {chartAgainst === 'Productivity'
                        ? 'Number of employees'
                        : chartAgainst === 'Profitability'
                        ? 'Turnover value'
                        : 'Profit value'}
                    </span>
                  </div>
                </ErrorBoundary>
              </div>
              <div className="column is-one-fifth">
                {/* <div className="chart-title">
                  <div>Banks By Presence In Tax Havens</div>
                </div>
                <div
                  style={{
                    width: '100%',
                    height: '525px',
                    backgroundColor: COLOR_GRAY,
                    padding: '5px 8px',
                    opacity: '0.5',
                  }}
                >
                  <small>tbd</small>
                </div> */}
                <ErrorBoundary>
                  <BanksInTaxHavensBar height="525" data={data} />
                </ErrorBoundary>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AnnualComparison;
