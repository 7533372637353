import React, { Fragment, useState, useMemo, useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { BASE_TITLE, GA_TRACKING_ID } from '../VARS';
import _ from '../utils/lodashImporter';
import ErrorBoundary from '../ErrorBoundary';
import TrayBankProfiles from './bankProfiles/TrayBankProfiles';
import AllBanksBar from './bankProfiles/AllBanksBar';
import AggregateBar from './bankProfiles/AggregateBar';
import AggregateNumbers from './bankProfiles/AggregateNumbers';
import Top10Bar from './bankProfiles/Top10Bar';
import ProductivityMisalignmentBar from './bankProfiles/ProductivityMisalignmentBar';
import ModalAnomalies from './bankProfiles/ModalAnomalies';
import Select from '../components/Select';
import YearSelector from '../components/YearSelector';
import { GAPageView } from '../utils/helpers';

const BankProfiles = ({ year, data, loadYear, location, history }) => {
  // state
  const [sortBy, setSortBy] = useState('Name'); // name|turnover|tax|profit
  const [selectedBank, setSelectedBank] = useState('*'); // or bank name
  const [hoveredBank, setHoveredBank] = useState(null); // or bank name
  const [hoveredType, setHoveredType] = useState('bank'); // bank|country
  const [anomaliesModalIsOpen, setAnomaliesModalIsOpen] = useState(false);

  // memos
  const banks = useMemo(() => {
    if (!data || !data[year]) return [];
    return data[year].byBank.map(bank => bank.bank);
  }, [year, data]);

  const sortableBy = ['Name', 'Turnover', 'Profit', 'Tax'];

  const shouldTrackPageView = useRef(false);
  useEffect(() => {
    shouldTrackPageView.current = true;
  }, []);

  // handlers
  const handleChange = e => {
    const actuators = {
      sortBy: setSortBy,
      selectedBank: setSelectedBank,
    };
    actuators[e.currentTarget.name](e.currentTarget.value);
    e.target.blur();
  };

  const handleHover = data => {
    // onMouseOut
    if (data === null) {
      setHoveredBank(null);
      return;
    }

    // onMouseHover: If data.data.key is present then it's the AggregateBar,
    // otherwise it's AllBanks. Also, are we hovering a bank or a country?
    const hoveredBank = data.data.key ? data.id : data.indexValue;
    const hoveredType =
      data.data.type && data.data.type === 'country' ? 'country' : 'bank';

    setHoveredBank(hoveredBank);
    setHoveredType(hoveredType);
  };

  const handleOut = () => {
    handleHover(null);
  };

  const handleClick = data => {
    // do nothing if I'm clicking on a country
    if (data.data.type && data.data.type === 'country') return;

    const clickedBank = data.data.key ? data.id : data.indexValue;
    if (clickedBank === selectedBank) {
      setSelectedBank('*');
    } else {
      setSelectedBank(clickedBank);
    }
  };

  const handleTrayClick = e => {
    const selectedBank = e.currentTarget.textContent;
    setSelectedBank(selectedBank);
  };

  return (
    <>
      <Helmet
        onChangeClientState={(newState, addedTags, removedTags) => {
          if (shouldTrackPageView.current) {
            shouldTrackPageView.current = false;
            GAPageView(location, history, GA_TRACKING_ID);
          }
        }}
      >
        <title>Bank Profiles | {BASE_TITLE}</title>
      </Helmet>

      <YearSelector year={year} loadYear={loadYear} />
      <TrayBankProfiles
        year={year}
        location={location}
        handleClick={handleTrayClick}
      />
      <section className="hero">
        <div className="hero-body">
          <div className="container">
            {/******************** TITLE+CONTROLS ********************/}
            <div className="columns">
              <div className="column is-one-third">
                <h1 className="title">
                  Size of Operations of
                  <br />
                  Largest European Banks
                </h1>
                <div className="field">
                  <div className="control">
                    <span className="label">Sort by</span>
                    {sortableBy.map(by => (
                      <Fragment key={by}>
                        <input
                          className={`is-checkradio is-small is-checkradio__${by}`}
                          type="radio"
                          name="sortBy"
                          id={`radioSort_${by}`}
                          value={by}
                          checked={sortBy === by}
                          onChange={handleChange}
                        />
                        <label
                          key={by}
                          className="radio"
                          htmlFor={`radioSort_${by}`}
                        >
                          {_.capitalize(by).replace('Tax', 'Taxes paid')}
                        </label>
                      </Fragment>
                    ))}
                  </div>
                </div>
              </div>
              <div
                className={
                  selectedBank === '*'
                    ? 'column is-two-thirds select-banks selected-bank'
                    : 'column is-two-thirds select-banks selected-bank is-selected'
                }
              >
                <Select
                  label="Details for"
                  name="selectedBank"
                  defaultName="All banks"
                  defaultValue="*"
                  values={banks}
                  value={selectedBank}
                  onChange={handleChange}
                  hidden={true}
                />
              </div>
            </div>

            {/******************** CHARTS ********************/}
            <div className="columns">
              <div className="column is-one-third">
                <ErrorBoundary>
                  <AllBanksBar
                    height="1000"
                    data={data && data[year] ? data[year].byBank : null}
                    year={year}
                    sortBy={sortBy}
                    selectedBank={selectedBank}
                    hoveredBank={hoveredBank}
                    onHover={handleHover}
                    onOut={handleOut}
                    onClick={handleClick}
                  />
                </ErrorBoundary>
                <p className="definition">
                  <br />
                  <dfn>FTE</dfn>: Average full-time equivalent.
                </p>
                <p>
                  <br />
                  <button
                    className="button is-primary is-light is-small"
                    onClick={() => setAnomaliesModalIsOpen(true)}
                  >
                    <span className="icon">
                      <svg
                        width="100%"
                        height="100%"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.16752 8.3335H10.8342V12.5002H9.16752V8.3335ZM9.16669 13.3335H10.8334V15.0002H9.16669V13.3335Z"
                          fill="currentColor"
                        />
                        <path
                          d="M11.4733 3.50023C11.1833 2.9544 10.6183 2.61523 9.99998 2.61523C9.38164 2.61523 8.81664 2.9544 8.52664 3.50107L2.41164 15.0536C2.27595 15.3073 2.20873 15.5919 2.21663 15.8795C2.22452 16.1671 2.30724 16.4477 2.45664 16.6936C2.60389 16.9406 2.81301 17.1449 3.06335 17.2863C3.31369 17.4278 3.5966 17.5015 3.88414 17.5002H16.1158C16.7058 17.5002 17.24 17.1986 17.5441 16.6936C17.6933 16.4476 17.7759 16.1671 17.7838 15.8795C17.7917 15.592 17.7246 15.3073 17.5891 15.0536L11.4733 3.50023ZM3.88414 15.8336L9.99998 4.28107L16.12 15.8336H3.88414Z"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                    <span>Reporting Anomalies</span>
                  </button>
                </p>
              </div>
              <div
                className={
                  selectedBank === '*'
                    ? 'column is-two-thirds selected-bank'
                    : 'column is-two-thirds selected-bank is-selected'
                }
              >
                <div className="columns">
                  <div className="column is-one-quarter">
                    <ErrorBoundary>
                      <AggregateNumbers
                        data={data && data[year] ? data[year].byBank : null}
                        year={year}
                        selectedBank={selectedBank}
                      />
                    </ErrorBoundary>
                  </div>
                  <div className="column is-three-quarters">
                    <ErrorBoundary>
                      <AggregateBar
                        data={data && data[year] ? data[year].byBank : null}
                        year={year}
                        keys={banks}
                        selectedBank={selectedBank}
                        hoveredBank={hoveredBank}
                        hoveredType={hoveredType}
                        onHover={handleHover}
                        onOut={handleOut}
                        onClick={handleClick}
                      />
                    </ErrorBoundary>
                  </div>
                </div>

                <div
                  className="columns productivity-misalignment-wrapper"
                  style={{ height: selectedBank === '*' ? '0' : '350px' }}
                >
                  <div className="column is-full">
                    <ErrorBoundary>
                      <ProductivityMisalignmentBar
                        dataByBank={
                          data && data[year] ? data[year].byBank : null
                        }
                        selectedBank={selectedBank}
                      />
                    </ErrorBoundary>
                  </div>
                </div>

                <div className="columns">
                  <div className="column is-half">
                    <ErrorBoundary>
                      <Top10Bar
                        dataByCountry={
                          data && data[year] ? data[year].byCountry : null
                        }
                        dataByBank={
                          data && data[year] ? data[year].byBank : null
                        }
                        year={year}
                        selectedBank={selectedBank}
                        type="Profitability"
                      />
                    </ErrorBoundary>
                  </div>
                  <div className="column is-half">
                    <ErrorBoundary>
                      <Top10Bar
                        dataByCountry={
                          data && data[year] ? data[year].byCountry : null
                        }
                        dataByBank={
                          data && data[year] ? data[year].byBank : null
                        }
                        year={year}
                        selectedBank={selectedBank}
                        type="Productivity"
                      />
                    </ErrorBoundary>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ModalAnomalies
        isOpen={anomaliesModalIsOpen}
        onClose={() => setAnomaliesModalIsOpen(false)}
      />
    </>
  );
};

export default BankProfiles;
