import React from 'react';

const Info = ({ text, position }) => {
  let classes = 'explainer tooltip is-tooltip-multiline';
  if (position) classes += ' is-tooltip-' + position;
  else classes += ' is-tooltip-bottom';
  return (
    <div className={classes} data-tooltip={text} aria-label={text}>
      i
    </div>
  );
};

export default Info;
