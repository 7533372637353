import React from 'react';
import TrayCarousel from '../../../components/TrayCarousel';

const Carousel2015 = ({ handleClick }) => {
  return (
    <TrayCarousel slidesToShow={2}>
      <div className="did-you-know__trivia">
        <button className="a" onClick={e => handleClick(e, 'Productivity')}>
          UniCredit
        </button>{' '}
        had operations in 9 countries with no employees, yet declared profits in
        these locations.
      </div>
      <div className="did-you-know__trivia">
        The UK-based{' '}
        <button className="a" onClick={e => handleClick(e, 'Productivity')}>
          Barclays
        </button>{' '}
        reported 42 employees in Luxembourg. They generated €651m profits,
        meaning each employee generated around €16m of profit.
      </div>
      <div className="did-you-know__trivia">
        The operations of{' '}
        <button className="a" onClick={e => handleClick(e, 'Profitability')}>
          HSBC
        </button>{' '}
        in China produced €2333m profits, which was more than the reported
        turnover of the operations, €2178m.
      </div>
      <div className="did-you-know__trivia">
        Two banks reported over €250m profits in two countries. They had no
        employees there, paid no taxes, and reported no turnover. The banks were{' '}
        <button className="a" onClick={e => handleClick(e, 'Taxation')}>
          UniCredit
        </button>{' '}
        in Turkey and{' '}
        <button className="a" onClick={e => handleClick(e, 'Taxation')}>
          HSBC
        </button>{' '}
        in Saudi Arabia.
      </div>
      <div className="did-you-know__trivia">
        <button className="a" onClick={e => handleClick(e, 'Taxation')}>
          Barclays
        </button>{' '}
        reported €143m turnover in Mauritius. However, its profits were over
        three times higher, €472m. The bank paid an effective tax rate of 1.06%
        on these profits.
      </div>
    </TrayCarousel>
  );
};

export default Carousel2015;
