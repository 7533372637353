import React from 'react';
import styled from 'styled-components/macro';
import * as theme from '../sass/_theme';

const years = [2015, 2016, 2017, 2018, 2019];

const StyledYearSelector = styled.div`
  position: sticky;
  top: 0;
  border-top: 7px solid #333;
  z-index: 32;
`;

const YearsWrapper = styled.div`
  position: absolute;
  display: inline-block;
  margin-left: -12px;
  padding: 0 8px;
  background: #333;
`;

const YearSelectorLabel = styled.p`
  margin: -6px 0 0 4px;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.1em;
  color: #c6c6c6;
`;

const YearsBox = styled.div`
  position: relative;
  border-bottom: 2px solid rgba(255, 255, 255, 0.4);

  ::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -4px;
    width: 20%;
    height: 6px;
    background: ${theme.PRIMARY};
    transform: translate3d(
      ${props => (props.activeYear - years[0]) * 100 + '%'},
      0,
      0
    );
    transition: transform 0.3s ease-out;
  }
`;

const YearButton = styled.button`
  margin: 0;
  padding: 0 4px;
  font-size: 2.5rem;
  line-height: 1.2;
  font-family: ${theme.TITLE_FAMILY};
  border: none;
  color: white;
  background: transparent;
  cursor: pointer;
  opacity: ${props => (props.year === props.activeYear ? 1 : 0.4)};
  transition: opacity 0.2s ease-out;

  :hover,
  :focus {
    opacity: 1;
  }
`;

const YearSelector = ({
  year = 2015,
  loadYear = () => {},
  hideSelector = false,
}) => {
  const activeYear = year;

  return (
    <StyledYearSelector>
      {!hideSelector && (
        <div className="container">
          <YearsWrapper>
            <YearSelectorLabel>Year selected</YearSelectorLabel>
            <YearsBox activeYear={activeYear}>
              {years.map(year => (
                <YearButton
                  key={year}
                  onClick={() => loadYear(year)}
                  year={year}
                  activeYear={activeYear}
                >
                  {year}
                </YearButton>
              ))}
            </YearsBox>
          </YearsWrapper>
        </div>
      )}
    </StyledYearSelector>
  );
};

export default YearSelector;
