import React, { Component } from 'react';

import Loading from '../../components/Loading';
import BarWithLabels from '../../components/BarWithLabels';
/* import mostN from '../../utils/mostN';
import sample from '../../utils/sample';  */

class Top20CountriesBar extends Component {
  render() {
    const {
      data,
      chartAgainst,
      selectedRegion,
      selectedList,
      handleClick,
    } = this.props;

    if (!data)
      return (
        <div className="loading is-bar-chart">
          <Loading />
        </div>
      );

    let filteredData = data.filter(country => {
      if (
        (selectedRegion !== '*' && selectedRegion !== country.region) ||
        (selectedList !== '*' && selectedList !== country.list)
      ) {
        return false;
      }
      return true;
    });
    filteredData = filteredData.slice(-20);

    if (filteredData.length < 20) {
      for (let i = filteredData.length; i < 20; i++) {
        filteredData.splice(0, 0, {
          Name: 'filler' + i,
          [chartAgainst]: 0,
          fill: 'transparent',
        });
      }
    }

    return (
      <div className="frequency-top-bar">
        <BarWithLabels
          className="chart-frequency-top"
          data={filteredData}
          keys={[chartAgainst, '']}
          colors={d => d.data.fill}
          indexBy="Name"
          height={this.props.height}
          format="num"
          onClick={item => handleClick(item.indexValue)}
        />
        <div className="top20-legend">
          <div className="top20-legend-item is-black">
            Jurisdiction currently listed on the EU’s so called ‘black list’ of
            non-cooperative jurisdictions for tax purposes
          </div>
          <div className="top20-legend-item is-grey">
            Jurisdiction currently listed on the EU’s so called ‘grey list’ of
            non-cooperative jurisdictions for tax purposes
          </div>
          <div className={`top20-legend-item is-${chartAgainst}`}>
            Other countries
          </div>
        </div>
      </div>
    );
  }
}

export default Top20CountriesBar;
