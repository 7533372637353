import React, { useState, useMemo } from 'react';

import Loading from '../../components/Loading';
import BarWithLabels from '../../components/BarWithLabels';
import Info from '../../components/Info';
import Tooltip from '../../components/Tooltip';
// import Info from '../../components/Info';
import _ from '../../utils/lodashImporter';

let Fuse;
if (process.env.NODE_ENV === 'development') {
  Fuse = require('fuse.js');
}

// https://gka.github.io/palettes/#/5|s|4f902a,8fb533,cfda3b|ffffe0,ff005e,93003a|1|1
const colors = ['#4f902a', '#70a22e', '#8fb533', '#afc737', '#cfda3b'];

const BanksInTaxHavensBar = ({ data, height }) => {
  const [page, setPage] = useState(1);

  const memoized = useMemo(() => {
    const mapped = [];
    const years = data ? Object.keys(data) : [];

    if (!data)
      return {
        data: mapped,
        years,
      };

    years.forEach((year, i) => {
      const yearlyData = data[year];
      yearlyData.byBank.forEach(bank => {
        const name = bank.bank;
        const havens = bank.countries.filter(country => {
          const metaCountry = yearlyData.countryMeta.find(
            meta => meta.name === country.country
          );
          if (metaCountry)
            return metaCountry.list === 'grey' || metaCountry.list === 'black';
          else {
            if (process.env.NODE_ENV !== 'development') return false;
            console.error(
              `[AppError] could not find country "${country.country}" in`,
              yearlyData.countryMeta
            );
            if (Fuse !== undefined) {
              const fuse = new Fuse(yearlyData.countryMeta, {
                keys: ['country'],
                shouldSort: true,
              });
              const result = fuse.search(country.country);
              console.log(`... did you mean`, result);
            }
            return false;
          }
        });

        // @NOTE: we assume ALL banks are present in ALL years
        if (i === 0) {
          mapped.push({
            bank: name,
            [year]: havens.length,
            [year + 'Color']: colors[i],
          });
        } else {
          const mappedBank = mapped.find(bank => bank.bank === name);
          if (mappedBank) {
            mappedBank[year] = havens.length;
            mappedBank[year + 'Color'] = colors[i];
          }
        }
      });
    });

    // Filter out banks for which all 3 years are 0
    const mappedWithHavens = mapped.filter(bank => {
      const havens = years.reduce((sum, year) => sum + bank[year], 0);
      return havens > 0;
    });

    const mappedSorted = _.orderBy(mappedWithHavens, years[0], 'desc');
    return {
      data: mappedSorted,
      years,
    };
  }, [data]);

  const dataSlice = memoized.data.slice((page - 1) * 20, page * 20);
  dataSlice.reverse();

  if (dataSlice.length < 20) {
    for (let i = dataSlice.length; i < 20; i++) {
      const filler = {
        bank: 'filler' + i,
      };
      memoized.years.forEach(year => {
        filler[year] = 0;
        filler[year + 'Color'] = 'transparent';
      });
    }
  }

  const pages = Math.ceil(memoized.data.length / 20);

  if (!data)
    return (
      <div className="loading is-bar-chart">
        <Loading />
      </div>
    );

  return (
    <div className="banks-tax-havens-bar">
      <div className="chart-title">
        <div>
          Banks By Presence In Tax Havens&nbsp;
          <span style={{ whiteSpace: 'nowrap' }}>
            <Info
              text="Jurisdictions currently listed on the EU’s so called ‘black list’ of non-cooperative jurisdictions for tax purposes"
              position="left"
            />
          </span>
          <br />
          <small>
            in{' '}
            {memoized.years.map((year, i) => (
              <span key={i} style={{ color: colors[i] }}>
                {' '}
                {year}
              </span>
            ))}
          </small>
          {/* {chartAgainst === 'Taxation' ? (
            <Info
              position="left"
              text="Average effective tax rates among all banks operating in a jurisdiction"
            />
          ) : null} */}
        </div>
      </div>
      <BarWithLabels
        className="chart-banks-tax-havens"
        data={dataSlice}
        keys={memoized.years}
        colors={colors}
        indexBy="bank"
        height={height}
        format="num"
        groupMode="grouped"
        markers={[
          {
            axis: 'x',
            value: 0,
            lineStyle: {
              stroke: 'rgba(0, 0, 0, 0.2)',
              strokeWidth: 1,
            },
          },
        ]}
        tooltip={({ data }) => (
          <Tooltip title={data.bank} key={data.bank}>
            <div className="columns">
              {memoized.years.map((year, i) => (
                <div key={year} className="column">
                  <div
                    className="charttip__figure"
                    style={{ color: data[year + 'Color'] }}
                  >
                    {data[year]}
                  </div>
                  <div className="charttip__caption">{year}</div>
                </div>
              ))}
            </div>
          </Tooltip>
        )}
      />
      {pages > 1 && (
        <div className="chart-paginate">
          <button
            className="button is-small is-paginate"
            onClick={() => setPage(page => page - 1)}
            disabled={page <= 1}
          >
            ▲
          </button>
          <button
            className="button is-small is-paginate"
            onClick={() => setPage(page => page + 1)}
            disabled={page >= pages}
          >
            ▼
          </button>
        </div>
      )}
    </div>
  );
};

export default BanksInTaxHavensBar;
