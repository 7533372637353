import React from 'react';
import TrayCarousel from '../../components/TrayCarousel';

const TrayCarouselAnnualComparison = ({ handleClick }) => (
  <TrayCarousel>
    <div className="did-you-know__trivia">
      Since 2015, over €6 billion in profit has been made in “ghost
      jurisdictions”; countries where firms reported zero employees yet had
      economic activity.
    </div>
    <div className="did-you-know__trivia">
      The number of banks reporting activity in EU-blacklisted tax havens has
      fallen from 15 in 2015 to 7 in 2019.
    </div>
    <div className="did-you-know__trivia">
      The overall effective tax rate in Malta has not exceeded 10.8% since 2016.
      The official nominal tax rate in Malta is 35%.
    </div>
    <div className="did-you-know__trivia">
      The difference in taxes paid between the most and the least tax-aggressive
      banks is almost 30 percentage points: while{' '}
      <button className="a" onClick={handleClick}>
        UniCredit
      </button>{' '}
      paid only 8.2% of their profits in taxes,{' '}
      <button className="a" onClick={handleClick}>
        Nationwide
      </button>{' '}
      Building Society contributed over 38% of theirs.
    </div>
    <div className="did-you-know__trivia">
      There has been a negative trend in the overall effective tax rate over the
      years: from 29.25% in 2015 to 22.82% in 2019.
    </div>
    <div className="did-you-know__trivia">
      German banks are on average 30 percentage points less profitable than
      Swedish ones. German banks have an overall profit rate of 11%, whereas
      Swedish banks over 40%.
    </div>
    <div className="did-you-know__trivia">
      Every year{' '}
      <button className="a" onClick={handleClick}>
        Crédit Mutuel
      </button>{' '}
      earned positive profits (a total of €375m) in Morocco and Tunisia without
      having any turnover nor employees. Not a single euro of these profits has
      been taxed.
    </div>
    <div className="did-you-know__trivia">
      The banks with the lowest total income tax paid in the years 2015-2019
      were{' '}
      <button className="a" onClick={handleClick}>
        UniCredit
      </button>{' '}
      (8.2%),{' '}
      <button className="a" onClick={handleClick}>
        KfW
      </button>{' '}
      (10.91%), and{' '}
      <button className="a" onClick={handleClick}>
        Bayerische Landesbank
      </button>{' '}
      (12.41%).
    </div>
  </TrayCarousel>
);

export default TrayCarouselAnnualComparison;
