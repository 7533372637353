import _ from './utils/lodashImporter';
import d3 from './utils/d3Importer';
//import * as d3 from 'd3';

function isCIOM(country) {
  const compare = typeof country === 'string' ? country : country.country;
  return compare === 'Channel Islands';
}

function hasCIOM(countries) {
  const found = countries.find(country => isCIOM(country));
  return found !== undefined;
}

export const loadAndProcessData = (callback = _.noop) => {
  const promises = [
    d3.json('data/top20-countries-summary_v2.json'),
    d3.json('data/top20-countries-meta_v2.json'),
  ];
  Promise.all(promises)
    .then(([v2, countryMeta]) => {
      /*******************************************************************
       * WARNING: loooong *untested* spaghetti code ahead,
       *  be CAREFUL when you edit things
       *******************************************************************/

      // Some countries are missing tax rates for certain years,
      // but NEVER for 2017. Let's loop over all countries and
      // copy the 2017 values to the missing years.
      const preparedCountryMeta = countryMeta.map(country => {
        const nominalTR2015 =
          country.nominalTR2015 === null
            ? country.nominalTR2017
            : country.nominalTR2015;
        const nominalTR2016 =
          country.nominalTR2016 === null
            ? country.nominalTR2017
            : country.nominalTR2016;
        const nominalTR2018 =
          country.nominalTR2018 === null
            ? country.nominalTR2017
            : country.nominalTR2018;
        const nominalTR2019 =
          country.nominalTR2019 === null
            ? country.nominalTR2017
            : country.nominalTR2019;
        return {
          ...country,
          nominalTR2015,
          nominalTR2016,
          nominalTR2018,
          nominalTR2019,
        };
      });

      const measures = ['Turnover', 'Employees', 'Profit', 'Tax'];

      const ChinaWithHK = new Map();

      for (const year in v2) {
        if (v2.hasOwnProperty(year)) {
          const top20 = v2[year];
          ChinaWithHK.set(year, 0);

          // Immediately add countryMeta. It will get added (by reference!) to each year,
          // but such is life. It's easier to reuse much of the old App.js this way.
          top20.countryMeta = preparedCountryMeta;

          top20.byBank.Maxs = {};
          top20.byBank.Mins = {};
          top20.byBank.Totals = {};
          top20.byCountry.Maxs = {};
          top20.byCountry.Mins = {};
          top20.byCountry.Totals = {};

          // FIRST compute totals per each bank
          top20.byBank.forEach(bank => {
            // 0. Fix "China With HK" reporting
            bank.countries.forEach(country => {
              if (country.country !== 'China with HK') return;
              country.country = 'China, P.R.: Mainland';
              country.name.replace('China with HK', 'China, P.R.: Mainland');
              ChinaWithHK.set(year, ChinaWithHK.get(year) + 1);
            });

            // 1. For each bank, calculate total num of countries of presence
            let numCountries = bank.countries.length;
            if (hasCIOM(bank.countries)) numCountries -= 1;

            // 1b. Pull up to Max/Min
            if (
              !top20.byBank.Maxs.Countries ||
              numCountries > top20.byBank.Maxs.Countries
            )
              top20.byBank.Maxs.Countries = numCountries;
            if (
              !top20.byBank.Mins.Countries ||
              numCountries < top20.byBank.Mins.Countries
            )
              top20.byBank.Mins.Countries = numCountries;

            // 2. Find, for each bank, and for each "measure", the Max, Min and Total value
            // computed from its countries of presence
            bank.Maxs = {};
            bank.Mins = {};
            bank.Totals = bank.Totals || {}; // This should now be added by `prepare-data`
            measures.forEach(measure => {
              bank.Maxs[measure] = Math.max.apply(
                Math,
                bank.countries.map(country =>
                  isCIOM(country) ? -Infinity : country.datapoints[measure]
                )
              );
              bank.Mins[measure] = Math.min.apply(
                Math,
                bank.countries.map(country =>
                  isCIOM(country) ? Infinity : country.datapoints[measure]
                )
              );
              if (!bank.Totals[measure]) {
                console.log(
                  `bank.Totals[${measure}] not found. Computing sum for ${bank.bank}.`
                );
                bank.Totals[measure] = bank.countries.reduce(
                  (sum, country) =>
                    sum + (isCIOM(country) ? 0 : country.datapoints[measure]),
                  0
                );
              }
            });

            // HSBC and Nordea declare respectively -4355.6296 and -686 mln€ as
            // "Intra HSBC Items" and "Eliminations" in less profits. Here we account for this.
            if (bank.bank === 'HSBC') bank.Totals.Turnover -= 4355.6296;
            if (bank.bank === 'Nordea') bank.Totals.Turnover -= 686;

            // Standard Chartered declares -2299.08 in Turnover and -570.7128 in
            // Profits as "Group adjustments". Here we account for this.
            if (bank.bank === 'Standard Chartered') {
              bank.Totals.Turnover -= 2299.08;
              bank.Totals.Profit -= 570.7128;
            }
          });

          // THEN compute global totals for all banks
          measures.forEach(measure => {
            top20.byBank.Maxs[measure] = Math.max.apply(
              Math,
              top20.byBank.map(bank => bank.Totals[measure])
            );
            top20.byBank.Mins[measure] = Math.min.apply(
              Math,
              top20.byBank.map(bank => bank.Totals[measure])
            );
            top20.byBank.Totals[measure] = top20.byBank.reduce(
              (sum, bank) => sum + bank.Totals[measure],
              0
            );
          });

          // FIX "China with HK" mess
          const byCountryChinaPR = top20.byCountry.find(
            country => country.country === 'China, P.R.: Mainland'
          );
          const byCountryChinaWithHK = top20.byCountry.find(
            country => country.country === 'China with HK'
          );
          const byCountryChinaWithHKIndex = top20.byCountry.findIndex(
            country => country.country === 'China with HK'
          );
          byCountryChinaWithHK.banks.forEach(bank => {
            bank.name.replace('China with HK', 'China, P.R.: Mainland');
            byCountryChinaPR.banks.push(bank);
          });
          top20.byCountry.splice(byCountryChinaWithHKIndex, 1);
          byCountryChinaPR.reportedChinaWithHK = ChinaWithHK.get(year);

          // FINALLY compute totals per each country
          top20.byCountry.forEach(country => {
            // 1. For each country, calculate total num of banks present
            const numBanks = country.banks.length;

            // 1b. Pull up to Max/Min
            if (
              !top20.byCountry.Maxs.Banks ||
              numBanks > top20.byCountry.Maxs.Banks
            )
              top20.byCountry.Maxs.Banks = numBanks;
            if (
              !top20.byCountry.Mins.Banks ||
              numBanks < top20.byCountry.Mins.Banks
            )
              top20.byCountry.Mins.Banks = numBanks;

            // 2. Find, for each country, and for each "measure", the Max, Min and Total value
            // computed from its banks present
            country.Maxs = {};
            country.Mins = {};
            country.Totals = {};
            measures.forEach(measure => {
              country.Maxs[measure] = Math.max.apply(
                Math,
                country.banks.map(bank => bank.datapoints[measure])
              );
              country.Mins[measure] = Math.min.apply(
                Math,
                country.banks.map(bank => bank.datapoints[measure])
              );
              country.Totals[measure] = country.banks.reduce(
                (sum, bank) => sum + bank.datapoints[measure],
                0
              );
            });
          });
        }
      }

      callback(v2);
    })
    .catch(reason => {
      console.error({ '[AppError]': 'Error retrieving data', reason });
    });
};
