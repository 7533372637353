export const COLOR_TURNOVER = 'rgba(67,142,202,1)'; // '#438ECA';
export const COLOR_PROFIT = 'rgba(191,154,227,1)'; // '#BF9AE3';
export const COLOR_EXPECTED_PROFIT = 'rgba(105,211,255,1)'; // '#69D3FF';
export const COLOR_TAX = 'rgba(247,181,57,1)'; // '#F7B539';
export const COLOR_TAXATION = 'rgba(247,181,57,1)'; // '#F7B539';
export const COLOR_TAXATION_DARKER = 'rgba(245,164,12,1)';
export const COLOR_EMPLOYEES = 'rgba(149,173,198,1)'; // '#95ADC6';
export const COLOR_OPERATIONS = 'rgba(188,207,0,1)'; // '#BCCF00';
export const COLOR_PROFITABILITY = 'rgba(231,123,61,1)'; // '#E77B3D';
export const COLOR_PRODUCTIVITY = 'rgba(214,58,112,1)'; // '#D63A70';
export const COLOR_GRAY = 'rgba(221,221,221,1)'; // '#DDDDDD';
export const COLOR_GRAY_DARKER = 'rgba(180,180,180,1)'; // '#B4B4B4';
export const COLOR_BLACK = 'rgba(125,125,125,1)'; //
export const COLOR_LIGHT = 'rgba(247, 247, 248, 1)'; // '#F7F7F8';

export const STIFFNESS = 90;
export const DUMPING = 18;

export const NIVO_THEMED = {
  fontFamily:
    "'HelveticaNeue', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
  axis: {
    ticks: {
      text: {
        fill: '#666',
        fontSize: 10,
      },
    },
  },
  grid: {
    stroke: '#f5f5f5',
  },
  tooltip: {
    container: {
      background: 'rgba(51, 51, 51, 0.9)',
      color: '#fff',
      fontSize: '12px',
      borderRadius: '0',
      boxShadow: 'none',
      padding: '10px 14px',
    },
    basic: {
      whiteSpace: 'pre',
      display: 'flex',
      alignItems: 'center',
    },
    table: {},
    tableCell: {
      padding: '3px 5px',
    },
  },
};

export const KEYS = [
  'Turnover',
  'Profit',
  'Tax',
  'Employees',
  'Operations',
  'Profitability',
  'Productivity',
  'Taxation',
];

export const COMPUTE = {
  Productivity: {
    dividend: 'Profit',
    divisor: 'Employees',
  },
  Profitability: {
    dividend: 'Profit',
    divisor: 'Turnover',
  },
  Taxation: {
    dividend: 'Tax',
    divisor: 'Profit',
  },
};

export const BASE_TITLE =
  'Corporate Tax Tracker - Transparency International EU';

export const GA_TRACKING_ID = 'UA-5771039-10';
