import React, { useRef, useEffect } from 'react';
import { ResponsiveLine } from '@nivo/line';
import { area, curveLinear } from 'd3-shape';

import Loading from '../../components/Loading';
import Tooltip from '../../components/Tooltip';
import { formatNum, formatPerc } from '../../utils/helpers';
import {
  COLOR_PROFITABILITY,
  COLOR_PRODUCTIVITY,
  COLOR_TAXATION_DARKER,
  COMPUTE,
  STIFFNESS,
  DUMPING,
  NIVO_THEMED,
} from '../../VARS';

// How much of the area below 0 to show (0.1 = 10%)
const CUTOFF = 0.15;

const notables = {
  Productivity: [],
  Profitability: [],
  Taxation: ['UniCredit', 'KfW', 'Bayerische Landesbank'],
};

const AnnualComparisonLine = ({
  height,
  data,
  chartAgainst,
  selectedBank,
  hoveredBank,
  hoveredPoint,
  onHover,
  onOut,
  onClick,
}) => {
  const mappedData = useRef({});

  const notableHovering = useRef(false);

  // Call onOut() every 50ms bc @nivo/line doesn't properly fire onMouseLeave :/
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (
        hoveredBank &&
        !document.querySelector('.charttip') &&
        !notableHovering.current
      )
        onOut();
    }, 16);

    return () => clearInterval(intervalId);
  }, [onOut, hoveredBank]);

  if (!data)
    return (
      <div className="loading is-bar-chart">
        <Loading />
      </div>
    );

  const mapDataAgainst = (chartAgainst, selectedBank) => {
    // Shorthands
    const dividend = COMPUTE[chartAgainst].dividend;
    const divisor = COMPUTE[chartAgainst].divisor;

    // Go through every year in the dataset, and every bank, and build the average
    let i = 1;
    let mapped = [];
    let years = [];
    for (const year in data) {
      if (data.hasOwnProperty(year)) {
        years.push(year);
        const yearlyDataset = data[year];

        let iterableData = yearlyDataset.byBank;
        let nameAccessor = datum => datum.bank;
        let dataAccessor = datum => datum.Totals;

        // If we have a selected bank set the accessors so that the bank's
        // countries data is used instead
        if (selectedBank !== '*') {
          const bank = yearlyDataset.byBank.find(
            bank => bank.bank === selectedBank
          );
          // Years may be missing... :/
          if (!bank) {
            console.error(
              `[AppError] no data for bank ${selectedBank}, year ${year}`
            );
            iterableData = [];
          } else {
            iterableData = bank.countries;
          }
          nameAccessor = datum => datum.country;
          dataAccessor = datum => datum.datapoints;
        }

        // If it's the first year, build the main array.
        // For the other years, build on top of the years before.
        // NOTE: We assume the exact same banks and countries appear in EVERY YEAR
        if (i === 1) {
          mapped = iterableData.map((item, idx) => {
            let value =
              dataAccessor(item)[dividend] === 0
                ? 0
                : dataAccessor(item)[dividend] / dataAccessor(item)[divisor];

            if (dataAccessor(item)[divisor] === 0) {
              console.error('[AppError] trying to divide by 0', {
                item,
                dividend,
                divisor,
              });
              value = null;
            }

            return {
              idx: idx,
              id: nameAccessor(item),
              data: [
                {
                  x: year,
                  y: value,
                },
              ],
              years: [
                {
                  year,
                  dividend: dataAccessor(item)[dividend],
                  divisor: dataAccessor(item)[divisor],
                },
              ],
            };
          });
        } else {
          iterableData.forEach(
            (mapped => {
              return (item, idx) => {
                let value =
                  dataAccessor(item)[dividend] === 0
                    ? 0
                    : dataAccessor(item)[dividend] /
                      dataAccessor(item)[divisor];

                if (dataAccessor(item)[divisor] === 0) {
                  console.error('[AppError] trying to divide by 0', {
                    item,
                    dividend,
                    divisor,
                  });
                  value = null;
                }

                const savedItem = mapped.find(
                  mappedItem => mappedItem.id === nameAccessor(item)
                );

                // This means that, for example, the 2016 data for a bank includes
                // a country that the 2015 data does not
                if (!savedItem) {
                  mapped.push({
                    idx: idx,
                    id: nameAccessor(item),
                    data: [
                      {
                        x: year,
                        y: value,
                      },
                    ],
                    years: [
                      {
                        year,
                        dividend: dataAccessor(item)[dividend],
                        divisor: dataAccessor(item)[divisor],
                      },
                    ],
                  });
                } else {
                  savedItem.data.push({
                    x: year,
                    y: value,
                  });
                  savedItem.years.push({
                    year,
                    dividend: dataAccessor(item)[dividend],
                    divisor: dataAccessor(item)[divisor],
                  });
                }
              };
            })(mapped)
          );
        }
        i++;
      }
    }

    // Now find out if there are missing items and fill them with null values
    mapped.forEach(item => {
      if (item.data.length !== years.length) {
        for (let j = 0; j < years.length; j++) {
          const loopedYear = years[j];
          const loopedYearData = item.data.find(data => data.x === loopedYear);
          if (!loopedYearData) {
            item.data.splice(j, 0, { x: loopedYear, y: null });
          }
        }
      }
    });

    // Now compute averages (in order to show global trend)
    mapped.globalTrend = [];
    years.forEach((year, i) => {
      const globalYear = { year, dividend: 0, divisor: 0 };
      mapped.forEach(item => {
        const yearData = item.years.find(yearData => yearData.year === year);
        globalYear.dividend += yearData ? yearData.dividend : 0;
        globalYear.divisor += yearData ? yearData.divisor : 0;
      });
      globalYear.dividend /= mapped.length;
      globalYear.divisor /= mapped.length;
      mapped.globalTrend.push(globalYear);
    });

    return mapped;
  };

  if (!mappedData.current[chartAgainst]) mappedData.current[chartAgainst] = {};

  if (!mappedData.current[chartAgainst][selectedBank])
    mappedData.current[chartAgainst][selectedBank] = mapDataAgainst(
      chartAgainst,
      selectedBank
    );

  const colorBy = item => {
    let color = '#fff';
    const opacity = item.id === hoveredBank ? 1 : 0.6;
    switch (chartAgainst) {
      case 'Productivity':
        color = COLOR_PRODUCTIVITY;
        break;

      case 'Profitability':
        color = COLOR_PROFITABILITY;
        break;

      case 'Taxation':
        color = COLOR_TAXATION_DARKER;
        break;

      default:
        break;
    }
    return color.replace('1)', opacity + ')');
  };

  const CustomLines = ((hoveredBank, hoveredPoint) => {
    return ({ series, lineGenerator, xScale, yScale }) => {
      const hovered = series.find(({ id }) => id === hoveredBank);

      let lines = [];

      if (hovered) {
        // Filter out missing years
        const hoveredData = hovered.data.filter(d => d.data.y !== null);
        lines = lines.concat([
          <path
            key={hovered.id}
            d={lineGenerator(
              hoveredData.map(d => ({
                x: xScale(d.data.x),
                y: yScale(d.data.y),
              }))
            )}
            fill="none"
            stroke={colorBy(hovered)}
            strokeWidth={3}
          />,
        ]);
      }

      if (hovered && hoveredPoint) {
        lines = lines.concat([
          <circle
            key="circle"
            r={8}
            cx={hoveredPoint.x}
            cy={hoveredPoint.y}
            fill="none"
            stroke={colorBy(hovered)}
            strokeWidth={2}
          />,
        ]);
      }

      // Notable banks (show only in Taxation view)
      if (selectedBank === '*' && chartAgainst === 'Taxation') {
        const notableBanks = notables[chartAgainst];
        notableBanks.forEach(bankName => {
          const bank = mappedData.current[chartAgainst]['*'].find(
            item => item.id === bankName
          );
          if (!bank) return;
          const lastBankYear = bank.years[bank.years.length - 1];
          const handleEnter = (function iifeBankName(committedBankName) {
            return () => {
              notableHovering.current = true;
              onHover({ indexValue: committedBankName });
            };
          })(bankName);
          const handleLeave = () => {
            notableHovering.current = false;
            onOut();
          };
          lines.push(
            <g
              transform={`translate(${xScale(lastBankYear.year)} ${yScale(
                lastBankYear.dividend / lastBankYear.divisor
              ) - 23})`}
            >
              <g
                filter="url(#filter0_d)"
                onMouseEnter={handleEnter}
                onMouseLeave={handleLeave}
              >
                <path d="M 4 23 H 10 V 17 L 4 23 Z" fill="#f9f9f9" />
                <path
                  d="M10,7c0,-2.2 1.8,-4 4,-4l19,0c2.2,0 4,1.8 4,4l0,12c0,2.2 -1.8,4 -4,4l-23,0l0,-16Z"
                  fill="#f9f9f9"
                />
                <g fill="#4f4f4f" transform="matrix(0.7,0,0,0.7,15,5)">
                  <circle cx="12" cy="15.9" r="1.3" />
                  <path d="M 14.4 6.7 c -0.1 -0.1 -0.2 -0.2 -0.4 -0.2 h -3.9 c -0.2 0 -0.3 0.1 -0.4 0.2 c -0.1 0.1 -0.1 0.3 -0.1 0.4 l 1.8 6.1 c 0.1 0.2 0.3 0.4 0.5 0.4 h 0.3 c 0.2 0 0.4 -0.1 0.5 -0.4 l 1.8 -6.1 c 0 -0.2 0 -0.3 -0.1 -0.4 z" />
                  <path d="M 12 22.3 C 6.1 22.3 1.3 17.4 1.3 11.5 S 6.1 0.8 12 0.8 S 22.8 5.6 22.8 11.5 S 17.9 22.3 12 22.3 z m 0 -20 C 6.9 2.3 2.8 6.4 2.8 11.5 S 6.9 20.8 12 20.8 s 9.3 -4.2 9.3 -9.3 S 17.1 2.3 12 2.3 z" />
                </g>
              </g>
              <defs>
                <filter
                  id="filter0_d"
                  x="0"
                  y="0"
                  width="84"
                  height="28"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  />
                  <feOffset dy="1" />
                  <feGaussianBlur stdDeviation="2" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow"
                    result="shape"
                  />
                </filter>
              </defs>
            </g>
          );
        });
      }

      // Global trend
      if (selectedBank === '*') {
        const globalTrend =
          mappedData.current[chartAgainst][selectedBank].globalTrend;
        const firstGlobalYear = globalTrend[0];
        const lastGlobalYear = globalTrend[globalTrend.length - 1];
        lines = lines.concat([
          <path
            key="global-trend-path"
            d={lineGenerator(
              mappedData.current[chartAgainst][selectedBank].globalTrend.map(
                d => ({
                  x: xScale(d.year),
                  y: yScale(d.dividend / d.divisor),
                })
              )
            )}
            fill="none"
            stroke={colorBy({ id: null })}
            strokeWidth={3}
            strokeDasharray={4}
          />,
          <text
            key="global-trend-initial"
            x={xScale(firstGlobalYear.year) - 5}
            y={yScale(firstGlobalYear.dividend / firstGlobalYear.divisor) + 4}
            textAnchor="end"
            style={{
              fontFamily: NIVO_THEMED.fontFamily,
              fontSize: 12,
              fontWeight: 'bold',
            }}
          >
            {chartAgainst === 'Productivity'
              ? formatNum(firstGlobalYear.dividend / firstGlobalYear.divisor) +
                ' M€/FTE'
              : formatPerc(firstGlobalYear.dividend / firstGlobalYear.divisor)}
          </text>,
          <text
            key="global-trend-final"
            x={xScale(lastGlobalYear.year) + 5}
            y={yScale(lastGlobalYear.dividend / lastGlobalYear.divisor) - 4}
            style={{
              fontFamily: NIVO_THEMED.fontFamily,
              fontSize: 12,
              fontWeight: 'bold',
            }}
          >
            {chartAgainst === 'Productivity'
              ? formatNum(lastGlobalYear.dividend / lastGlobalYear.divisor) +
                ' M€/FTE'
              : formatPerc(lastGlobalYear.dividend / lastGlobalYear.divisor)}
          </text>,
          <text
            key="global-trend-text"
            x={xScale(lastGlobalYear.year) + 5}
            y={yScale(lastGlobalYear.dividend / lastGlobalYear.divisor) + 10}
            style={{
              fontFamily: NIVO_THEMED.fontFamily,
              fontSize: 12,
            }}
          >
            Global trend
          </text>,
        ]);
      } else {
        // If we select a bank and *then* change the value data is mapped against
        // (Productivity, Profitability etc.) the general data will not have been mapped
        // yet. Here we do that.
        if (!mappedData.current[chartAgainst]['*'])
          mappedData.current[chartAgainst]['*'] = mapDataAgainst(
            chartAgainst,
            '*'
          );
        const selected = mappedData.current[chartAgainst]['*'].find(
          ({ id }) => id === selectedBank
        );
        const firstSelectedYear = selected.years[0];
        const lastSelectedYear = selected.years[selected.years.length - 1];
        lines = lines.concat([
          <path
            key={selected.id}
            d={lineGenerator(
              selected.data.map(d => ({
                x: xScale(d.x),
                y: yScale(d.y),
              }))
            )}
            fill="none"
            stroke={colorBy({ id: null })}
            strokeWidth={3}
            strokeDasharray={4}
          />,
          <text
            key="bank-average-initial"
            x={xScale(firstSelectedYear.year) - 5}
            y={
              yScale(firstSelectedYear.dividend / firstSelectedYear.divisor) + 4
            }
            textAnchor="end"
            style={{
              fontFamily: NIVO_THEMED.fontFamily,
              fontSize: 12,
              fontWeight: 'bold',
            }}
          >
            {chartAgainst === 'Productivity'
              ? formatNum(
                  firstSelectedYear.dividend / firstSelectedYear.divisor
                ) + ' M€/FTE'
              : formatPerc(
                  firstSelectedYear.dividend / firstSelectedYear.divisor
                )}
          </text>,
          <text
            key="bank-average-final"
            x={xScale(lastSelectedYear.year) + 5}
            y={yScale(lastSelectedYear.dividend / lastSelectedYear.divisor) - 4}
            style={{
              fontFamily: NIVO_THEMED.fontFamily,
              fontSize: 12,
              fontWeight: 'bold',
            }}
          >
            {chartAgainst === 'Productivity'
              ? formatNum(
                  lastSelectedYear.dividend / lastSelectedYear.divisor
                ) + ' M€/FTE'
              : formatPerc(
                  lastSelectedYear.dividend / lastSelectedYear.divisor
                )}
          </text>,
          <text
            key="bank-average-text"
            x={xScale(lastSelectedYear.year) + 5}
            y={
              yScale(lastSelectedYear.dividend / lastSelectedYear.divisor) + 10
            }
            style={{
              fontFamily: NIVO_THEMED.fontFamily,
              fontSize: 12,
            }}
          >
            Bank average
          </text>,
        ]);
      }

      return lines;
    };
  })(hoveredBank, hoveredPoint);

  const AreaLayer = (hoveredBank => {
    return ({ series, xScale, yScale, innerHeight }) => {
      const selected = series.find(({ id }) => id === hoveredBank);

      let data;
      if (!selected && selectedBank !== '*') {
        return null;
      } else if (!selected) {
        data = mappedData.current[chartAgainst][selectedBank].globalTrend;
      } else {
        data = selected.years;
      }

      let divisorScale;
      switch (chartAgainst) {
        case 'Productivity':
          divisorScale = selectedBank === '*' ? 1000000 : 10000;
          break;

        case 'Profitability':
          divisorScale = selectedBank === '*' ? 100000 : 100;
          break;

        case 'Taxation':
          divisorScale = selectedBank === '*' ? 10000 : 500;
          break;

        default:
          break;
      }

      const areaGenerator = area()
        .x(d => xScale(d.year))
        .y0(d =>
          yScale(d.dividend / d.divisor - Math.abs(d.divisor / divisorScale))
        )
        .y1(d =>
          yScale(d.dividend / d.divisor + Math.abs(d.divisor / divisorScale))
        )
        .curve(curveLinear);

      return (
        <path
          d={areaGenerator(data)}
          fill="rgba(152, 171, 190, 0.2)"
          strokeWidth={0}
        />
      );
    };
  })(hoveredBank);

  const WhiteHider = ({ yScale, innerWidth }) => {
    return (
      <rect
        x="0"
        y={yScale(-CUTOFF)}
        width={innerWidth}
        height="1000"
        fill="rgba(255, 255, 255, 1)"
      />
    );
  };

  return (
    <ResponsiveLine
      data={mappedData.current[chartAgainst][selectedBank]}
      margin={{
        top: 82,
        right: 100,
        bottom: 80,
        left: 100,
      }}
      height={height}
      xScale={{ type: 'point' }}
      // yScale={{ type: 'linear', min: 'auto', max: 'auto' }}
      yScale={{ type: 'linear', min: -CUTOFF, max: 'auto' }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        orient: 'bottom',
        tickSize: 0,
        tickPadding: 25,
        tickRotation: 0,
        legend: null,
      }}
      axisLeft={{
        orient: 'left',
        tickSize: 0,
        tickPadding: 20,
        tickRotation: 0,
        tickValues: 2,
        format: chartAgainst === 'Productivity' ? formatNum : formatPerc,
        legend: null,
      }}
      colors={colorBy}
      lineWidth={1}
      enablePoints={false}
      enableGridY={false}
      useMesh={true}
      isInteractive={true}
      motionStiffness={STIFFNESS}
      motionDamping={DUMPING}
      //onMouseEnter={(e, data) => console.log({ e, data })}
      onMouseLeave={onOut}
      onClick={data => (selectedBank === '*' ? onClick(data) : null)}
      theme={NIVO_THEMED}
      markers={[
        {
          axis: 'y',
          value: 0,
          lineStyle: {
            stroke: 'rgba(0, 0, 0, 0.2)',
            strokeWidth: 1,
          },
        },
      ]}
      tooltip={({ point }) => {
        onHover(point);
        /*
        {
          "point": {
            "id": "Skandinaviska Enskilda Banken AB.2",
            "index": 101,
            "serieId": "Skandinaviska Enskilda Banken AB",
            "serieColor": "#e8a838",
            "x": 521.5999755859375,
            "y": 167,
            "color": "#e8a838",
            "borderColor": "transparent",
            "data": {
              "x": "2017",
              "y": 0.13549438836290675,
              "xFormatted": "2017",
              "yFormatted": 0.13549438836290675
            }
          }
        }
        */
        const item = mappedData.current[chartAgainst][selectedBank].find(
          item => item.id === point.serieId
        );

        const dividend = COMPUTE[chartAgainst].dividend;
        const divisor = COMPUTE[chartAgainst].divisor;
        const currentYear = item.years.find(year => year.year === point.data.x);
        let dividendTotal = 0,
          divisorTotal = 0;

        if (selectedBank === '*') {
          item.years.forEach(year => {
            dividendTotal += year.dividend ? year.dividend : 0;
            divisorTotal += year.divisor ? year.divisor : 0;
          });
        } else {
          dividendTotal = currentYear.dividend;
          divisorTotal = currentYear.divisor;
        }

        return (
          <div style={NIVO_THEMED.tooltip.container}>
            <Tooltip title={point.serieId} theme={NIVO_THEMED}>
              <div className="columns">
                <div className="column charttip__legend">
                  {selectedBank === '*' ? 'Average' : `Year ${point.data.x}`}
                </div>
              </div>
              <div className="columns">
                {selectedBank === '*' && (
                  <div
                    className={
                      chartAgainst === 'Productivity'
                        ? 'column is-two-fifths'
                        : 'column'
                    }
                  >
                    <div className={`charttip__figure is-${chartAgainst}`}>
                      {chartAgainst === 'Productivity'
                        ? formatNum(dividendTotal / divisorTotal) + ' M€/FTE'
                        : formatPerc(dividendTotal / divisorTotal)}
                    </div>
                    <div className="charttip__caption">
                      {chartAgainst === 'Taxation'
                        ? 'Effective tax rate'
                        : chartAgainst}
                    </div>
                  </div>
                )}
                <div className="column">
                  <div className={`charttip__figure is-${dividend}`}>
                    {formatNum(dividendTotal)}
                    {dividend !== 'Employees' ? ' M€' : null}
                  </div>
                  <div className="charttip__caption">
                    {dividend.replace('Tax', 'Taxes paid')}
                  </div>
                </div>
                <div className="column">
                  <div className={`charttip__figure is-${divisor}`}>
                    {formatNum(divisorTotal)}
                    {divisor !== 'Employees' ? ' M€' : null}
                  </div>
                  <div className="charttip__caption">
                    {divisor.replace('Tax', 'Taxes paid')}
                  </div>
                </div>
              </div>
              <hr />
              <div className="columns">
                <div className="column charttip__legend">
                  {chartAgainst} by year{' '}
                  {chartAgainst === 'Productivity' ? '(M€/FTE)' : null}
                </div>
              </div>
              <div className="columns">
                {item.years.map((year, i) => {
                  const prevYear = i >= 1 ? item.years[i - 1] : null;
                  const isHigher =
                    prevYear &&
                    year.dividend / year.divisor >=
                      prevYear.dividend / prevYear.divisor;
                  const isHigherClass =
                    prevYear && isHigher
                      ? 'is-higher'
                      : prevYear && !isHigher
                      ? 'is-lower'
                      : '';
                  return (
                    <div
                      key={year.year}
                      className={`column is-one-fifth ${
                        // eslint-disable-next-line
                        point.data.x == year.year ? 'is-active-year' : ''
                      }`}
                    >
                      <div
                        className={`charttip__figure is-${chartAgainst} ${isHigherClass}`}
                      >
                        {!year.divisor
                          ? '-'
                          : chartAgainst === 'Productivity'
                          ? formatNum(year.dividend / year.divisor)
                          : formatPerc(year.dividend / year.divisor)}
                      </div>
                      <div className="charttip__caption">{year.year}</div>
                    </div>
                  );
                })}
              </div>
            </Tooltip>
          </div>
        );
      }}
      layers={[
        'grid',
        'markers',
        AreaLayer,
        'lines',
        CustomLines,
        'slices',
        'points',
        'mesh',
        WhiteHider,
        'axes',
      ]}
    />
  );
};

export default AnnualComparisonLine;
