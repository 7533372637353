import React from 'react';
import TrayCarousel from '../../../components/TrayCarousel';

const Carousel2015 = ({ handleClick }) => {
  return (
    <TrayCarousel>
      {/* <div className="did-you-know__trivia">
        <button className="a" onClick={handleClick}>
          Crédit Mutuel
        </button>{' '}
        (45%) and{' '}
        <button className="a" onClick={handleClick}>
          Nordea
        </button>{' '}
        (46%) have the highest profitability among the largest European banks?
      </div> */}
      <div className="did-you-know__trivia">
        <button className="a" onClick={handleClick}>
          Deutsche Bank
        </button>{' '}
        made €88m profits in Malta while employing just 4 people there. If the
        profits in Malta would have been the average productivity of Deutsche
        Bank, the bank would have made €1.56m.
      </div>
      <div className="did-you-know__trivia">
        Three of the largest European banks made negative profits?{' '}
        <button className="a" onClick={handleClick}>
          Standard Chartered
        </button>
        ,{' '}
        <button className="a" onClick={handleClick}>
          Deutsche Bank
        </button>{' '}
        and{' '}
        <button className="a" onClick={handleClick}>
          RBS
        </button>
        .
      </div>
      {/* <div className="did-you-know__trivia">
        <button className="a" onClick={handleClick}>
          BNP Paribas
        </button>{' '}
        reported €134m profits in Cayman Islands despite having only €39m
        turnover?
      </div>
      <div className="did-you-know__trivia">
        Out of the largest European banks,{' '}
        <button className="a" onClick={handleClick}>
          RBS
        </button>{' '}
        (€77m),{' '}
        <button className="a" onClick={handleClick}>
          Lloyds
        </button>{' '}
        (€41m) and{' '}
        <button className="a" onClick={handleClick}>
          UniCredit
        </button>{' '}
        (€83m) appear to have paid the least taxes?
      </div> */}
    </TrayCarousel>
  );
};

export default Carousel2015;
