import React, { Component } from 'react';

import BarWithLabels from '../../components/BarWithLabels';
import {
  COLOR_TURNOVER,
  COLOR_PROFIT,
  COLOR_EMPLOYEES,
  COLOR_TAX,
} from '../../VARS';

class CountryDetailsBar extends Component {
  constructor(props) {
    super(props);

    // store computed
    this.year = null;
    this.mappedData = {};
    this.colorBy = () => 'transparent';
  }

  _mapData(data, chartAgainst) {
    // Build the array holding the summary for each bank
    let mapped = data.map(bank => {
      return {
        type: 'bank',
        Name: bank.bank,
        [chartAgainst]: bank.datapoints[chartAgainst],
      };
    });

    return mapped;
  }

  render() {
    const { year, data, chartAgainst } = this.props;

    if (!data) return null;

    // memoize data on first render, and on year change
    if (!this.mappedData[chartAgainst] || this.year !== year) {
      this.mappedData[chartAgainst] = this._mapData(data, chartAgainst);
      this.year = year;
    }

    const mappedData = this._mapData(data, chartAgainst);

    let color = 'rgba(255, 255, 255, 1)';
    switch (chartAgainst) {
      case 'Turnover':
        color = COLOR_TURNOVER;
        break;

      case 'Profit':
        color = COLOR_PROFIT;
        break;

      case 'Employees':
        color = COLOR_EMPLOYEES;
        break;

      case 'Tax':
        color = COLOR_TAX;
        break;

      default:
        break;
    }

    return (
      <div className={`country-details-bar is-${chartAgainst}`}>
        <BarWithLabels
          className="chart-country-details"
          data={mappedData}
          keys={[chartAgainst, '']}
          indexBy="Name"
          colors={color}
          axisBottom={chartAgainst === 'Operations' ? null : true}
          marginLeft={3}
          height={mappedData.length * 29 + 25}
          format="num"
        />
      </div>
    );
  }
}

export default CountryDetailsBar;
