import d3 from './d3Importer';

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    var error = new Error(response.statusText);
    error.response = response;
    throw error;
  }
}

function parseJSON(response) {
  return response.json();
}

function XHRError(error) {
  console.error('request failed', error);
}

function formatNum(num) {
  if (Math.abs(num) >= 1000) {
    return num.toLocaleString(undefined, { maximumFractionDigits: 0 });
  } else if (Math.abs(num) >= 100) {
    return num.toLocaleString(undefined, { maximumFractionDigits: 1 });
  } else if (Math.abs(num) >= 1) {
    return num.toLocaleString(undefined, { maximumFractionDigits: 2 });
  } else {
    return num.toLocaleString(undefined, { maximumFractionDigits: 3 });
  }
}

function formatPerc(perc) {
  if (isNaN(perc)) return 'N/A';
  return d3.format('.2p')(perc);
}

function formatLabel(label) {
  if (label.indexOf('filler') === 0) {
    return '';
  } else if (label.indexOf('Channel Islands +') === 0) {
    return 'CHI + IOM (for all banks)';
  } else if (label.indexOf('Channel Islands (') === 0) {
    return 'CHI (RBS) & CHI + IOM (HSBC)';
  }

  return label;
}

function GAPageView(location, history, GA_TRACKING_ID) {
  const gtag = window.gtag;

  // if (location.pathname === this.props.location.pathname) {
  //   // don't log identical link clicks (nav links likely)
  //   return;
  // }

  if (
    (history.action === 'PUSH' || history.action === 'POP') &&
    typeof gtag === 'function'
  ) {
    // console.info('gtag', {
    //   page: window.location.href,
    //   title: document.title,
    // });
    gtag('config', GA_TRACKING_ID, {
      page_title: document.title,
      page_location: window.location.href,
      page_path: location.pathname,
    });
  }
}

export {
  checkStatus,
  parseJSON,
  XHRError,
  formatNum,
  formatPerc,
  formatLabel,
  GAPageView,
};
