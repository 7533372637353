import React, { Component, Fragment } from 'react';
import memoize from 'memoize-one';
import { Helmet } from 'react-helmet';
import Fuse from 'fuse.js';

import _ from '../utils/lodashImporter';
import ErrorBoundary from '../ErrorBoundary';
import Select from '../components/Select';
import Info from '../components/Info';
import YearSelector from '../components/YearSelector';
import TrayCountryProfiles from './countryProfiles/TrayCountryProfiles';
import FrequencyPie from './countryProfiles/FrequencyPie';
import ListsSunburst from './countryProfiles/ListsSunburst';
import Top20CountriesBar from './countryProfiles/Top20CountriesBar';
import WorldMap from './countryProfiles/WorldMap';
import CountryDetailsBar from './countryProfiles/CountryDetailsBar';
import {
  COLOR_EMPLOYEES,
  COLOR_OPERATIONS,
  COLOR_PROFIT,
  COLOR_TURNOVER,
  COLOR_GRAY,
  COLOR_BLACK,
  BASE_TITLE,
  GA_TRACKING_ID,
} from '../VARS';
import { formatNum, GAPageView } from '../utils/helpers';
import mostN from '../utils/mostN';

// let Fuse;
// if (process.env.NODE_ENV === 'development') {
//   Fuse = require('fuse.js');
// }

/* ONE DAY...
const PageCountryProfiles = ({ year, data, loadYear, location, history }) => {
  // state
  const [selectedRegion, setSelectedRegion] = useState('*'); // or region name
  const [selectedList, setSelectedList] = useState('*'); // or bank name
  const [selectedCountry, setSelectedCountry] = useState('*'); // or country name
  const [hoveredRegion, setHoveredRegion] = useState(null);
  const [hoveredList, setHoveredList] = useState(null);
  const [hoveredCountry, setHoveredCountry] = useState(null); // bank|country
  const [chartAgainst, setChartAgainst] = useState('Operations'); // Operations|Turnover|Profit|Employees
*/

class PageCountryProfiles extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRegion: '*', // or region name
      selectedList: '*', // or bank name
      selectedCountry: '*', // or country name
      hoveredRegion: null,
      hoveredList: null,
      hoveredCountry: null, // bank|country
      chartAgainst: 'Operations', // Operations|Turnover|Profit|Employees
    };

    this.chartableAgainst = ['Operations', 'Turnover', 'Profit', 'Employees'];
    this.detailsAgainst = [
      'Operations',
      'Turnover',
      'Profit',
      'Employees',
      'Tax',
    ];

    this.year = null;
    this.mappedData = {
      regions: {},
      // lists: {},
      countries: {},
      zero: {},
      burst: {},
    };
    this.regionList = [];
    this.listList = [];

    this.detailsData = null;

    this.insightsRef = React.createRef();

    this.colorByGenerator = (selected, hovered, chartAgainst, bare = false) => {
      function isSelected(datumId, check) {
        if (datumId === check) return true;
        if (
          (datumId === 'black-grey' && check === 'black') ||
          (datumId === 'black-grey' && check === 'grey')
        )
          return true;
        return false;
      }

      return datum => {
        let color = 'rgba(255, 255, 255, 1)';
        let opacity = 1;
        switch (chartAgainst) {
          case 'Operations':
            color = COLOR_OPERATIONS;
            break;

          case 'Turnover':
            color = COLOR_TURNOVER;
            break;

          case 'Profit':
            color = COLOR_PROFIT;
            break;

          case 'Employees':
            color = COLOR_EMPLOYEES;
            break;

          default:
            break;
        }

        // Is it the 0% Corporate Tax rate?
        if (datum.id === 'zero') return COLOR_BLACK.replace('1)', '0.6)');
        if (datum.id === 'other') return color.replace('1)', '0.2)');

        // hover, selected, what? opacity is an ugly beast...
        if (!bare) {
          if (selected !== '*') {
            if (!isSelected(datum.id, selected)) opacity -= 0.8;
            if (hovered && datum.id === hovered) opacity += 0.4;
          } else {
            if (hovered && datum.id !== hovered) opacity -= 0.4;
          }

          color = color.replace('1)', opacity + ')');
        }

        return color;
      };
    };
    this.colorByRegionsPie = memoize(this.colorByGenerator);
    this.colorByListsPie = memoize(this.colorByGenerator);
    this.colorByZeroPie = memoize(this.colorByGenerator);
    this.colorByBurst = memoize(this.colorByGenerator);
    this.colorByMap = memoize(this.colorByGenerator);

    this.shouldTrackPageView = false;

    // bnd
    this.handleChange = this.handleChange.bind(this);
    //this.handleHover = _.debounce(this.handleHover.bind(this), 20);
    this.handleHover = this.handleHover.bind(this);
    this.handleOut = this.handleOut.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleTrayClick = this.handleTrayClick.bind(this);
    this.handleClickAgainst = this.handleClickAgainst.bind(this);
    this.handleCountryClick = this.handleCountryClick.bind(this);
  }

  handleChange(e) {
    if (this.state[e.currentTarget.name]) {
      this.setState({ [e.currentTarget.name]: e.currentTarget.value });
    }
    e.target.blur();
  }

  handleHover(data) {
    // onMouseOut
    if (data === null) {
      this.setState({ hoveredRegion: null, hoveredList: null });
      return;
    }

    let stateProp;
    if (data.type) {
      stateProp = 'hovered' + _.upperFirst(data.type);
      this.setState({ [stateProp]: data.id });
    }

    // onMouseHover: If data.data.key is present then it's the AggregateBar,
    // otherwise it's AllBanks. Also, are we hovering a bank or a country?
    // const hoveredCountry = data.data.key ? data.id : data.indexValue;
    // this.setState({ hoveredCountry });
  }

  handleOut() {
    this.handleHover(null);
  }

  handleClick(data) {
    let stateProp;
    if (data.type) {
      stateProp = 'selected' + _.upperFirst(data.type);
      if (data.id === this.state[stateProp]) {
        this.setState({ [stateProp]: '*' });
      } else {
        this.setState({ [stateProp]: data.id });
      }
    }
  }

  handleTrayClick(e) {
    const selectedCountry = e.currentTarget.textContent;
    this.setState({ selectedCountry });
  }

  handleClickAgainst(e) {
    if (this.state[e.currentTarget.name]) {
      this.setState({ [e.currentTarget.name]: e.currentTarget.value });
    }
  }

  handleCountryClick(countryName) {
    const byCountry =
      this.props.data && this.props.data[this.props.year]
        ? this.props.data[this.props.year].byCountry
        : [];
    let countryExists = byCountry.find(
      country => country.country === countryName
    );

    // debug
    if (!countryExists && process.env.NODE_ENV === 'development') {
      console.error(
        '[AppError] could not find country ' + countryName + ' in',
        byCountry
      );
      if (Fuse !== undefined) {
        const fuse = new Fuse(byCountry, {
          keys: ['country'],
          shouldSort: true,
        });
        const result = fuse.search(countryName);
        console.log(`... did you mean`, result);
      }
    }

    // Let's try to prevent future and easy to miss errors
    // (eg. "Macedonia" !== "Nort Macedonia") by making a best guess
    if (!countryExists) {
      const fuse = new Fuse(byCountry, {
        keys: ['country'],
        shouldSort: true,
      });
      const result = fuse.search(countryName);
      if (result.length) {
        console.log(`Clicked ${countryName} but found ${result[0].country}`);
        countryExists = true;
        countryName = result[0].country;
      }
    }

    if (!countryExists) {
      this.setState({ selectedCountry: '*' });
    } else if (countryName !== this.state.selectedCountry) {
      this.setState({ selectedCountry: countryName });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.selectedCountry !== this.state.selectedCountry &&
      this.insightsRef.current
    ) {
      this.insightsRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }

  _getCountryValue(value, byCountry) {
    let country = byCountry.find(
      item => item.country === this.state.selectedCountry
    );
    if (!country) return 0;
    return value === 'Operations'
      ? country.banks.length
      : country.Totals[value];
  }

  _mapMeta(meta) {
    const regionList = new Set();
    const listList = new Set();
    meta.forEach(country => {
      // Ignore as it's not a country
      if (country.name === 'Other') return;

      regionList.add(country.region);
      listList.add(country.list);
    });
    this.regionList = Array.from(regionList).sort();
    this.listList = Array.from(listList).sort();
  }

  _mapRegions(data, meta, chartAgainst) {
    const regions = {};

    const MostN = new mostN(
      999, // whatever
      (a, b) => {
        if (a < b) return -1;
        if (b < a) return 1;
        return 0;
      },
      value => value[chartAgainst]
    );

    meta.forEach(countryMeta => {
      // Ignore as it's not a country
      if (countryMeta.name === 'Other') return;

      const countryData = _.find(data, { country: countryMeta.name });
      if (!countryData) {
        if (process.env.NODE_ENV !== 'development') return;
        console.error(
          `[AppError] could not find country "${countryMeta.name}" in`,
          data
        );
        if (Fuse !== undefined) {
          const fuse = new Fuse(data, {
            keys: ['country'],
            shouldSort: true,
          });
          const result = fuse.search(countryMeta.name);
          console.log(`... did you mean`, result);
        }
        return;
      }

      // fill in regions (Pie)
      if (!regions[countryMeta.region]) {
        regions[countryMeta.region] = {
          id: countryMeta.region,
          label: countryMeta.region,
          value: 0,
          type: 'region',
        };
      }
      regions[countryMeta.region].value +=
        chartAgainst === 'Operations'
          ? countryData.banks.length
          : countryData.Totals[chartAgainst];

      // fill in countries (Bar)
      let fill = 'rgba(255, 255, 255, 1)';
      switch (chartAgainst) {
        case 'Operations':
          fill = COLOR_OPERATIONS;
          break;

        case 'Turnover':
          fill = COLOR_TURNOVER;
          break;

        case 'Profit':
          fill = COLOR_PROFIT;
          break;

        case 'Employees':
          fill = COLOR_EMPLOYEES;
          break;

        default:
          break;
      }
      fill =
        countryMeta.list === 'grey'
          ? COLOR_GRAY
          : countryMeta.list === 'black'
          ? COLOR_BLACK
          : fill;
      const country = {
        type: 'country',
        fill: fill,
        Name: countryData.country,
        list: countryMeta.list,
        region: countryMeta.region,
        [chartAgainst]:
          chartAgainst === 'Operations'
            ? countryData.banks.length
            : countryData.Totals[chartAgainst],
      };

      // FIX "China with HK"
      if (countryData.country === 'China, P.R.: Mainland') {
        country.reportedChinaWithHK = countryData.reportedChinaWithHK;
      }

      MostN.addAndCompute(country);
    });

    this.mappedData.regions[chartAgainst] = Object.values(regions);
    this.mappedData.countries[chartAgainst] = MostN.all.reverse();
  }

  _mapBurst(data, meta, chartAgainst, year) {
    const burst = {
      label: 'burst',
      children: [],
    };
    const zeroList = [];

    meta.forEach(countryMeta => {
      // Ignore as it's not a country
      if (countryMeta.name === 'Other') return;

      if (
        this.state.selectedRegion !== '*' &&
        this.state.selectedRegion !== countryMeta.region
      )
        return;

      const countryData = data.find(
        country => country.country === countryMeta.name
      );
      if (!countryData) {
        if (process.env.NODE_ENV !== 'development') return;
        console.error(
          `[AppError] could not find country "${countryMeta.name}" in`,
          data
        );
        if (Fuse !== undefined) {
          const fuse = new Fuse(data, {
            keys: ['country'],
            shouldSort: true,
          });
          const result = fuse.search(countryMeta.name);
          console.log(`... did you mean`, result);
        }
        return;
      }

      const label =
        countryMeta.list === 'others'
          ? 'Others'
          : 'Black/grey-listed countries';
      let list = burst.children.find(
        child =>
          (countryMeta.list === 'others' && child.label === label) ||
          (countryMeta.list !== 'others' && child.label === label)
      );
      if (!list) {
        list = {
          id: countryMeta.list === 'others' ? 'others' : 'black-grey',
          label,
          type: 'list',
          value: 1,
          zeroPercent: 0,
          // children: [
          //   {
          //     id: 'other',
          //     label: 'Other Tax Rate',
          //     value: 0,
          //     type: 'taxRate',
          //   },
          //   {
          //     id: 'zero',
          //     label: '0% Corporate Tax Rate',
          //     value: 0,
          //     type: 'taxRate',
          //   },
          // ],
        };
        burst.children.push(list);
      }

      list.value +=
        chartAgainst === 'Operations'
          ? countryData.banks.length
          : countryData.Totals[chartAgainst];

      const taxRate =
        countryMeta[`nominalTR${year}`] === 0
          ? '0% Corporate Tax Rate'
          : 'Other Tax Rate';

      if (taxRate === '0% Corporate Tax Rate') {
        list.zeroPercent +=
          chartAgainst === 'Operations'
            ? countryData.banks.length
            : countryData.Totals[chartAgainst];
      }

      // let zero = list.children.find(child => child.label === taxRate);

      // zero.value +=
      //   chartAgainst === 'Operations'
      //     ? countryData.banks.length
      //     : countryData.Totals[chartAgainst];

      if (countryMeta[`nominalTR${year}`] === 0) {
        zeroList.push({
          country: countryMeta.name,
          value:
            chartAgainst === 'Operations'
              ? countryData.banks.length
              : countryData.Totals[chartAgainst],
        });
      }
    });

    if (!this.mappedData.burst[chartAgainst]) {
      this.mappedData.burst[chartAgainst] = {};
    }
    this.mappedData.burst[chartAgainst][this.state.selectedRegion] = burst;

    if (!this.mappedData.zero[chartAgainst]) {
      this.mappedData.zero[chartAgainst] = {};
    }
    this.mappedData.zero[chartAgainst][this.state.selectedRegion] = zeroList;
  }

  // _mapLists(data, meta, chartAgainst) {
  //   // We are already checking in render(), but 2 is better than 1
  //   if (
  //     this.mappedData.lists[chartAgainst] &&
  //     this.mappedData.lists[chartAgainst][this.state.selectedRegion]
  //   )
  //     return;

  //   const lists = {};

  //   meta.forEach(countryMeta => {
  //     // Ignore as it's not a country
  //     if (countryMeta.name === 'Other') return;

  //     const countryData = _.find(data, { country: countryMeta.name });
  //     if (!countryData) {
  //       if (process.env.NODE_ENV !== 'development') return;
  //       console.error(
  //         `[AppError] could not find country "${countryMeta.name}" in`,
  //         data
  //       );
  //       if (Fuse !== undefined) {
  //         const fuse = new Fuse(data, {
  //           keys: ['country'],
  //           shouldSort: true,
  //         });
  //         const result = fuse.search(countryMeta.name);
  //         console.log(`... did you mean`, result);
  //       }
  //       return;
  //     }

  //     // fill in lists (Pie)
  //     const listLabel =
  //       countryMeta.list === 'others'
  //         ? 'Others'
  //         : 'Black/grey-listed countries';
  //     if (!lists[listLabel]) {
  //       lists[listLabel] = {
  //         id: listLabel,
  //         label: listLabel,
  //         value: 0,
  //         type: 'list',
  //       };
  //     }
  //     if (
  //       this.state.selectedRegion === '*' ||
  //       this.state.selectedRegion === countryMeta.region
  //     ) {
  //       lists[listLabel].value +=
  //         chartAgainst === 'Operations'
  //           ? countryData.banks.length
  //           : countryData.Totals[chartAgainst];
  //     }
  //   });

  //   // delete black-grey list if 0
  //   if (
  //     lists['Black/grey-listed countries'] &&
  //     lists['Black/grey-listed countries'].value === 0
  //   ) {
  //     delete lists['Black/grey-listed countries'];
  //   }

  //   if (!this.mappedData.lists[chartAgainst]) {
  //     this.mappedData.lists[chartAgainst] = {};
  //   }
  //   this.mappedData.lists[chartAgainst][
  //     this.state.selectedRegion
  //   ] = Object.values(lists);
  // }

  componentDidMount() {
    this.shouldTrackPageView = true;
  }

  render() {
    // We need this "fake initial value" because we changed how yearly data
    // is sent down from App.js - what happens now is that we send the data for 3 years.
    // This hack is so that this component keeps working as is, and won't need a
    // rewrite for the time being.
    const data =
      this.props.data && this.props.data[this.props.year]
        ? this.props.data[this.props.year]
        : {
            byBank: null,
            byCountry: null,
            countryMeta: null,
          };

    if (
      (!this.regionList.length || this.year !== this.props.year) &&
      data.countryMeta
    ) {
      this._mapMeta(data.countryMeta);
    }

    if (
      (!this.mappedData.regions[this.state.chartAgainst] ||
        this.year !== this.props.year) &&
      data.byCountry
    ) {
      this._mapRegions(
        data.byCountry,
        data.countryMeta,
        this.state.chartAgainst
      );
    }

    // if (
    //   (!this.mappedData.lists[this.state.chartAgainst] ||
    //     !this.mappedData.lists[this.state.chartAgainst][
    //       this.state.selectedRegion
    //     ] ||
    //     this.year !== this.props.year) &&
    //   data.byCountry
    // ) {
    //   this._mapLists(data.byCountry, data.countryMeta, this.state.chartAgainst);
    // }

    if (
      (!this.mappedData.burst[this.state.chartAgainst] ||
        !this.mappedData.burst[this.state.chartAgainst][
          this.state.selectedRegion
        ] ||
        this.year !== this.props.year) &&
      data.byCountry
    ) {
      this._mapBurst(
        data.byCountry,
        data.countryMeta,
        this.state.chartAgainst,
        this.props.year
      );
    }

    this.year = this.props.year;

    const colorByRegionsPie = this.colorByRegionsPie(
      this.state.selectedRegion,
      this.state.hoveredRegion,
      this.state.chartAgainst
    );
    const colorByListsPie = this.colorByListsPie(
      this.state.selectedList,
      this.state.hoveredList,
      this.state.chartAgainst
    );
    /*const colorByZeroPie = this.colorByZeroPie(
      undefined,
      undefined,
      this.state.chartAgainst
    );*/
    const colorByBurst = this.colorByBurst(
      this.state.selectedList,
      this.state.hoveredList,
      this.state.chartAgainst
    );
    const mainColorMap = this.colorByMap(
      this.state.selectedList,
      this.state.hoveredList,
      this.state.chartAgainst,
      true
    )({ id: null });

    // Calculate country details on the fly, sigh...
    let detailsData = [];
    if (data.byCountry && this.state.selectedCountry !== '*') {
      detailsData = data.byCountry
        .find(country => country.country === this.state.selectedCountry)
        .banks.slice(0);

      if (['Jersey', 'Guernsey'].indexOf(this.state.selectedCountry) >= 0) {
        const channelIslandsBanks = data.byBank.filter(
          bank =>
            bank.countries.find(
              country => country.country.indexOf('Channel Islands') >= 0
            ) !== undefined
        );
        channelIslandsBanks.forEach(bank => {
          let country = bank.countries.find(
            country => country.country === 'Channel Islands'
          );
          if (country) {
            country.bank = bank.bank + '*';
            detailsData.unshift(country);
          }
        });
      }

      if (this.state.selectedCountry === 'Isle of Man') {
        data.byBank.forEach(bank => {
          if (bank.bank !== 'HSBC') return;

          let country = bank.countries.find(
            country => country.country === 'Channel Islands'
          );
          if (country) {
            country.bank = bank.bank + '*';
            detailsData.unshift(country);
          }
        });
      }
    }

    return (
      <Fragment>
        <Helmet
          onChangeClientState={(newState, addedTags, removedTags) => {
            if (this.shouldTrackPageView) {
              this.shouldTrackPageView = false;
              GAPageView(
                this.props.location,
                this.props.history,
                GA_TRACKING_ID
              );
            }
          }}
        >
          <title>Country Profiles | {BASE_TITLE}</title>
        </Helmet>

        <YearSelector year={this.props.year} loadYear={this.props.loadYear} />
        <TrayCountryProfiles
          year={this.props.year}
          location={this.props.location}
          handleClick={this.handleTrayClick}
        />
        <section className="hero">
          <div className="hero-body">
            <div className="container">
              {/******************** TITLE ********************/}
              <div className="columns">
                <div className="column">
                  <h1 className="title">Display Data By</h1>
                </div>
              </div>

              {/******************** CONTROLS ********************/}
              <div className="columns">
                <div className="column">
                  <div className="buttons has-addons chart-against">
                    {this.chartableAgainst.map(against => (
                      <button
                        name="chartAgainst"
                        key={against}
                        value={against}
                        className={
                          this.state.chartAgainst === against
                            ? 'button is-primary is-selected is-' + against
                            : 'button is-' + against
                        }
                        onClick={this.handleClickAgainst}
                      >
                        {against}
                      </button>
                    ))}
                  </div>
                  <Select
                    label="In"
                    labelInline={true}
                    name="selectedRegion"
                    defaultName="All regions"
                    defaultValue="*"
                    values={this.regionList}
                    value={this.state.selectedRegion}
                    onChange={this.handleChange}
                  />
                  <Select
                    label="And"
                    labelInline={true}
                    name="selectedList"
                    defaultName="All countries"
                    defaultValue="*"
                    values={this.listList}
                    value={this.state.selectedList}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="column is-2">
                  <div className="frequency-top-bar__title chart-title">
                    Top 20 Countries
                    <br />
                    by {this.state.chartAgainst}
                  </div>
                </div>
              </div>

              {/******************** CHARTS ********************/}
              <div className="columns">
                <div className="column is-3">
                  <ErrorBoundary>
                    <FrequencyPie
                      type="regions"
                      data={
                        this.mappedData.regions[this.state.chartAgainst]
                          ? this.mappedData.regions[this.state.chartAgainst]
                          : []
                      }
                      year={this.props.year}
                      chartAgainst={this.state.chartAgainst}
                      colorBy={colorByRegionsPie}
                      onHover={this.handleHover}
                      onOut={this.handleOut}
                      onClick={this.handleClick}
                    />
                  </ErrorBoundary>
                  <ErrorBoundary>
                    <ListsSunburst
                      data={
                        this.mappedData.burst[this.state.chartAgainst] &&
                        this.mappedData.burst[this.state.chartAgainst][
                          this.state.selectedRegion
                        ]
                          ? this.mappedData.burst[this.state.chartAgainst][
                              this.state.selectedRegion
                            ]
                          : []
                      }
                      zero={
                        this.mappedData.zero[this.state.chartAgainst] &&
                        this.mappedData.zero[this.state.chartAgainst][
                          this.state.selectedRegion
                        ]
                          ? this.mappedData.zero[this.state.chartAgainst][
                              this.state.selectedRegion
                            ]
                          : []
                      }
                      year={this.props.year}
                      chartAgainst={this.state.chartAgainst}
                      colorBy={colorByBurst}
                      onHover={this.handleHover}
                      onOut={this.handleOut}
                      // onClick={this.handleClick}
                    />
                  </ErrorBoundary>
                </div>
                <div className="column is-7">
                  <ErrorBoundary>
                    <WorldMap
                      countryData={data.byCountry}
                      countryMeta={data.countryMeta}
                      year={this.props.year}
                      chartAgainst={this.state.chartAgainst}
                      selectedRegion={this.state.selectedRegion}
                      selectedList={this.state.selectedList}
                      mainColor={mainColorMap}
                      handleClick={this.handleCountryClick}
                    />
                  </ErrorBoundary>
                </div>
                <div className="column is-2">
                  <ErrorBoundary>
                    <Top20CountriesBar
                      height="525"
                      data={this.mappedData.countries[this.state.chartAgainst]}
                      year={this.props.year}
                      chartAgainst={this.state.chartAgainst}
                      selectedRegion={this.state.selectedRegion}
                      selectedList={this.state.selectedList}
                      colorBy={colorByListsPie}
                      handleClick={this.handleCountryClick}
                    />
                  </ErrorBoundary>
                </div>
              </div>

              {/******************** INSIGHTS ********************/}
              {data.byCountry && this.state.selectedCountry !== '*' ? (
                <div className="columns" ref={this.insightsRef}>
                  <div className="column is-3" />
                  <div className="column is-7 country-details-charts">
                    <Select
                      label={`Operating banks in ${this.state.selectedCountry}`}
                      labelInline={true}
                      name="selectedCountry"
                      defaultName="All countries"
                      defaultValue="*"
                      values={[]}
                      value={this.state.selectedCountry}
                      onChange={this.handleChange}
                    />
                    <Info
                      position="right"
                      text="You can see the exact amount of turnover, profits, taxes paid and full-time employees by bank by hovering over each bar."
                    />
                    <div className="columns">
                      {this.detailsAgainst.map((detailsAgainst, i) => (
                        <div
                          className="column"
                          style={{ minWidth: i === 0 ? '165px' : 0 }}
                          key={detailsAgainst}
                        >
                          <div
                            className={`aggregate-number aggregate-number__${detailsAgainst}`}
                          >
                            <strong>
                              {formatNum(
                                this._getCountryValue(
                                  detailsAgainst,
                                  data.byCountry
                                )
                              )}
                              {detailsAgainst !== 'Operations' &&
                              detailsAgainst !== 'Employees' ? (
                                <span>M€</span>
                              ) : null}
                            </strong>
                            <small>
                              {detailsAgainst !== 'Operations'
                                ? detailsAgainst.replace(
                                    'Tax',
                                    'Tax on profit or loss'
                                  )
                                : null}
                            </small>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="columns">
                      {this.detailsAgainst.map((detailsAgainst, i) => (
                        <div
                          className="column"
                          style={{ minWidth: i === 0 ? '165px' : 0 }}
                          key={detailsAgainst}
                        >
                          <CountryDetailsBar
                            data={detailsData}
                            year={this.props.year}
                            chartAgainst={detailsAgainst}
                          />
                        </div>
                      ))}
                    </div>
                    <p>
                      <small>
                        <b>Note:</b>{' '}
                        <i>
                          On certain bar graphs, when there is a big difference
                          in the size of the bars, the smaller bars disappear
                          entirely, which gives the impression that that there
                          is a value of <b>0</b> for that figure, rather than
                          just a relatively small value.
                        </i>
                      </small>
                    </p>
                    {['Jersey', 'Guernsey'].indexOf(
                      this.state.selectedCountry
                    ) >= 0 ? (
                      <p>
                        <small>
                          <i>
                            * <b>RBS</b> and <b>Lloyds</b> have reported its
                            operations in all Channel Islands, <b>HSBC</b> has
                            reported its operations in all Channel Islands and
                            in Isle of Man.
                          </i>
                        </small>
                      </p>
                    ) : this.state.selectedCountry === 'Isle of Man' ? (
                      <p>
                        <small>
                          <i>
                            * <b>HSBC</b> has reported its operations in all
                            Channel Islands and in Isle of Man.
                          </i>
                        </small>
                      </p>
                    ) : null}
                  </div>
                  <div className="column is-2" />
                </div>
              ) : null}
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}

export default PageCountryProfiles;
