import React from 'react';
import TrayCarousel from '../../../components/TrayCarousel';

const Carousel2015 = ({ handleClick }) => {
  return (
    <TrayCarousel>
      <div className="did-you-know__trivia">
        According to data from 2017,{' '}
        <button className="a" onClick={handleClick}>
          Banco Santander
        </button>{' '}
        had the highest productivity in Ireland. Just 4 employees generated €38m
        in profits. No taxes were reported to have been paid. If the
        productivity of these employees had matched Santander's average, the
        profits would have been €0.21m.
      </div>
      <div className="did-you-know__trivia">
        If operating with similar productivity rates as it has in other
        countries,{' '}
        <button className="a" onClick={handleClick}>
          Intesa Sanpaolo
        </button>
        ’s operations in 2017 in Ireland would have generated some €13.27m of
        profits. However, due to the higher productivity, the profits were
        twentyseven times higher: €503m.
      </div>
      <div className="did-you-know__trivia">
        <button className="a" onClick={handleClick}>
          Crédit Mutuel
        </button>{' '}
        declared no employees, taxes or turnover in Morocco or Tunisia. However,
        the bank reported €65m of profits in Morocco and €17m in Tunisia.
      </div>
      <div className="did-you-know__trivia">
        <button className="a" onClick={handleClick}>
          Barclays
        </button>{' '}
        has multiple operations in African countries, in which the bank did not
        report any taxes, but notable profits. This includes operations in
        Kenya, Mauritius, Mozambique, Tanzania, Uganda and Zambia.
      </div>
      <div className="did-you-know__trivia">
        In 2017 the Saudi Arabian operations of{' '}
        <button className="a" onClick={handleClick}>
          HSBC
        </button>{' '}
        generated €391m of profits. The bank reported no turnover, taxes paid or
        full time employees in the country.
      </div>
      <div className="did-you-know__trivia">
        <button className="a" onClick={handleClick}>
          UniCredit
        </button>{' '}
        reported €285m of profits in Turkey, while not reporting any turnover,
        taxes, or full time employees in the country.
      </div>
    </TrayCarousel>
  );
};

export default Carousel2015;
