import React from 'react';
import TrayCarousel from '../../../components/TrayCarousel';

const Carousel2015 = ({ handleClick }) => {
  return (
    <TrayCarousel slidesToShow={1}>
      <div className="did-you-know__trivia">
        10 large European banks had operations in The{' '}
        <button className="a" onClick={handleClick}>
          Cayman Islands
        </button>
        .
      </div>
      <div className="did-you-know__trivia">
        <button className="a" onClick={handleClick}>
          Singapore
        </button>{' '}
        is one of the most common locations for the largest European banks. In
        2015, 22 of 39 largest European banks had operations in the country.
      </div>
      <div className="did-you-know__trivia">
        Many of the largest European banks have operations in the Channel
        Islands. In 2015, 10 banks had operations in{' '}
        <button className="a" onClick={handleClick}>
          Jersey
        </button>{' '}
        and 7 in{' '}
        <button className="a" onClick={handleClick}>
          Guernsey
        </button>
        .
      </div>
      <div className="did-you-know__trivia">
        <button className="a" onClick={handleClick}>
          Ireland
        </button>{' '}
        was the most common location where the European banks operated with 0%
        effective tax rate in 2015. Altogether 7 banks reported paying no taxes
        in{' '}
        <button className="a" onClick={handleClick}>
          Ireland
        </button>
        .
      </div>
    </TrayCarousel>
  );
};

export default Carousel2015;
