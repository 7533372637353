import React from 'react';
import TrayCarousel from '../../../components/TrayCarousel';

const Carousel2015 = ({ handleClick }) => {
  return (
    <TrayCarousel slidesToShow={2}>
      <div className="did-you-know__trivia">
        <button className="a" onClick={e => handleClick(e, 'Productivity')}>
          UniCredit
        </button>{' '}
        had operations in 8 countries with no employees, yet declared profits in
        these locations.
      </div>
      <div className="did-you-know__trivia">
        <button className="a" onClick={e => handleClick(e, 'Productivity')}>
          Société Générale
        </button>{' '}
        had profitable operations in 9 countries without reporting any
        employees.
      </div>
      <div className="did-you-know__trivia">
        The most productive employees of{' '}
        <button className="a" onClick={e => handleClick(e, 'Productivity')}>
          Deutsche Bank
        </button>{' '}
        were based in Malta. The 4 employees generated more than €22m profits
        each.
      </div>
      <div className="did-you-know__trivia">
        <button className="a" onClick={e => handleClick(e, 'Profitability')}>
          RBS
        </button>{' '}
        reported €1140m of profits in Ireland but only €764m turnover.
      </div>
      <div className="did-you-know__trivia">
        <button className="a" onClick={e => handleClick(e, 'Taxation')}>
          BPCE
        </button>{' '}
        operated in 26 jurisdictions where it did not report any taxes paid.
      </div>
      <div className="did-you-know__trivia">
        <button className="a" onClick={e => handleClick(e, 'Taxation')}>
          Barclays
        </button>{' '}
        operated in 5 jurisdictions where it did not pay any taxes, despite
        declaring profits in all of these countries.
      </div>
    </TrayCarousel>
  );
};

export default Carousel2015;
