// https://stackoverflow.com/a/20261974/416714
// https://jsperf.com/sorted-array-insert-comparison/1

const defaultCompareFn = (a, b) => {
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
};

export default class mostN {
  constructor(N, compareFn = defaultCompareFn, extractorFn = value => value) {
    this._N = N;
    this._store = [];
    this._compareFn = compareFn;
    this._extractorFn = extractorFn;
  }

  get all() {
    return this._store.slice(0);
  }

  get most() {
    return this._store.slice(0, this._N);
  }

  _locationOf(element, array, start, end) {
    if (array.length === 0) return -1;

    start = start || 0;
    end = end || array.length;
    var pivot = Math.floor((start + end) / 2);

    var c = this._compareFn(
      this._extractorFn(element),
      this._extractorFn(array[pivot])
    );
    if (end - start <= 1) return c === 1 ? pivot - 1 : pivot;

    switch (c) {
      case 1:
        return this._locationOf(element, array, start, pivot);
      case -1:
        return this._locationOf(element, array, pivot, end);
      case 0:
      default:
        return pivot;
    }
  }

  addAndCompute(element) {
    this._store.splice(this._locationOf(element, this._store) + 1, 0, element);
  }

  addArrayAndCompute(elements) {
    elements.forEach(element => {
      this.addAndCompute(element);
    });
  }

  reset() {
    this._store = [];
  }
}
