/* eslint jsx-a11y/anchor-is-valid: "off", no-script-url: "off" */

import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

//import logo from '../images/TI_logo.svg';
import logo from '../images/TI_logo.png';
import logo2x from '../images/TI_logo@2x.png';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayMenu: false,
    };

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    this.setState({ displayMenu: !this.state.displayMenu });
  }

  render() {
    const { path } = this.props;
    let classes = 'navbar';

    if (/*path === '/' || */ path === '/about') classes += ' is-fixed-top';

    return (
      <nav className={classes}>
        <div className="navbar-brand">
          <NavLink className="navbar-item" to="/">
            <img
              src={logo}
              srcSet={`${logo} 1x, ${logo2x} 2x`}
              alt="Transparency International"
              height="30"
              width="146"
            />
            <span>
              Corporate
              <br />
              Tax Tracker
            </span>
          </NavLink>
          <a
            role="button"
            className={
              this.state.displayMenu
                ? 'navbar-burger is-active'
                : 'navbar-burger'
            }
            aria-label="menu"
            aria-expanded="false"
            onClick={this.handleClick}
          >
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </a>
        </div>

        <div
          className={
            this.state.displayMenu ? 'navbar-menu is-active' : 'navbar-menu'
          }
        >
          <div className="navbar-start"> </div>

          <div className="navbar-end">
            <NavLink
              className="navbar-item"
              activeClassName="is-active"
              to="/bank-profiles"
              onClick={this.handleClick}
            >
              Bank Profiles
            </NavLink>
            <NavLink
              className="navbar-item"
              activeClassName="is-active"
              to="/country-profiles"
              onClick={this.handleClick}
            >
              Country Profiles
            </NavLink>
            <NavLink
              className="navbar-item"
              activeClassName="is-active"
              to="/overall-risk"
              onClick={this.handleClick}
            >
              Overall Risk Indicators
            </NavLink>
            <NavLink
              className="navbar-item"
              activeClassName="is-active"
              to="/annual-comparison"
              onClick={this.handleClick}
            >
              Annual Comparison
            </NavLink>
            <NavLink
              className="navbar-item is-secondary"
              activeClassName="is-active"
              exact
              to="/about"
              onClick={this.handleClick}
            >
              About
            </NavLink>
          </div>
        </div>
      </nav>
    );
  }
}

export default Header;
