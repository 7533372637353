import React, { Fragment } from 'react';
import { ResponsiveBar } from '@nivo/bar';

import Tooltip from '../components/Tooltip';
import { formatNum, formatPerc, formatLabel } from '../utils/helpers';
import { STIFFNESS, DUMPING, NIVO_THEMED, KEYS } from '../VARS';

const margin = {
  top: 20,
  right: 15,
  bottom: 20,
  left: 15,
};
const padding = 0.15;
const formats = {
  num: formatNum,
  perc: formatPerc,
};

const BarWithLabels = ({
  className,
  height,
  data,
  keys,
  indexBy,
  colors,
  markers,
  format,
  axisBottom = true,
  axisTop = null,
  marginLeft = null,
  isDiscrepancy = false,
  ...rest
}) => (
  <div
    className={`overlayed-container ${className}`}
    style={{ height: `${height}px` }}
  >
    <ResponsiveBar
      data={data}
      keys={keys}
      indexBy={indexBy}
      margin={
        marginLeft === null
          ? margin
          : Object.assign(margin, { left: marginLeft })
      }
      padding={padding}
      layout="horizontal"
      colors={colors}
      axisBottom={
        axisBottom !== null
          ? {
              orient: 'bottom',
              tickSize: 0,
              tickPadding: 10,
              tickRotation: 0,
              tickValues: 2,
              format: formats[format],
              legend: '',
            }
          : null
      }
      axisTop={
        axisTop !== null
          ? {
              orient: 'top',
              tickSize: 0,
              tickPadding: 5,
              tickRotation: 0,
              tickValues: 2,
              format: formats[format],
              legend: '',
            }
          : null
      }
      axisLeft={null}
      enableGridY={false}
      enableLabel={false}
      markers={markers}
      isInteractive={false}
      motionStiffness={STIFFNESS}
      motionDamping={DUMPING}
      theme={NIVO_THEMED}
      {...rest}
    />
    <div
      className="labels-ghost-chart"
      style={{ height: `${height}px`, width: '100%' }}
    >
      <ResponsiveBar
        data={data}
        keys={keys}
        indexBy={indexBy}
        margin={
          marginLeft === null
            ? margin
            : Object.assign(margin, { left: marginLeft })
        }
        padding={padding}
        layout="horizontal"
        colors={colors}
        colorBy="id"
        axisBottom={null}
        axisLeft={{
          orient: 'left',
          tickSize: 0,
          tickPadding: -5,
          tickRotation: 0,
          format: formatLabel,
          legend: '',
        }}
        enableGridY={false}
        enableLabel={false}
        tooltip={({ data }) => {
          return (
            <Tooltip title={data.Name} key={data.Name}>
              {KEYS.map((key, i) => {
                if (!data[key]) return null;
                if (i % 2 === 1 && data[KEYS[i - 1]]) return null; // skip odds

                const key1 = key;
                const key2 =
                  KEYS[i + 1] && data[KEYS[i + 1]] ? KEYS[i + 1] : false;
                let label1;
                let label2;

                const formatFn1 =
                  ['Profitability', 'Taxation'].indexOf(key1) >= 0
                    ? formatPerc
                    : formatNum;
                const suffix1 =
                  ['Turnover', 'Profit', 'Tax'].indexOf(key1) >= 0 ? ' M€' : '';

                let formatFn2 = false;
                let suffix2 = false;

                if (key1 === 'Taxation' && !isDiscrepancy) {
                  label1 = 'Effective tax rate';
                } else if (key1 === 'Taxation' && isDiscrepancy) {
                  label1 = 'Discrepancy';
                } else if (key1 === 'Tax') {
                  label1 = 'Taxes paid';
                } else {
                  label1 = key1;
                }

                if (key2) {
                  formatFn2 =
                    ['Profitability', 'Taxation'].indexOf(key2) >= 0
                      ? formatPerc
                      : formatNum;
                  suffix2 =
                    ['Turnover', 'Profit', 'Tax'].indexOf(key2) >= 0
                      ? ' M€'
                      : '';

                  if (key2 === 'Taxation' && !isDiscrepancy) {
                    label2 = 'Effective tax rate';
                  } else if (key2 === 'Taxation' && isDiscrepancy) {
                    label2 = 'Discrepancy';
                  } else if (key2 === 'Tax') {
                    label2 = 'Taxes paid';
                  } else {
                    label2 = key2;
                  }
                }

                return (
                  <Fragment key={i}>
                    <div className="columns">
                      <div className="column is-half">
                        <div className={`charttip__figure is-${key1}`}>
                          {formatFn1(data[key1])} {suffix1}
                        </div>
                        <div className="charttip__caption">
                          {key1 === 'Productivity'
                            ? key1 + ' (M€/FTE)'
                            : label1}
                        </div>
                      </div>
                      {key2 ? (
                        <div className="column is-half">
                          <div className={`charttip__figure is-${key2}`}>
                            {formatFn2(data[key2])} {suffix2}
                          </div>
                          <div className="charttip__caption">
                            {key2 === 'Productivity'
                              ? key2 + ' (M€/FTE)'
                              : label2}
                          </div>
                        </div>
                      ) : null}
                    </div>
                    {data.Name.indexOf('Channel') === 0 && (
                      <div className="charttip__caption">
                        <span>
                          <i>
                            The bank aggregates multiple
                            <br /> jurisdictions in its report
                          </i>
                        </span>
                      </div>
                    )}
                    {key1 === 'Operations' && data.reportedChinaWithHK && (
                      <div className="charttip__caption">
                        <span>
                          *{data.reportedChinaWithHK} operations were
                          <br /> reported as China with Hong Kong
                        </span>
                      </div>
                    )}
                  </Fragment>
                );
              })}
            </Tooltip>
          );
        }}
        theme={NIVO_THEMED}
        animate={true}
        motionStiffness={STIFFNESS}
        motionDamping={DUMPING}
        labelTextColor="#FFF"
        {...rest}
      />
    </div>
  </div>
);

export default BarWithLabels;
