import React, { Component } from 'react';

import './tray.sass';
import handle from '../images/tray-handle.svg';

class Tray extends Component {
  constructor(props) {
    super(props);

    const savedState = window.sessionStorage.getItem(props.location.pathname);
    const open = savedState === null || savedState === 'true' ? true : false;

    this.state = {
      open: open,
    };

    this.handleClick = this.handleClick.bind(this);
    this.saveStateToSessionStorage = this.saveStateToSessionStorage.bind(this);
  }

  handleClick() {
    this.setState({ open: !this.state.open });
  }

  saveStateToSessionStorage() {
    window.sessionStorage.setItem(
      this.props.location.pathname,
      this.state.open
    );
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.saveStateToSessionStorage);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.saveStateToLocalStorage);

    this.saveStateToSessionStorage();
  }

  render() {
    return (
      <div className={`tray ${this.state.open ? 'is-open' : ''}`}>
        <button
          className="button tray_handle"
          aria-hidden="true"
          onClick={this.handleClick}
        >
          <img src={handle} alt="" />
        </button>
        <div className="tray_container">
          <div className="tray_content content">{this.props.children}</div>
        </div>
      </div>
    );
  }
}

export default Tray;
