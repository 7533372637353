/* eslint jsx-a11y/anchor-is-valid: "off", no-script-url: "off" */

import React from 'react';
import TrayCarouselAnnualComparison from './TrayCarouselAnnualComparison';

import Tray from '../../components/Tray';

import clickity from '../../images/clickity.svg';

const TrayOverallRisk = ({ year, location, handleClick }) => (
  <Tray location={location}>
    <div className="container">
      <div className="columns">
        <div className="column is-5">
          <p>
            This page visualises the banks’ annual trends by showing how their
            behavioural changes throughout the years affect the risk indicators
            we used in the previous page. It provides an overview of how the
            productivity, profitability, and effective tax rates of the largest
            European banks have evolved in their reported countries of
            operation.
          </p>
          <p className="clickity">
            <img src={clickity} alt="" />
            Click on the charts to explore data in-depth
          </p>
        </div>
        <div className="column is-6 is-offset-1 did-you-know-container">
          <p>
            <small>
              <b>DID YOU KNOW THAT...</b>
            </small>
          </p>
          <TrayCarouselAnnualComparison handleClick={handleClick} />
        </div>
      </div>
    </div>
  </Tray>
);

export default TrayOverallRisk;
