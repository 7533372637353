/**
 * NOTE: THIS FILE TO BE KEPT IN SYNC WITH ./_theme.sass
 */

const BLACK = '#0a0a0a';

// Set your brand variables
export const TITLE_FAMILY = 'Folio, sans-serif';

// Fix https://github.com/jgthms/bulma/pull/2039#issuecomment-423222778
export const GAP = 32 + 'px';
export const DESKTOP = 960 + 2 * 32 + 'px';

// Update Bulma's variables;
export const FAMILY_SANS_SERIF =
  "'HelveticaNeue', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif";
export const BLUE = 'hsl(198, 92, 46)';
// export const PRIMARY = BLUE;
export const BODY_COLOR = '#4d4d4d';
export const BODY_RENDERING = 'auto';
export const STRONG_COLOR = BODY_COLOR;
export const TITLE_COLOR = BLACK;

// Our colors
export const TURNOVER_COLOR = '#438ECA';
export const PROFIT_COLOR = '#BF9AE3';
export const EXPECTED_PROFIT_COLOR = '#69D3FF';
export const PRIMARY_COLOR = TURNOVER_COLOR;
export const PRIMARY = TURNOVER_COLOR;
export const TAX_COLOR = '#F7B539';
export const EMPLOYEES_COLOR = '#95ADC6';
export const OPERATIONS_COLOR = '#BCCF00';
export const PROFITABILITY_COLOR = '#E77B3D';
export const PRODUCTIVITY_COLOR = '#D63A70';
export const GREY_COLOR = '#DDD';
export const BLACK_COLOR = 'rgb(125, 125, 125)';

// More vars
export const NAVBAR_HEIGHT = '3.75rem';
