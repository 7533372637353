import React, { Component } from 'react';
import Scrollbar from 'react-scrollbars-custom';

import Loading from '../../components/Loading';
import BarWithLabels from '../../components/BarWithLabels';
import Info from '../../components/Info';
import _ from '../../utils/lodashImporter';
import d3 from '../../utils/d3Importer';
/* import mostN from '../../utils/mostN';
import sample from '../../utils/sample'; */
import { formatPerc } from '../../utils/helpers';
import {
  COLOR_PROFITABILITY,
  COLOR_PRODUCTIVITY,
  COLOR_TAXATION,
  COMPUTE,
} from '../../VARS';

class AnomalousBanksBar extends Component {
  constructor(props) {
    super(props);

    // store computed
    this.year = null;
    this.mappedData = {};
    this.colorBy = () => 'transparent';

    // store input
    this.hoveredBank = null;
    this.hoveredBankId = -1;
    this.selectedBank = null;
    this.selectedBankId = -1;
  }

  _mapData(data, chartAgainst, cases = []) {
    // Shorthands
    const dividend = COMPUTE[chartAgainst].dividend;
    const divisor = COMPUTE[chartAgainst].divisor;

    // Build the array holding the summary for each bank
    let mapped = data.map(bank => {
      let value =
        bank.Totals[dividend] === 0
          ? 0
          : bank.Totals[dividend] / bank.Totals[divisor];

      // We need it fake!
      //if (chartAgainst === 'Tax') value = -1;

      return {
        type: 'bank',
        Name: bank.bank,
        [chartAgainst]: value,
      };
    });

    // Calculate the standard deviation and mean for the 'whole' banks
    //const deviation = d3.deviation(mapped, bank => bank[chartAgainst]);
    const mean = d3.mean(mapped, bank => bank[chartAgainst]);

    /******************
     *  OPTION NUMBER A
     * ***************** */
    /*
    // Go through each bank and find the 5 that are the furthest
    //
    // 1. For each bank, go through each of its country HQ
    // 2. For each HQ, get the <deviation>
    const Most5 = new mostN(
      5,
      (a, b) => {
        if (Math.abs(a) < Math.abs(b)) return -1;
        if (Math.abs(a) > Math.abs(b)) return 1;
        return 0;
      },
      value =>
        value.datapoints[dividend] / value.datapoints[divisor] - mean
    );

    data.forEach(bank => {
      bank.countries.forEach(country => {
        Most5.addAndCompute(country);
      });
    });

    */
    /******************
     *  OPTION NUMBER B
     * ***************** */
    /*
    // Go through each bank and find the ones that are
    // more than 3 deviations away
    const deviated = [];
    data.forEach(bank => {
      bank.countries.forEach(country => {
        // Ignore +/-Infinity values :/
        if (country.datapoints[divisor] === 0) return;

        // Add the rest
        let value =
          country.datapoints[dividend] === 0
            ? 0
            : country.datapoints[dividend] / country.datapoints[divisor];
        const fromDeviation = Math.abs(value - mean);
        if (fromDeviation / deviation > 3) {
          deviated.push(country);
        }
      });
    });

    // Extract a sample and add to mapped data
    const deviatedSample = sample(deviated, 5);
    deviatedSample.forEach(bank => {
      mapped.push({
        type: 'case',
        Name: bank.name,
        [chartAgainst]:
          bank.datapoints[dividend] === 0
            ? 0
            : bank.datapoints[dividend] / bank.datapoints[divisor],
      });
    });
    */

    // Add cases
    if (cases.length) {
      cases.forEach(bank => {
        mapped.push({
          type: 'case',
          Name: bank.name,
          [chartAgainst]:
            bank.datapoints[dividend] === 0
              ? 0
              : bank.datapoints[dividend] / bank.datapoints[divisor],
        });
      });
    }
    mapped.concat(cases);

    // Finally, sort and attach mean/deviation values
    mapped = _.orderBy(mapped, chartAgainst, 'asc');
    mapped.mean = mean;
    //mapped.deviation = deviation;

    return mapped;
  }

  render() {
    const { year, data, chartAgainst, hoveredBank, selectedBank } = this.props;

    if (!data)
      return (
        <div className="loading is-bar-chart">
          <Loading />
        </div>
      );

    // memoize data on first render, and on year change
    if (!this.mappedData[chartAgainst] || this.year !== year) {
      this.mappedData[chartAgainst] = this._mapData(
        data,
        chartAgainst /*, cases*/
      );
    }

    // memoize hovered on first render, and on year change
    if (
      this.hoveredBank !== hoveredBank ||
      this.selectedBank !== selectedBank ||
      this.year !== year
    ) {
      // 1. find ID of hovered and selected bank and memoize
      this.hoveredBankId = _.findIndex(this.mappedData[chartAgainst], {
        Name: hoveredBank,
      });
      this.selectedBankId = _.findIndex(this.mappedData[chartAgainst], {
        Name: selectedBank,
      });

      // 2. customize color function to provide for different opacities
      this.colorBy = (hoveredBankId => {
        return datum => {
          // exit early
          //if (datum.id === 'Tax') return 'transparent';

          let color = 'rgba(255, 255, 255, 1)';
          let opacity = 1;
          switch (datum.id) {
            case 'Profitability':
              color = COLOR_PROFITABILITY;
              break;

            case 'Productivity':
              color = COLOR_PRODUCTIVITY;
              break;

            case 'Taxation':
              color = COLOR_TAXATION;
              break;

            default:
              break;
          }

          // hover, selected, what? opacity is an ugly beast...
          if (selectedBank !== '*') {
            if (datum.indexValue !== selectedBank) opacity -= 0.8;
            if (hoveredBankId >= 0 && datum.index === hoveredBankId)
              opacity += 0.4;
          } else {
            if (hoveredBankId >= 0 && datum.index !== hoveredBankId)
              opacity -= 0.4;
          }

          color = color.replace('1)', opacity + ')');

          return color;
        };
      })(this.hoveredBankId);

      // 3. memoize
      this.hoveredBank = hoveredBank;
      this.selectedBank = selectedBank;
    }

    this.year = year;

    const mean = this.mappedData[chartAgainst].mean;

    let title = '';
    if (chartAgainst === 'Productivity') {
      title = 'Most Productive Banks';
    } else if (chartAgainst === 'Profitability') {
      title = 'Most Profitable Banks';
    } else {
      title = 'Banks by taxes paid';
    }

    return (
      <div className={`anomalous-bar is-${chartAgainst}`}>
        <div className="anomalous-bar__title chart-title">
          <div>
            {title}{' '}
            {chartAgainst !== 'Taxation' ? (
              <Info
                text={
                  chartAgainst === 'Productivity'
                    ? 'Labour productivity means the amount of profits (before tax) a bank or a corporation make per employee in a certain jurisdiction. Comparing the productivity between different operations can reveal discrepancies in real economic activities.'
                    : chartAgainst === 'Profitability'
                    ? 'Profitability of the operations in a jurisdiction is measured by the amount of profits a bank or corporation make in comparison to their turnover. As the current country-by-country reports only provide information on pre-tax profits, this ratio is calculated by dividing profits made in a selected jurisdiction by the overall turnover in that jurisdiction. Extremely high profits compared to the turnover in a jurisdiction, and low ones in another, may indicate profit shifting or other corporate arrangements.'
                    : null
                }
              />
            ) : null}
          </div>
        </div>
        <Scrollbar
          className="chart-most-banks"
          maximalThumbSize={120}
          noScrollX={true}
          style={{ height: '575px' }}
        >
          <BarWithLabels
            className="chart-most-banks__chart"
            data={this.mappedData[chartAgainst]}
            keys={[chartAgainst, '']}
            indexBy="Name"
            colors={this.colorBy}
            markerss={
              chartAgainst !== 'Taxx'
                ? [
                    {
                      axis: 'x',
                      value: mean,
                      lineStyle: {
                        stroke: 'rgba(0, 0, 0, 0.3)',
                        strokeWidth: 1,
                        strokeDasharray: 5,
                      },
                      legend: `Mean value: ${formatPerc(mean)}`,
                      textStyle: {
                        fontSize: '12px',
                        fontStyle: 'italic',
                        fill: 'rgba(0, 0, 0, 0.5)',
                      },
                      legendOffsetY: -4,
                      legendOffsetX: -4,
                    } /*
                  {
                    axis: 'x',
                    value: 0,
                    lineStyle: {
                      stroke: '#999',
                      strokeWidth: 1,
                    },
                  },*/,
                  ]
                : null
            }
            axisBottom={chartAgainst !== 'Taxx' ? true : null}
            axisTop={true}
            height={this.props.height}
            format={chartAgainst === 'Productivity' ? 'num' : 'perc'}
            onMouseEnter={this.props.onHover}
            onMouseLeave={this.props.onOut}
            onClick={this.props.onClick}
            isInteractive={chartAgainst !== 'Taxx'}
          />
        </Scrollbar>
      </div>
    );
  }
}

export default AnomalousBanksBar;
