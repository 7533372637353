import React from 'react';
import TrayCarousel from '../../../components/TrayCarousel';

const Carousel2015 = ({ handleClick }) => {
  return (
    <TrayCarousel slidesToShow={2}>
      <div className="did-you-know__trivia">
        15 banks reported profits in countries with no employees.
      </div>
      <div className="did-you-know__trivia">
        <button className="a" onClick={e => handleClick(e, 'Taxation')}>
          Barclays
        </button>{' '}
        paid 0% corporate tax rate on more than 48% of its total annual profit.
      </div>
      <div className="did-you-know__trivia">
        <button className="a" onClick={e => handleClick(e, 'Profitability')}>
          RBS
        </button>{' '}
        reported higher profits than turnover in 4 jurisdictions. Those are
        Taiwan, India, Poland and Malaysia.
      </div>
      <div className="did-you-know__trivia">
        <button className="a" onClick={e => handleClick(e, 'Productivity')}>
          Société Générale
        </button>{' '}
        reported income in 5 different jurisdictions with no employees.
      </div>
      <div className="did-you-know__trivia">
        The difference between the profitability of Spanish banks in their home
        country and overall profitability in all jurisdictions is almost 29%.
        While Spanish banks' profit ratio was -3.64% in Spain, the average
        profit ratio of the banks was 25.2%
      </div>
      <div className="did-you-know__trivia">
        In 34 cases, banks reported higher profits in a jurisdiction than
        turnover.
      </div>
    </TrayCarousel>
  );
};

export default Carousel2015;
