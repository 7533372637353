import React from 'react';

import Tray from '../../../components/Tray';
import Carousel2015 from './Carousel2015';
import Carousel2016 from './Carousel2016';
import Carousel2017 from './Carousel2017';
import Carousel2018 from './Carousel2018';
import Carousel2019 from './Carousel2019';

import clickity from '../../../images/clickity.svg';

const carousels = {
  '2015': Carousel2015,
  '2016': Carousel2016,
  '2017': Carousel2017,
  '2018': Carousel2018,
  '2019': Carousel2019,
};

const TrayCountryProfiles = ({ year, location, handleClick }) => {
  const CarouselContent = carousels[year];

  // Get a random country from this grey list for the second slide
  // of the <Carousel />
  // const operations = [18, 17, 17, 13, 11, 10, 9, 9, 8];
  // const countries = [
  //   'Switzerland',
  //   'Luxembourg',
  //   'Singapore',
  //   'Hong Kong, China',
  //   'Jersey',
  //   'Cayman Islands',
  //   'Guernsey',
  //   'Mauritius',
  //   'Monaco',
  // ];
  // const rand = Math.floor(Math.random() * operations.length);
  // const randOperation = operations[rand];
  // const randCountry = countries[rand];

  return (
    <Tray location={location}>
      <div className="container">
        <div className="columns">
          <div className="column is-5">
            <p>
              This page gives an overview of which countries are the main
              operational locations of the largest European banks. It also shows
              how many of these countries are currently on the EU’s blacklist
              and grey list of tax havens, and highlights each bank’s most
              profitable countries of operation.
            </p>
            <p className="clickity">
              <img src={clickity} alt="" />
              Click on the charts to explore data in-depth
            </p>
          </div>
          <div className="column is-3 did-you-know-container">
            <p>
              <small>
                <b>BANKS’ HEADQUARTERS</b>
              </small>
            </p>
            <dl className="headquarters">
              <dt>Germany</dt>
              <dd>
                <div className="is-sr-only">8</div>
                <div className="headquarter" aria-hidden />
                <div className="headquarter" aria-hidden />
                <div className="headquarter" aria-hidden />
                <div className="headquarter" aria-hidden />
                <div className="headquarter" aria-hidden />
                <div className="headquarter" aria-hidden />
                <div className="headquarter" aria-hidden />
                <div className="headquarter" aria-hidden />
              </dd>
              <dt>UK</dt>
              <dd>
                <div className="is-sr-only">6</div>
                <div className="headquarter" aria-hidden />
                <div className="headquarter" aria-hidden />
                <div className="headquarter" aria-hidden />
                <div className="headquarter" aria-hidden />
                <div className="headquarter" aria-hidden />
                <div className="headquarter" aria-hidden />
              </dd>
              <dt>France</dt>
              <dd>
                <div className="is-sr-only">5</div>
                <div className="headquarter" aria-hidden />
                <div className="headquarter" aria-hidden />
                <div className="headquarter" aria-hidden />
                <div className="headquarter" aria-hidden />
                <div className="headquarter" aria-hidden />
              </dd>
              <dt>Spain</dt>
              <dd>
                <div className="is-sr-only">4</div>
                <div className="headquarter" aria-hidden />
                <div className="headquarter" aria-hidden />
                <div className="headquarter" aria-hidden />
                <div className="headquarter" aria-hidden />
              </dd>
              <dt>Netherlands</dt>
              <dd>
                <div className="is-sr-only">3</div>
                <div className="headquarter" aria-hidden />
                <div className="headquarter" aria-hidden />
                <div className="headquarter" aria-hidden />
              </dd>
              <dt>Italy</dt>
              <dd>
                <div className="is-sr-only">3</div>
                <div className="headquarter" aria-hidden />
                <div className="headquarter" aria-hidden />
                <div className="headquarter" aria-hidden />
              </dd>
              <dt>Sweden</dt>
              <dd>
                <div className="is-sr-only">3</div>
                <div className="headquarter" aria-hidden />
                <div className="headquarter" aria-hidden />
                <div className="headquarter" aria-hidden />
              </dd>
              <dt>Finland</dt>
              <dd>
                <div className="is-sr-only">2</div>
                <div className="headquarter" aria-hidden />
                <div className="headquarter" aria-hidden />
              </dd>
              <dt>Denmark</dt>
              <dd>
                <div className="is-sr-only">2</div>
                <div className="headquarter" aria-hidden />
                <div className="headquarter" aria-hidden />
              </dd>
              <dt>Belgium</dt>
              <dd>
                <div className="is-sr-only">2</div>
                <div className="headquarter" aria-hidden />
                <div className="headquarter" aria-hidden />
              </dd>
              <dt>Austria</dt>
              <dd>
                <div className="is-sr-only">1</div>
                <div className="headquarter" aria-hidden />
              </dd>
            </dl>
          </div>
          <div className="column is-4 did-you-know-container">
            <p>
              <small>
                <b>DID YOU KNOW THAT IN {year}...</b>
              </small>
            </p>
            <CarouselContent handleClick={handleClick} />
          </div>
        </div>
      </div>
    </Tray>
  );
};

export default TrayCountryProfiles;
