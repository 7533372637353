import React from 'react';
import TrayCarousel from '../../../components/TrayCarousel';

const Carousel2015 = ({ handleClick }) => {
  return (
    <TrayCarousel slidesToShow={1}>
      <div className="did-you-know__trivia">
        An average bank employee in{' '}
        <button className="a" onClick={handleClick}>
          Malta
        </button>{' '}
        made €3m in profits for their company. This makes them almost 50 times
        more productive than an average German employee.
      </div>
      <div className="did-you-know__trivia">
        The countries where the most banks paid no in taxes in 2019 were the{' '}
        <button className="a" onClick={handleClick}>
          United Arab Emirates
        </button>
        ,{' '}
        <button className="a" onClick={handleClick}>
          Cayman Islands
        </button>{' '}
        and{' '}
        <button className="a" onClick={handleClick}>
          China<span className="is-sr-only">, P.R.: Mainland</span>
        </button>{' '}
        (6 banks each).
      </div>
      <div className="did-you-know__trivia">
        No corporate taxes were paid on nearly €4 billion of profits. 60% of
        these untaxed profits were made in the{' '}
        <button className="a" onClick={handleClick}>
          United Kingdom
        </button>
        .
      </div>
    </TrayCarousel>
  );
};

export default Carousel2015;
