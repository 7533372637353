import React from 'react';
import TrayCarousel from '../../../components/TrayCarousel';

const Carousel2015 = ({ handleClick }) => {
  return (
    <TrayCarousel slidesToShow={2}>
      <div className="did-you-know__trivia">
        <button className="a" onClick={e => handleClick(e, 'Productivity')}>
          UniCredit
        </button>{' '}
        had operations in 13 countries with no employees, yet declared profits
        in these locations.
      </div>
      <div className="did-you-know__trivia">
        <button className="a" onClick={e => handleClick(e, 'Productivity')}>
          Banco Santander
        </button>{' '}
        made €38m of profits in Ireland. They reported only 4 employees in the
        country.
      </div>
      <div className="did-you-know__trivia">
        The operations of{' '}
        <button className="a" onClick={e => handleClick(e, 'Profitability')}>
          HSBC
        </button>{' '}
        in China produced €2296m profits, which was more than the reported
        turnover of the operations, €2289m.
      </div>
      <div className="did-you-know__trivia">
        <button className="a" onClick={e => handleClick(e, 'Taxation')}>
          UniCredit
        </button>{' '}
        in Turkey and{' '}
        <button className="a" onClick={e => handleClick(e, 'Taxation')}>
          HSBC
        </button>{' '}
        in Saudi Arabia had operations generating over €250m profits while not
        reporting any employees, taxes or turnover in these countries.
      </div>
      <div className="did-you-know__trivia">
        <button className="a" onClick={e => handleClick(e, 'Taxation')}>
          ABN AMRO
        </button>{' '}
        reported paying no taxes in 4 different location where it was making
        profits.
      </div>
    </TrayCarousel>
  );
};

export default Carousel2015;
