import React from 'react';
import TrayCarousel from '../../../components/TrayCarousel';

const Carousel2015 = ({ handleClick }) => {
  return (
    <TrayCarousel slidesToShow={2}>
      <div className="did-you-know__trivia">
        Over the years, banks have reported profits in countries with no
        employees 145 times. 2018 saw this the most: 46 banks' operations in
        that year were in jurisdictions with no employees.
      </div>
      <div className="did-you-know__trivia">
        Banks made over €17 billion in profits in jurisdictions without
        employing any workers there.
      </div>
      <div className="did-you-know__trivia">
        Even though 99.6% of{' '}
        <button className="a" onClick={e => handleClick(e, 'Profitability')}>
          Monte dei Paschi
        </button>
        's turnover is generated in Italy, the country where it is
        headquartered, the bank reports negative profits of €496m while managing
        to earn almost an equal amount in profits in Luxembourg without having a
        single employee there.
      </div>
      <div className="did-you-know__trivia">
        <button className="a" onClick={e => handleClick(e, 'Profitability')}>
          UniCredit
        </button>{' '}
        reported larger profits than its turnover in 11 of its countries of
        operation.
      </div>
      <div className="did-you-know__trivia">
        13 banks reported positive profits in ghost jurisdictions. These are
        countries with no employees.
      </div>
      <div className="did-you-know__trivia">
        EU banks paid no taxes on over 2.8 billion of profits.
      </div>
    </TrayCarousel>
  );
};

export default Carousel2015;
