import capitalize from 'lodash/capitalize';
import noop from 'lodash/noop';
import orderBy from 'lodash/orderBy';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import forOwn from 'lodash/forOwn';
import filter from 'lodash/filter';
import debounce from 'lodash/debounce';
import upperFirst from 'lodash/upperFirst';

export default {
  capitalize,
  noop,
  orderBy,
  find,
  findIndex,
  forOwn,
  filter,
  debounce,
  upperFirst,
};
