import React from 'react';
import { ResponsiveSunburst } from '@nivo/sunburst';

import Info from '../../components/Info';
import Tooltip from '../../components/Tooltip';
import { formatNum } from '../../utils/helpers';
import { STIFFNESS, DUMPING, NIVO_THEMED } from '../../VARS';

const ListsSunburst = ({
  data,
  year,
  zero,
  chartAgainst,
  colorBy,
  onHover,
  onOut,
  onClick,
}) => {
  if (!data.label) return null;

  zero.sort((a, b) => b.value - a.value);

  return (
    <div
      className={`sunburst `}
      style={{
        height: window.innerWidth < 1280 ? '180px' : '220px',
      }}
    >
      <ResponsiveSunburst
        data={data}
        innerRadius={0.6}
        margin={{
          top: 30,
          right: 15,
          bottom: 30,
          left: 25,
        }}
        colors={colorBy}
        childColor="noinherit"
        sliceLabel="label"
        enableSlicesLabels={true}
        motionStiffness={STIFFNESS}
        motionDamping={DUMPING}
        theme={NIVO_THEMED}
        // onMouseEnter={this.props.onHover}
        // onMouseLeave={this.props.onOut}
        tooltip={datum => {
          const { label, value, zeroPercent } = datum;
          return (
            <Tooltip title={label}>
              <div className="columns">
                <div className="column">
                  <div className={`charttip__figure is-${chartAgainst}`}>
                    {formatNum(value)}
                    {chartAgainst !== 'Employees' &&
                    chartAgainst !== 'Operations'
                      ? ' M€'
                      : ''}
                  </div>
                  <div className="charttip__caption">
                    {chartAgainst}
                    <br />
                    <br />
                    <span>
                      Of which{' '}
                      <b>
                        {formatNum(zeroPercent)}
                        {chartAgainst !== 'Employees' &&
                        chartAgainst !== 'Operations'
                          ? ' M€'
                          : ''}
                      </b>{' '}
                      in countries
                      <br />
                      with 0% Corporate Tax Rate
                    </span>
                  </div>
                </div>
              </div>
            </Tooltip>
          );
        }}
        onMouseEnter={onHover}
        onMouseLeave={onOut}
        // onClick={onClick}
      />
      <Info
        position="right"
        text="You can narrow down the countries by clicking on specific country lists. The black and grey lists are based on the Common EU list of third country jurisdictions for tax purposes, which consists of non-cooperative tax jurisdictions. Countries which still have shortcomings, but have committed on improving their standards, are considered grey listed countries."
      />
      <div className="top20-legend">
        <div className="top20-legend-item is-zero">
          Countries with 0% Corporate Tax Rate and {chartAgainst} declared in
          the year {year}:
        </div>
      </div>
      <div className="zero-disclaimer">
        {zero.map((item, i) => (
          <span
            key={item.country}
            className="tooltip is-tooltip-right"
            data-tooltipp={`${formatNum(
              item.value
            )} M€ in profit declared here.`}
            data-tooltip={`${formatNum(item.value)} ${
              chartAgainst !== 'Employees' && chartAgainst !== 'Operations'
                ? ' M€ in'
                : ''
            } ${chartAgainst
              .toLowerCase()
              .replace('ons', 'on(s)')} declared here.`}
          >
            <b>{item.country}</b>
            {zero.length === i + 1 ? '.' : ','}
          </span>
        ))}
      </div>
    </div>
  );
};

export default ListsSunburst;
