import PropTypes from 'prop-types';
import React from 'react';
import ExtendedDatamaps from './extendedDatamaps';

const MAP_CLEARING_PROPS = ['height', 'scope', 'setProjection', 'width'];

const propChangeRequiresMapClear = (oldProps, newProps) => {
  return MAP_CLEARING_PROPS.some(key => oldProps[key] !== newProps[key]);
};

export default class Datamap extends React.Component {
  static propTypes = {
    arc: PropTypes.array,
    arcOptions: PropTypes.object,
    bubbleOptions: PropTypes.object,
    bubbles: PropTypes.array,
    data: PropTypes.object,
    graticule: PropTypes.bool,
    height: PropTypes.any,
    labels: PropTypes.bool,
    responsive: PropTypes.bool,
    style: PropTypes.object,
    updateChoroplethOptions: PropTypes.object,
    width: PropTypes.any,
  };

  constructor(props) {
    super(props);
    this.resizeMap = this.resizeMap.bind(this);
    this.containerRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.responsive) {
      window.addEventListener('resize', this.resizeMap);
    }
    this.drawMap();
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (propChangeRequiresMapClear(this.props, newProps)) {
      this.clear();
    }
  }

  componentDidUpdate() {
    this.drawMap();
  }

  componentWillUnmount() {
    this.clear();
    if (this.props.responsive) {
      window.removeEventListener('resize', this.resizeMap);
    }
  }

  clear() {
    const container = this.containerRef.current;

    for (const child of Array.from(container.childNodes)) {
      container.removeChild(child);
    }

    delete this.map;
  }

  drawMap() {
    const {
      arc,
      arcOptions,
      bubbles,
      bubbleOptions,
      data,
      graticule,
      labels,
      updateChoroplethOptions,
      onDone,
      ...props
    } = this.props;

    let map = this.map;

    if (!map) {
      map = this.map = new ExtendedDatamaps({
        ...props,
        data,
        element: this.containerRef.current,
      });
    } else {
      map.updateChoropleth(data, updateChoroplethOptions);
    }

    if (arc) {
      map.arc(arc, arcOptions);
    }

    if (bubbles) {
      map.bubbles(bubbles, bubbleOptions);
    }

    if (graticule) {
      map.graticule();
    }

    if (labels) {
      map.labels();
    }

    if (onDone) {
      onDone(map);
    }
  }

  resizeMap() {
    this.map.resize();
  }

  render() {
    const style = {
      height: '100%',
      position: 'relative',
      width: '100%',
      ...this.props.style,
    };

    return <div ref={this.containerRef} style={style} />;
  }
}
