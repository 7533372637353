import React from 'react';

import Tray from '../../../components/Tray';
import Carousel2015 from './Carousel2015';
import Carousel2016 from './Carousel2016';
import Carousel2017 from './Carousel2017';
import Carousel2018 from './Carousel2018';
import Carousel2019 from './Carousel2019';

import clickity from '../../../images/clickity.svg';

const carousels = {
  '2015': Carousel2015,
  '2016': Carousel2016,
  '2017': Carousel2017,
  '2018': Carousel2018,
  '2019': Carousel2019,
};

const TrayOverallRisk = ({ year, location, handleClick }) => {
  const CarouselContent = carousels[year];

  return (
    <Tray location={location}>
      <div className="container">
        <div className="columns">
          <div className="column is-5">
            <p>
              This page highlights profit shifting risk indicators such as high
              profits compared to turnover or high profits compared to number of
              employees in certain jurisdictions. It also gives an overview of
              how banks’ tax rates do not necessarily correlate with their
              turnover and profits, and illustrates how many banks declare high
              profits in countries with no employees.
            </p>
            <p className="clickity">
              <img src={clickity} alt="" />
              Click on the charts to explore data in-depth
            </p>
          </div>
          {/* <div className="column is-3 did-you-know-container">
            <p>
              <small>
                <b>
                  RATIO BETWEEN TURNOVER AND GDP
                  <br /> IN SELECTED EU COUNTRIES
                </b>
              </small>
            </p>
            <dl className="ratio-gdp">
              {ratioGDP.map(chart => (
                <Fragment key={chart.country}>
                  <dt>{chart.country}</dt>
                  <dd>
                    <div
                      className="ratio-gdp__bar tooltip is-tooltip-right"
                      style={{ width: `${chart.ratio * 100 * 5}%` }}
                      data-tooltip={chart.label}
                    >
                      <span className="is-sr-only">{chart.label}</span>
                    </div>
                  </dd>
                </Fragment>
              ))}
            </dl>
          </div> */}
          <div className="column is-6 is-offset-1 did-you-know-container">
            <p>
              <small>
                <b>DID YOU KNOW THAT IN {year}...</b>
              </small>
            </p>
            <CarouselContent handleClick={handleClick} />
          </div>
        </div>
      </div>
    </Tray>
  );
};

export default TrayOverallRisk;
