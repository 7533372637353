import React, { useRef } from 'react';
import Scrollbar from 'react-scrollbars-custom';

import Loading from '../../components/Loading';
import BarWithLabels from '../../components/BarWithLabels';
// import Info from '../../components/Info';
import _ from '../../utils/lodashImporter';
import {
  COLOR_PROFITABILITY,
  COLOR_PRODUCTIVITY,
  COLOR_TAXATION,
  COMPUTE,
} from '../../VARS';

// class AnomalousBanksBar extends Component {
const BanksAverageBar = ({
  height,
  data,
  chartAgainst,
  selectedBank,
  hoveredBank,
  onHover,
  onOut,
  onClick,
}) => {
  const mappedData = useRef({});

  if (!data)
    return (
      <div className="loading is-bar-chart">
        <Loading />
      </div>
    );

  const mapDataAgainst = () => {
    // Shorthands
    const dividend = COMPUTE[chartAgainst].dividend;
    const divisor = COMPUTE[chartAgainst].divisor;

    // Go through every year in the dataset, and every bank, and build the average
    let i = 1;
    let mapped = [];
    for (const year in data) {
      if (data.hasOwnProperty(year)) {
        const yearlyDataset = data[year];

        // If it's the first year, build the main bank array.
        // For the other years, build on top of the years before.
        // NOTE: We assume the exact same banks appear in EVERY YEAR
        if (i === 1) {
          mapped = yearlyDataset.byBank.map(bank => {
            const value =
              bank.Totals[dividend] === 0
                ? 0
                : bank.Totals[dividend] / bank.Totals[divisor];

            return {
              Name: bank.bank,
              [chartAgainst]: value,
              // [dividend]: bank.Totals[dividend],
              // [divisor]: bank.Totals[divisor],
            };
          });
        } else {
          yearlyDataset.byBank.forEach(
            ((mapped, i) => {
              return bank => {
                const value =
                  bank.Totals[dividend] === 0
                    ? 0
                    : bank.Totals[dividend] / bank.Totals[divisor];

                const savedBank = mapped.find(
                  mappedItem => mappedItem.Name === bank.bank
                );
                const savedValue = savedBank[chartAgainst] * (i - 1);
                // const savedDividend = savedBank[dividend] * (i - 1);
                // const savedDivisor = savedBank[divisor] * (i - 1);

                savedBank[chartAgainst] = (value + savedValue) / i;
                // savedBank[dividend] =
                //   (bank.Totals[dividend] + savedDividend) / i;
                // savedBank[divisor] = (bank.Totals[divisor] + savedDivisor) / i;
              };
            })(mapped, i)
          );
        }
        i++;
      }
    }

    return _.orderBy(
      mapped,
      chartAgainst,
      chartAgainst === 'Taxation' ? 'desc' : 'asc'
    );
  };

  if (!mappedData.current[chartAgainst])
    mappedData.current[chartAgainst] = mapDataAgainst();

  const colorBy = datum => {
    let color;
    let opacity = 1;

    switch (datum.id) {
      case 'Profitability':
        color = COLOR_PROFITABILITY;
        break;

      case 'Productivity':
        color = COLOR_PRODUCTIVITY;
        break;

      case 'Taxation':
        color = COLOR_TAXATION;
        break;

      default:
        color = 'transparent';
        break;
    }

    // hover, selected, what? opacity is an ugly beast...
    if (selectedBank !== '*') {
      if (datum.indexValue !== selectedBank) opacity -= 0.8;
      if (hoveredBank !== null && datum.indexValue === hoveredBank)
        opacity += 0.4;
    } else {
      if (hoveredBank !== null && datum.indexValue !== hoveredBank)
        opacity -= 0.4;
    }

    color = color.replace('1)', opacity + ')');

    return color;
  };

  return (
    <>
      <div className="chart-title">
        <div>
          Banks Average
          {/* {chartAgainst !== 'Taxation' ? (
              <Info
                text={
                  chartAgainst === 'Productivity'
                    ? 'Labour productivity means the amount of profits (before tax) a bank or a corporation make per employee in a certain jurisdiction. Comparing the productivity between different operations can reveal discrepancies in real economic activities.'
                    : chartAgainst === 'Profitability'
                    ? 'Profitability of the operations in a jurisdiction is measured by the amount of profits a bank or corporation make in comparison to their turnover. As the current country-by-country reports only provide information on pre-tax profits, this ratio is calculated by dividing profits made in a selected jurisdiction by the overall turnover in that jurisdiction. Extremely high profits compared to the turnover in a jurisdiction, and low ones in another, may indicate profit shifting or other corporate arrangements.'
                    : null
                }
              />
            ) : null} */}
        </div>
      </div>
      <Scrollbar
        className="chart-banks-average"
        maximalThumbSize={120}
        noScrollX={true}
        style={{ height: '575px' }}
      >
        <BarWithLabels
          className="chart-banks-average__chart"
          data={mappedData.current[chartAgainst]}
          keys={[chartAgainst, '']}
          indexBy="Name"
          colors={colorBy}
          axisBottom={true}
          axisTop={true}
          height={height}
          format={chartAgainst === 'Productivity' ? 'num' : 'perc'}
          onMouseEnter={onHover}
          onMouseLeave={onOut}
          onClick={onClick}
          isInteractive={true}
        />
      </Scrollbar>
    </>
  );
};

export default BanksAverageBar;
