import React, { useMemo } from 'react';
import { ResponsiveBar } from '@nivo/bar';

import Tooltip from '../../components/Tooltip';
import Info from '../../components/Info';
import { formatNum, formatPerc, formatLabel } from '../../utils/helpers';
import {
  COLOR_PROFIT,
  COLOR_EXPECTED_PROFIT,
  STIFFNESS,
  DUMPING,
  NIVO_THEMED,
  KEYS,
} from '../../VARS';

const THIS_KEYS = [...KEYS, 'ExpectedProfit'];

const ProductivityMisalignmentBar = ({ dataByBank, selectedBank }) => {
  function _mapData(dataByBank, selectedBank) {
    if (!dataByBank || selectedBank === '*') return [];

    const data = [];
    const bank = dataByBank.find(bank => bank.bank === selectedBank);

    bank.countries.forEach(country => {
      const Profit = country.datapoints.Profit;
      const ExpectedProfit =
        (country.datapoints.Employees / bank.Totals.Employees) *
        bank.Totals.Profit;
      data.push({
        country: country.country,
        Profit,
        ExpectedProfit,
      });
    });

    // Sort by higher delta first
    data.sort((a, b) => {
      return b.Profit - b.ExpectedProfit - (a.Profit - a.ExpectedProfit);
    });

    // Return first 25 items
    return data.slice(0, 25);
  }

  const data = useMemo(() => _mapData(dataByBank, selectedBank), [
    dataByBank,
    selectedBank,
  ]);

  if (selectedBank === '*') return null;

  return (
    <div className="extra-bar">
      <div className="extra-bar__title chart-title">
        <div>
          <span style={{ color: COLOR_PROFIT }}>Actual</span> vs{' '}
          <span
            style={{
              color: COLOR_EXPECTED_PROFIT,
            }}
          >
            Expected
          </span>{' '}
          Profit
          <br />
          Top 25 countries by difference
          <br />
          <Info
            position="left"
            text="This indicator compares the profits of a single operation to the profits that banks could expect if employees in the jurisdiction would be equally productive as on average. Positive (+) numbers indicate how much more the bank makes in the jurisdiction than it would on average, whereas negative (-) numbers indicate the opposite."
          />
        </div>
      </div>
      <div style={{ height: '250px' }}>
        <ResponsiveBar
          data={data}
          keys={['Profit', 'ExpectedProfit']}
          indexBy="country"
          colors={[COLOR_PROFIT, COLOR_EXPECTED_PROFIT]}
          padding={0.35}
          margin={{
            top: 0,
            right: 0,
            bottom: 130,
            left: 65,
          }}
          axisLeft={null}
          axisBottom={{
            orient: 'bottom',
            tickSize: 0,
            tickPadding: 15,
            tickRotation: -45,
            tickValueFormat: formatLabel,
            legend: '',
            legendPosition: 'middle',
            legendOffset: 36,
          }}
          enableGridY={false}
          enableLabel={false}
          motionStiffness={STIFFNESS}
          motionDamping={DUMPING}
          theme={NIVO_THEMED}
          groupMode="grouped"
          tooltip={({ data }) => (
            <Tooltip title={data.country}>
              <div className="columns">
                {THIS_KEYS.map((key, i) => {
                  if (!data[key]) return null;

                  const formatFn =
                    ['Profitability'].indexOf(key) >= 0
                      ? formatPerc
                      : formatNum;
                  const suffix =
                    ['Turnover', 'Profit', 'Tax', 'ExpectedProfit'].indexOf(
                      key
                    ) >= 0
                      ? ' M€'
                      : '';

                  return (
                    <div className="column" key={i}>
                      <div className={`charttip__figure is-${key}`}>
                        {formatFn(data[key])} {suffix}
                      </div>
                      <div className="charttip__caption">
                        {key === 'Productivity'
                          ? key + ' (M€/FTE)'
                          : key === 'ExpectedProfit'
                          ? 'Expected profit'
                          : key}
                      </div>
                    </div>
                  );
                })}
              </div>
              {data.country.indexOf('Channel') === 0 ? (
                <div className="charttip__caption">
                  <span>
                    <i>
                      The bank aggregates multiple
                      <br /> jurisdictions in its report
                    </i>
                  </span>
                </div>
              ) : null}
            </Tooltip>
          )}
        />
      </div>
    </div>
  );
};

export default ProductivityMisalignmentBar;
