import React from 'react';
import TrayCarousel from '../../../components/TrayCarousel';

const Carousel2015 = ({ handleClick }) => {
  return (
    <TrayCarousel>
      <div className="did-you-know__trivia">
        <button className="a" onClick={handleClick}>
          Erste Group Bank
        </button>
        's profitability in Austria was only half (18%) compared to its average
        profits per euro earned (36%).
      </div>
      <div className="did-you-know__trivia">
        For each euro earned in the UK, the country of its headquarter,{' '}
        <button className="a" onClick={handleClick}>
          HSBC
        </button>{' '}
        only made a profit of 3 cents. This is 10 times less than the bank's
        average.
      </div>
      <div className="did-you-know__trivia">
        <button className="a" onClick={handleClick}>
          UniCredit
        </button>{' '}
        reported €343m profits in jurisdictions without any employees. This was
        10% of the bank's overall profits in the year.
      </div>
      <div className="did-you-know__trivia">
        <button className="a" onClick={handleClick}>
          HSBC
        </button>{' '}
        managed to create €356.83m in profits in Saudi Arabia without having any
        employees or turnover there.
      </div>
      <div className="did-you-know__trivia">
        <button className="a" onClick={handleClick}>
          HSBC
        </button>{' '}
        did not pay any tax on over half a billion euros of its profits.
      </div>
      <div className="did-you-know__trivia">
        Both{' '}
        <button className="a" onClick={handleClick}>
          Standard Chartered
        </button>{' '}
        and Groupe{' '}
        <button className="a" onClick={handleClick}>
          BPCE
        </button>{' '}
        reported not having paid any tax in 13 different countries of their
        operations. This meant Standard Chartered kept didn't pay any tax on
        €344m (11.5% of its overall profits).
      </div>
    </TrayCarousel>
  );
};

export default Carousel2015;
