import React, { Fragment } from 'react';
/* EXAMPLE:
  <Select name="aName" values={Array} value={Array[n]} onChange={Function} />
*/

import _ from '../utils/lodashImporter';

const Label = ({ label }) => <label className="label">{label}</label>;

const Field = ({
  name,
  value,
  values,
  defaultName,
  defaultValue,
  onChange,
  label,
  labelInline,
}) => (
  <div className="field">
    {label && !labelInline && <Label label={label} />}
    <div className="control">
      {values.length > 0 ? (
        <div className="select">
          <select
            name={name}
            value={value}
            onChange={onChange}
            className={defaultValue !== value ? 'is-selected' : ''}
          >
            <option value={defaultValue}>{defaultName}</option>
            <option value="x-dont-select-me" disabled>
              ──────────
            </option>
            {values.map(value => (
              <option key={value} value={value}>
                {_.upperFirst(value).replace(
                  /^(Black|Grey)/,
                  '$1-listed countries'
                )}
              </option>
            ))}
          </select>
        </div>
      ) : null}
      {value !== '*' && (
        <button
          className="delete"
          name={name}
          value={defaultValue}
          onClick={onChange}
        >
          Clear selection
        </button>
      )}
    </div>
  </div>
);

const Select = props => (
  <Fragment>
    {props.labelInline ? (
      <div className="field is-horizontal">
        <div className="field-label">
          <Label label={props.label} />
        </div>
        <div className="field-body">
          <Field {...props} />
        </div>
      </div>
    ) : (
      <Field {...props} />
    )}
  </Fragment>
);

export default Select;
