import React, { Component } from 'react';

import Loading from '../../components/Loading';
import BarWithLabels from '../../components/BarWithLabels';
import Info from '../../components/Info';
import _ from '../../utils/lodashImporter';
import d3 from '../../utils/d3Importer';
/* import mostN from '../../utils/mostN';
import sample from '../../utils/sample';  */
import { formatNum, formatPerc } from '../../utils/helpers';
import {
  COLOR_BLACK,
  COLOR_GRAY,
  COLOR_PROFITABILITY,
  COLOR_PRODUCTIVITY,
  COLOR_TAXATION,
  COMPUTE,
} from '../../VARS';

class RiskCountriesBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
    };

    this.year = null;
    this.mappedData = {};
    this.countriesMeta = null;

    this.handlePageUp = this.handlePageUp.bind(this);
    this.handlePageDown = this.handlePageDown.bind(this);
  }

  handlePageUp() {
    this.setState({ page: this.state.page + 1 });
  }

  handlePageDown() {
    this.setState({ page: this.state.page - 1 });
  }

  componentDidUpdate(prevProps) {
    // Force page start
    if (this.props.chartAgainst !== prevProps.chartAgainst) {
      this.setState({ page: 1 });
    }
  }

  _mapData(data, chartAgainst, year) {
    // Shorthands
    const dividend = COMPUTE[chartAgainst].dividend;
    const divisor = COMPUTE[chartAgainst].divisor;
    let defaultColor = '#fff';
    let title = '';

    switch (chartAgainst) {
      case 'Productivity':
        title = 'Countries Where Banks Have Highest Average Productivity';
        defaultColor = COLOR_PRODUCTIVITY;
        break;

      case 'Profitability':
        title = 'Countries Where Banks Have Highest Average Profitability';
        defaultColor = COLOR_PROFITABILITY;
        break;

      case 'Taxation':
        title = 'Discrepancy Between Nominal and Average Effective Tax Rates';
        defaultColor = COLOR_TAXATION;
        break;

      default:
        break;
    }

    // Build the array holding the summary for each bank
    let mapped = data.reduce((memo, country) => {
      const countryMeta = _.find(this.props.countryMeta, {
        name: country.country,
      });

      // Fail safe in case of
      if (!countryMeta) {
        console.warn(
          `[TaxTracker] Could not find country "${country.country}" in countryMeta`,
          country
        );
        return memo;
      }

      const list = countryMeta.list;
      let fill = defaultColor;

      switch (list) {
        case 'grey':
          fill = COLOR_GRAY;
          break;

        case 'black':
          fill = COLOR_BLACK;
          break;

        default:
          break;
      }

      let value =
        country.Totals[dividend] === 0
          ? 0
          : country.Totals[dividend] / country.Totals[divisor];

      // But for Tax, we get the discrepancy between effective and nominal rate!
      if (chartAgainst === 'Taxation') {
        value = value - parseFloat(countryMeta[`nominalTR${year}`], 10);
      }

      // Ignore +/-Infinity values :/
      if (!isNaN(value) && country.Totals[divisor] !== 0) {
        memo.push({
          type: 'country',
          fill: fill,
          Name: country.country,
          [chartAgainst]: value,
        });
      }

      // this return value will be passed in as the 'memo' argument
      // to the next call of this function, and this function will have
      // every element passed into it at some point.
      return memo;
    }, []);

    // Calculate the standard deviation for the 'whole' banks
    const deviation = d3.deviation(mapped, country => country[chartAgainst]);
    const mean = d3.mean(mapped, country => country[chartAgainst]);
    const extent = d3.extent(mapped, country => country[chartAgainst]);

    // Finally, sort, take most25, and attach mean/deviation values
    // mapped = _.orderBy(mapped, chartAgainst, 'asc').slice(-20);

    // Finally, sort, and attach mean/deviation values
    mapped = _.orderBy(
      mapped,
      chartAgainst,
      chartAgainst === 'Taxation' ? 'asc' : 'desc'
    );
    mapped.mean = mean;
    mapped.deviation = deviation;
    mapped.title = title;
    mapped.extent = extent;

    return mapped;
  }

  render() {
    const { year, data, chartAgainst } = this.props;

    if (!data)
      return (
        <div className="loading is-bar-chart">
          <Loading />
        </div>
      );

    // memoize data on first render, and on year change
    if (!this.mappedData[chartAgainst] || this.year !== year) {
      this.mappedData[chartAgainst] = this._mapData(data, chartAgainst, year);
      this.year = year;
    }

    const mean = this.mappedData[chartAgainst].mean;
    const title = this.mappedData[chartAgainst].title;
    const extent = this.mappedData[chartAgainst].extent;
    const pages = Math.ceil(this.mappedData[chartAgainst].length / 20);
    const dataSlice = this.mappedData[chartAgainst].slice(
      (this.state.page - 1) * 20,
      this.state.page * 20
    );
    dataSlice.reverse();

    if (dataSlice.length < 20) {
      for (let i = dataSlice.length; i < 20; i++) {
        dataSlice.splice(0, 0, {
          Name: 'filler' + i,
          [chartAgainst]: 0,
          fill: 'transparent',
        });
      }
    }

    return (
      <div className={`anomalous-countries-bar is-${chartAgainst}`}>
        <div className="anomalous-countries-bar__title chart-title">
          <div>
            {title}{' '}
            {chartAgainst === 'Taxation' ? (
              <Info
                position="left"
                text="Average effective tax rates among all banks operating in a jurisdiction"
              />
            ) : null}
          </div>
        </div>
        <BarWithLabels
          className="chart-anomalous-banks"
          data={dataSlice}
          isDiscrepancy={chartAgainst === 'Taxation' ? true : false}
          keys={[chartAgainst, '']}
          colors={d => d.data.fill}
          indexBy="Name"
          minValuee={extent[0]}
          maxValuee={extent[1]}
          markers={[
            {
              axis: 'x',
              value: mean,
              lineStyle: {
                stroke: 'rgba(0, 0, 0, 0.3)',
                strokeWidth: 1,
                strokeDasharray: 5,
              },
              legend: `Mean value: ${
                chartAgainst === 'Productivity'
                  ? formatNum(mean)
                  : formatPerc(mean)
              }`,
              textStyle: {
                fontSize: '12px',
                fontStyle: 'italic',
                fill: 'rgba(0, 0, 0, 0.5)',
              },
              legendOffsetY: -4,
              legendOffsetX: -4,
              legendPosition:
                chartAgainst === 'Taxation' ? 'top-left' : 'top-right',
            },
            {
              axis: 'x',
              value: 0,
              lineStyle: {
                stroke: 'rgba(0, 0, 0, 0.2)',
                strokeWidth: 1,
              },
            },
          ]}
          height={this.props.height}
          format={chartAgainst === 'Productivity' ? 'num' : 'perc'}
        />
        <div className="top20-legend">
          <div className="top20-legend-item is-black">
            Jurisdiction currently listed on the EU’s so called ‘black list’ of
            non-cooperative jurisdictions for tax purposes
          </div>
          <div className="top20-legend-item is-grey">
            Jurisdiction currently listed on the EU’s so called ‘grey list’ of
            non-cooperative jurisdictions for tax purposes
          </div>
          <div className={`top20-legend-item is-${chartAgainst}`}>
            Other countries
          </div>
        </div>
        <div className="chart-paginate">
          <button
            className="button is-small is-paginate"
            onClick={this.handlePageDown}
            disabled={this.state.page <= 1}
          >
            ▲
          </button>
          <button
            className="button is-small is-paginate"
            onClick={this.handlePageUp}
            disabled={this.state.page >= pages}
          >
            ▼
          </button>
        </div>
      </div>
    );
  }
}

export default RiskCountriesBar;
