import React from 'react';

import Tray from '../../../components/Tray';
import Carousel2015 from './Carousel2015';
import Carousel2016 from './Carousel2016';
import Carousel2017 from './Carousel2017';
import Carousel2018 from './Carousel2018';
import Carousel2019 from './Carousel2019';

import clickity from '../../../images/clickity.svg';

const carousels = {
  '2015': Carousel2015,
  '2016': Carousel2016,
  '2017': Carousel2017,
  '2018': Carousel2018,
  '2019': Carousel2019,
};

const TrayBankProfiles = ({ year, location, handleClick }) => {
  const CarouselContent = carousels[year];

  return (
    <Tray location={location}>
      <div className="container">
        <div className="columns">
          <div className="column is-5">
            <p>
              This page provides basic financial information on the largest
              European banks, including their turnover, profits, taxes paid and
              number of employees in different jurisdictions. You can examine
              their tax related information by selecting individual banks.
            </p>
            <p className="clickity">
              <img src={clickity} alt="" />
              Click on the charts to explore data in-depth
            </p>
          </div>
          <div className="column is-6 is-offset-1 did-you-know-container">
            <p>
              <small>
                <b>DID YOU KNOW THAT IN {year}...</b>
              </small>
            </p>
            <CarouselContent handleClick={handleClick} />
          </div>
        </div>
      </div>
    </Tray>
  );
};

export default TrayBankProfiles;
