/* global Pace */

import React, { Component } from 'react';
import { BrowserRouter as Router, Route, useLocation } from 'react-router-dom';
import './App.sass';

import { loadAndProcessData } from './DataHandling';
import Header from './components/Header';
import PageHome from './pages/PageHome';
import PageAbout from './pages/PageAbout';
import PageBankProfiles from './pages/PageBankProfiles';
import PageCountryProfiles from './pages/PageCountryProfiles';
import PageOverallRisk from './pages/PageOverallRisk';
import PageAnnualComparison from './pages/PageAnnualComparison';

const NullRoute = ({ location, onRouteUpdate }) => {
  onRouteUpdate(location);
  return null;
};

// This functional component wrapper make sure vNodes are re-created when needed
const AllRoutes = props => {
  let location = useLocation();
  location = location.pathname.replace(/^\/+|\/+$/g, '');
  if (location === '') location = 'home';

  return (
    <div className={`App page-${location}`}>
      <Route
        render={({ location }) => (
          <NullRoute
            location={location}
            onRouteUpdate={props.handleRouteUpdate}
          />
        )}
      />

      <Route
        render={({ location }) => (
          <Header path={location.pathname} loadYear={props.loadYear} />
        )}
      />

      <Route exact path="/" component={PageHome} />
      <Route exact path="/about" component={PageAbout} />
      <Route
        path="/bank-profiles"
        render={routeProps => <PageBankProfiles {...routeProps} {...props} />}
      />
      <Route
        path="/country-profiles"
        render={routeProps => (
          <PageCountryProfiles {...routeProps} {...props} />
        )}
      />
      <Route
        path="/overall-risk"
        render={routeProps => <PageOverallRisk {...routeProps} {...props} />}
      />
      <Route
        path="/annual-comparison"
        render={routeProps => (
          <PageAnnualComparison {...routeProps} {...props} />
        )}
      />
    </div>
  );
};

export default class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      year: 2015,
      data: null,
      // byBank: null,
      // byCountry: null,
      // countryMeta: null,
    };

    this.processedData = null;
    this.focusWrapper = React.createRef();
    this.loadYear = this.loadYear.bind(this);
    this.handleRouteUpdate = this.handleRouteUpdate.bind(this);
  }

  componentDidMount() {
    if (Pace) Pace.stop();
    loadAndProcessData(data => {
      console.info('data', data);
      this.setState({ data });
    });
    // Weirdly, it sometimes stays running
    if (Pace) Pace.stop();
  }

  loadYear(year) {
    if (this.state.data[year]) {
      this.setState({ year });
    }
  }

  handleRouteUpdate(location) {
    this.focusWrapper.current && this.focusWrapper.current.focus();
    if (location.hash.indexOf('#') === -1) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return (
      <div className="fix-focus" ref={this.focusWrapper} tabIndex="-1">
        <Router>
          <AllRoutes
            data={this.state.data}
            year={this.state.year}
            handleRouteUpdate={this.handleRouteUpdate}
            loadYear={this.loadYear}
          />
        </Router>
      </div>
    );
  }
}
