import React from 'react';

const Tooltip = ({ title, message, children }) => (
  <div className="charttip">
    {title ? <div className="charttip__title">{title}</div> : null}
    <div className="charttip__body">{message ? message : children}</div>
  </div>
);

export default Tooltip;
