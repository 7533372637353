// import { select } from 'd3-selection';
import { json } from 'd3-fetch';
import { mean, deviation, extent, min, max } from 'd3-array';
import { format } from 'd3-format';
import { scaleLinear } from 'd3-scale';
import { geoPath } from 'd3-geo';
import { geoLarrivee } from 'd3-geo-projection';

export default {
  // select,
  json,
  mean,
  deviation,
  extent,
  min,
  max,
  format,
  scaleLinear,
  geoPath,
  geoLarrivee,
};
