import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';

import { BASE_TITLE, GA_TRACKING_ID } from '../VARS';
import { GAPageView } from '../utils/helpers';
import CurrencyTable from './about/CurrencyTable';
import BankListTable from './about/BankListTable';

import luminateLogo from '../images/luminate_logo.png';
import FTCLogo from '../images/FTC_logo.jpg';
import DMFLogo from '../images/DMF_logo.jpg';

class About extends Component {
  constructor(props) {
    super(props);
    this.shouldTrackPageView = false;
  }

  componentDidMount() {
    this.shouldTrackPageView = true;
  }

  render() {
    return (
      <Fragment>
        <Helmet
          onChangeClientState={(newState, addedTags, removedTags) => {
            if (this.shouldTrackPageView) {
              this.shouldTrackPageView = false;
              GAPageView(
                this.props.location,
                this.props.history,
                GA_TRACKING_ID
              );
            }
          }}
        >
          <title>About | {BASE_TITLE}</title>
        </Helmet>

        <aside className="menu">
          <ul className="menu-list">
            <li>
              <a href="#about" className="is-activ">
                About
              </a>
            </li>
            <li>
              <a href="#background">Background</a>
            </li>
            <li>
              <a href="#data-sources">Data & Sources</a>
            </li>
            <li>
              <a href="#definitions-disclaimers">Definitions & Disclaimers</a>
            </li>
            <li>
              <a href="#acknowledgements">Acknowledgements</a>
            </li>
            <li>
              <a href="#contact-information">Contact Information</a>
            </li>
          </ul>
        </aside>

        <section className="hero is-about">
          <div id="about" className="hero-body">
            <div className="container content">
              <h1 className="title">About</h1>
              <p>
                <i>
                  Since 2015, European banks have been required to publish their
                  key financial data. However this information is difficult to
                  find and often very complex.
                </i>
              </p>
              <p>
                <i>
                  Transparency International EU’s Corporate Tax Tracker is a
                  tool that allows the public to examine the data reported by 39
                  of the largest banks in Europe. Anyone can use it to compare
                  and analyse banks’ activities and payments in different
                  countries of operation. This includes information on turnover,
                  profits, taxes paid and number of full-time employees. The
                  public has a right to know which financial institutions are
                  paying taxes, how much they are paying, and in which
                  countries.
                </i>
              </p>
              <hr
                style={{
                  backgroundColor: '#4d4d4d',
                  height: '1px',
                  width: '30px',
                }}
              />
              <p>
                The Corporate Tax Tracker platform collates and visualises the
                key financial data of 39 of the largest banks in Europe on a
                country-by-country basis. It draws on data published by the
                banks in 2015, 2016 and 2017, including information on
                activities, location and tax payments.
              </p>
              <p>
                This data is published by European banks as a requirement under
                the{' '}
                <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=celex%3A32013L0036">
                  EU’s fourth Capital Requirement Directive
                </a>{' '}
                since 2015.
              </p>
              <p>
                The development of the platform is funded by Luminate, the
                Financial Transparency Coalition and the Democracy and Media
                Foundation.
              </p>
              <p>
                <img src={luminateLogo} style={{ height: '40px' }} alt="" />
                <img src={FTCLogo} alt="" />
                <img src={DMFLogo} alt="" />
              </p>
            </div>
          </div>
        </section>

        <section className="hero is-about">
          <div id="background" className="hero-body">
            <div className="container content">
              <h1 className="title">Background</h1>
              <p>
                In the EU, the banking sector is required to publicly report on
                its key financial data on a country-by-country basis.
                Country-by-country reporting (CbCR) is a tax transparency tool
                which aims at providing the public with a global view on the
                activities, structures and taxes multinational enterprises pay
                on their corporate income.
              </p>
              <p>
                All banks headquartered in the European Union have been obliged
                to publicly report on their operations on a country-by-country
                basis since 2015. This is part of a legislative requirement set
                out in the Capital Requirement Directive (CRD) IV. The ultimate
                objective of having this data in the public domain is to check
                whether companies’ tax payments are aligned with their real
                economic activities. The exact disclosure requirements included
                in{' '}
                <a href="https://eur-lex.europa.eu/LexUriServ/LexUriServ.do?uri=OJ:L:2013:176:0338:0436:En:PDF">
                  the Directive
                </a>{' '}
                are the following:
              </p>
              <blockquote>
                <ol>
                  <li>
                    name(s), nature of activities and geographical location;
                  </li>
                  <li>turnover;</li>
                  <li>number of employees on a full time equivalent basis;</li>
                  <li>profit or loss before tax;</li>
                  <li>tax on profit or loss;</li>
                  <li>public subsidies received.</li>
                </ol>
              </blockquote>
              <p>
                The Tax Tracker collates and displays the data contained in
                these reports. It also helps to highlight how banks and
                companies use certain countries and the tax rules of those
                countries to maximise their profits and reduce their bills.
              </p>
              <p>
                The reporting obligations in the EU must be extended to cover
                all large multinational enterprises. A{' '}
                <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/PDF/?uri=CELEX:52016PC0198&from=EN">
                  proposal
                </a>{' '}
                to make public country-by-country reporting cover all big
                enterprises is currently going through the EU’s legislative
                process and was{' '}
                <a href="http://www.europarl.europa.eu/news/en/press-room/20170629IPR78639/multinationals-should-disclose-tax-information-in-each-country-they-operate">
                  supported by the European Parliament in July 2017
                </a>
                .
              </p>
              <p>
                TI EU is advocating for similar legislation to be extended to
                large multinational companies of all sectors. Currently, a
                legislative process on this matter is ongoing at EU level. A{' '}
                <a href="http://transparency.eu/transparency-international-eu-outraged-at-failure-to-break-council-deadlock-on-tax-transparency/">
                  lack of consensus
                </a>{' '}
                between EU Member States has delayed the adoption of a final
                negotiating position that would allow the commencement of
                trilogue negotiations with the European Parliament and the
                Commission.
              </p>
            </div>
          </div>
        </section>

        <section className="hero is-about">
          <div id="data-sources" className="hero-body">
            <div className="container content">
              <h1 className="title">Data & Sources</h1>
              <p>
                The first version of the Corporate Tax Tracker, launched in
                October 2018, visualised data coming from the first
                country-by-country reports of the 20 largest European banks,
                which were published in 2015. It was originally collected by
                Oxfam for its report{' '}
                <a href="https://www.oxfam.org/en/research/opening-vaults">
                  “
                  <i>
                    Opening the Vaults: The use of tax havens by Europe’s
                    biggest banks
                  </i>
                  ”
                </a>
                .
              </p>
              <p>
                For more information on the initial methodology and data
                collection carried out for the first version of the Corporate
                Tax Tracker, please refer to{' '}
                <a href="https://d1tn3vj7xz9fdh.cloudfront.net/s3fs-public/bp-opening-vaults-banks-tax-havens-270317-annexes-en.pdf">
                  Appendix 1
                </a>{' '}
                of the Oxfam report. It provides the definitions, methodology
                used for data collection and calculation as well as data
                inconsistencies. For instance, as the currency of the reports
                varies slightly, the data has been converted to euros for easier
                comparability. Oxfam used the average exchange rate of 2015 for
                the conversion.
              </p>
              <p>
                The second version of the platform extends the dataset from 20
                to 39 banks and includes four additional reporting years (2016,
                2017, 2018 and 2019). For the current update and additional data
                included in the website, we have used the conversion rates in
                the table below. These conversion rates were used by the Charles
                University in Prague for the CbCR data collection carried out
                for Professor Petr Jansky’s study{' '}
                <a href="https://www.researchgate.net/publication/342363417_European_banks_and_tax_havens_evidence_from_country-by-country_reporting">
                  European Banks and Tax Havens: Evidence from
                  Country-by-Country Reporting
                </a>
                .
              </p>
              <CurrencyTable />
              <p>
                The list of the 50 largest banks in Europe by total assets was
                published in May 2018 by the{' '}
                <a href="https://www.businessinsider.com/the-50-largest-banks-in-europe-2018-5?r=US&IR=T">
                  {' '}
                  Business Insider
                </a>{' '}
                based on research carried out by S&P Global Market Intelligence.
                However, TI EU’s Corporate Tax Tracker does not include all of
                the 50 largest banks in European due to different methodological
                reasons, which we explain below:
              </p>
              <dl>
                <dt>
                  Credit Suisse Group AG, DNB ASA, JSC VTB Bank, PAO Sberbank of
                  Russia, Raiffeisen Gruppe Switzerland, UBS Group AG, Zürcher
                  Kantonalbank
                </dt>
                <dd>
                  These banks are headquartered outside of the EU (Norway,
                  Russia and Switzerland). They have significant operations
                  through their subsidiaries in the EU and according to the CRD
                  IV, are required to develop and publish country by country
                  reports for those subsidiaries. They are not required to do
                  this for their operations in their headquarter countries or in
                  third countries, which means that the information available
                  for these banks is not comprehensive enough for a comparison
                  with EU banks. We have therefore decided to exclude this group
                  of banks from our dataset. The Norwegian bank DNB ASA is
                  currently the only bank among this group that voluntarily goes
                  beyond the legislation’s requirements and reports its
                  financial data on a country by country basis for its
                  operations in Norway and in third countries. The bank began
                  this practice in 2017, so could not be included in this
                  version of the Tax Tracker.
                </dd>
                <dt>
                  Caixa Bank, Cassa depositi e prestiti SpA, La Banque Postale
                </dt>
                <dd>
                  These banks headquartered in Spain, Italy and France do not
                  fall under the scope of the CRD IV for different reasons and
                  are, therefore, exempt from the CBCR requirements outlined in
                  the Directive. Data for these banks is not available.
                </dd>
                <dt>Banco BPM SpA and Dexia</dt>
                <dd>
                  These banks fall under the scope of the legislation and
                  publicly report country by country data. However, we
                  encountered challenges during our data collection and
                  analysis, as it appears that one or more of the reports
                  published by these banks lack some of the required data. We
                  contacted the banks between July and September 2019 seeking
                  clarification on these issues, but have not received adequate
                  responses.
                </dd>
              </dl>
              <p>
                The German bank KfW is a separate case. It was already included
                in the first version of our Corporate Tax Tracker, as it was
                also part of the dataset of the 20 largest European banks used
                by Oxfam for its report “Opening the Vaults”. It no longer
                features among the 50 largest European banks according to the
                2018 research by S&P Global Market Intelligence, which we based
                our methodology on. However, for continuity purposes we decided
                to keep this bank in the dataset for the years 2016-2019.
              </p>
              <p>The 39 banks included in the Corporate Tax Tracker are:</p>
              <BankListTable />
              <h6>Exceptions to the dataset</h6>
              <p>
                A few exceptions to the list above include the CBCR data of OP
                Financial Group in 2018, and 2019 data from HSBC Holdings. OP
                Financial Group’s data on its operations in Finland could not be
                found in the Group’s reporting documents for the accounting
                years 2018 and 2019. From our correspondence with the bank it
                emerged that this is due to the way Finland has transposed the
                CRD IV into national legislation, which does not require Finnish
                banks to publish this financial information in relation to their
                domestic operations and only disclose it for their foreign
                operations. The Group consists of approximately 140 OP
                cooperative banks. However, all OP cooperative banks pay their
                corporate tax locally in their operating region in Finland. Only
                one bank included in the group - OP Corporate Bank plc - has
                branch offices outside Finland and has published
                country-by-country reporting data on them. Although OP Financial
                Group did provide the Finnish data when we contacted them, we
                have not included it in the research as it could not truly be
                considered publicly available. HSBC Holdings did not have its
                CBCR report for 2019 published at the time of the second update
                of Corporate Tax Tracker, which was finalised in September 2020.
                As the Directive allows banks to publish their CBCR data for the
                accounting year 2019 up until 31 December 2020, the 2019
                operations of this bank could not be included in the platform.
              </p>
              <p>
                This does not cover the whole banking sector, although the banks
                selected do represent a significant part of it. Currently, the
                data is scattered throughout the banks’ annual reports or other
                report formats and corporate websites, and is not collected in
                one single place. It is usually reported in pdf instead of
                machine-readable format, which makes it challenging to compare
                it with other data. This website provides an easily accessible
                tool to visualise the data and compare the different banks.
              </p>
              <p>
                The Corporate Tax Tracker also refers to banks’ nominal tax
                rates. This data is based on{' '}
                <a href="https://home.kpmg/xx/en/home/services/tax/tax-tools-and-resources/tax-rates-online/corporate-tax-rates-table.html">
                  KPMG’s collection
                </a>{' '}
                of nominal corporate tax rates of the respective years.
              </p>
              <p>
                The aggregate figures for banks‘ overall economic activity
                account for intra-group consolidation, if applicable. Few
                exceptions to this are Banco Santander (2015), Royal Bank of
                Scotland (2015, 2016) and Standard Chartered (2016), as their
                consolidated CBCR data were not available at the time of the
                newest Corporate Tax Tracker update. Instead of the consolidated
                values, the platform visualises their non-consolidated aggregate
                data for Turnover, Profits and Taxes.
              </p>
              <p>
                The Corporate Tax Tracker also highlights countries that have
                not met the EU’s criteria related to tax transparency, fair
                taxation, and the implementation of OECD BEPS (Base Erosion and
                Profit Shifting) measures. These countries have been included in
                the EU's{' '}
                <a href="http://www.consilium.europa.eu/en/policies/eu-list-of-non-cooperative-jurisdictions/">
                  list of non-cooperative jurisdictions for tax purposes
                </a>
                , often dubbed as the EU list of tax havens, which is subject to
                changes. The list, adopted in December 2017, includes non-EU
                countries or territories that failed to make sufficient
                commitments in response to EU concerns on good tax governance.
                As this list has changed since its adoption, we will continue
                monitoring and updating the jurisdictions. The EU list contains
                information of both non-cooperative jurisdictions - the
                so-called black list - and countries that have made specific
                commitments to implement tax good governance principles - the
                so-called grey list.
              </p>
              <p>
                However, we believe that this list is the result of a flawed and
                opaque political exercise and, by design, does not include EU
                Member States in its scope, despite extensive evidence showing
                how some of them play an important role in corporate tax
                avoidance.
              </p>
              <p>
                <a href="https://docs.google.com/spreadsheets/d/16ytEfKt71gL5B8ctFTvvpMMoAJyI_pbB/edit#gid=158856139">
                  [CbCR data of 39 largest European banks]
                </a>
              </p>
            </div>
          </div>
        </section>

        <section className="hero is-about">
          <div id="definitions-disclaimers" className="hero-body">
            <div className="container content">
              <h1 className="title">Definitions & Disclaimers</h1>
              <p>
                The Corporate Tax Tracker platform looks into specific risk
                factors that may indicate discrepancies in corporate tax
                behaviour. In particular, the platform looks into{' '}
                <strong>productivity</strong>, <strong>profitability</strong>,{' '}
                <strong>effective tax rates</strong> and{' '}
                <strong>productivity imbalance</strong>.
              </p>
              <dl>
                <dt>Productivity</dt>
                <dd>
                  Labour productivity means the amount of profits (before tax) a
                  bank or a corporation make per employee in a certain
                  jurisdiction. Comparing the productivity between different
                  operations can reveal discrepancies in real economic
                  activities.
                </dd>
                <dt>Profitability</dt>
                <dd>
                  Profitability of the operations in a jurisdiction is measured
                  by the amount of profits a bank or corporation make in
                  comparison to their turnover. As the current CbC reports only
                  provide information on pre-tax profits, this ratio is
                  calculated by dividing profits made in a selected jurisdiction
                  by the overall turnover in that jurisdiction. Extremely high
                  profits compared to the turnover in a jurisdiction, and low
                  ones in another, may indicate profit shifting or other
                  corporate arrangements.
                </dd>
                <dt>Discrepancies between nominal and effective tax rate</dt>
                <dd>
                  The effective tax rate indicates the amount of taxes a bank or
                  corporation pays on its profits. As the reports provide
                  information on pre-tax profits and taxes paid, the effective
                  tax rate is calculated thanks to this data. It can be compared
                  to the nominal tax rate of each jurisdiction of operation. NB:
                  The discrepancies in data do not necessarily indicate tax
                  avoidance or profit shifting. Corporation tax payable in a
                  given year doesn’t necessarily relate to the profits earned in
                  the same year, as tax on profits is usually paid across
                  multiple years, meaning that it is possible that relatively
                  high corporation tax can be paid when profits are low and
                  vice-versa. This is a loophole in the legislation itself, as
                  it doesn’t require banks to also publish data for their
                  deferred taxes on top of the figures for taxes paid.
                </dd>
                <dt>Productivity imbalance</dt>
                <dd>
                  This indicator compares the profits of a single operation to
                  the profits that banks could expect if employees in the
                  jurisdiction would be equally productive as on average.
                  Positive (+) numbers indicate how much more the bank makes in
                  the jurisdiction than it would on average, whereas negative
                  (-) numbers indicate the opposite.
                </dd>
              </dl>
              <p>
                For a more comprehensive understanding of banks’ payments, we
                strongly encourage you to analyse the reports of the different
                banks directly. Some banks also provide additional data on a
                voluntary basis as part of their public reporting.
              </p>
            </div>
          </div>
        </section>

        <section className="hero is-about">
          <div id="acknowledgements" className="hero-body">
            <div className="container content">
              <h1 className="title">Acknowledgements</h1>
              <p>
                Transparency International EU would like to thank everyone who
                contributed to all stages of the development of the Corporate
                Tax Tracker, in particular Raphael Kergueno and Lucinda Pearson
                (Transparency International EU), Petr Jansky (Charles University
                of Prague), Quentin Parrinello (Oxfam France), Louise Crawford
                (University of Newcastle), Renzo Cordina (University of Dundee),
                Tommaso Faccio (University of Nottingham), Sargon Nissan
                (Financial Transparency Coalition) and David Power (University
                of Dundee).
              </p>
            </div>
          </div>
        </section>

        <section className="hero is-about">
          <div id="contact-information" className="hero-body">
            <div className="container content">
              <h1 className="title">Contact Information</h1>
              <p>
                For any questions or inquiries related to the platform itself,
                please contact Elena Gaita at{' '}
                <a href="mailto:egaita@transparency.org">
                  egaita@transparency.org
                </a>
                .
              </p>
              <p>
                The technical development of the platform has been done by{' '}
                <a href="//www.lattecreative.com/">Latte Creative</a>.
              </p>
              <p>Contact information of Transparency International EU:</p>
              <address>
                Rue de l’Industrie 10,
                <br />
                1000 Brussels, Belgium
                <br />
                Telephone: +32 (0) 2 893 24 58
                <br />
                Email:{' '}
                <a href="mailto:brussels@transparency.org">
                  brussels@transparency.org
                </a>
              </address>
              <br />
              <br />
              <p>
                <small>
                  This Corporate Tax Tracker database is made available under
                  the Open Database License:{' '}
                  <a href="//opendatacommons.org/licenses/odbl/1.0/">
                    http://opendatacommons.org/licenses/odbl/1.0/
                  </a>
                  . Any rights in individual contents of the database are
                  licensed under the Database Contents License:{' '}
                  <a href="//opendatacommons.org/licenses/dbcl/1.0/">
                    http://opendatacommons.org/licenses/dbcl/1.0/
                  </a>
                </small>
              </p>
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}

export default About;
