import React, { Component } from 'react';
import Scrollbar from 'react-scrollbars-custom';
// import classNames from 'classnames';

import Loading from '../../components/Loading';
import BarWithLabels from '../../components/BarWithLabels';
import _ from '../../utils/lodashImporter';
import { COLOR_TAX, COLOR_PROFIT, COLOR_TURNOVER } from '../../VARS';

class AllBanksBar extends Component {
  constructor(props) {
    super(props);

    // this.state = {
    //   isNotScrollTop: false,
    //   isNotScrollBottom: true,
    // };

    // store computed
    this.year = null;
    this.data = [];
    this.tooltipData = [];
    this.colorBy = 'id';

    // store input
    this.sortBy = null;
    this.hoveredBank = null;
    this.hoveredBankId = -1;
    this.selectedBank = null;
    this.selectedBankId = -1;
  }

  _mapData(data) {
    return data.map(bank => {
      return {
        Name: bank.bank,
        Tax: bank.Totals.Tax,
        Profit: bank.Totals.Profit,
        Turnover: bank.Totals.Turnover,
        Employees: bank.Totals.Employees,
      };
    });
  }

  _getTop(index, total) {
    // Hardcoding this 'cause we have no time. Hope it doesn't brake. I hate this.
    // Ps: it's the actual height occupied by the bars without top/bottom air
    const height = 460;
    const barHeight = height / total;
    const halfHeight = barHeight / 2;
    return height - (barHeight * index - halfHeight);
  }

  render() {
    const { year, data, sortBy, hoveredBank, selectedBank } = this.props;

    if (!data)
      return (
        <div className="loading is-bar-chart">
          <Loading />
        </div>
      );

    // memoize data on first render, and on year change
    if ((!this.data.length || this.year !== year) && data) {
      this.data = this._mapData(data);
    }

    // memoize sort on first render, and on year change
    let sortDir;
    if (this.sortBy !== sortBy || this.year !== year) {
      this.sortBy = sortBy;
      sortDir = sortBy === 'Name' ? 'desc' : 'asc';
      this.data = _.orderBy(this.data, sortBy, sortDir);
    }

    this.year = year;

    // memoize hovered
    if (
      this.hoveredBank !== hoveredBank ||
      this.selectedBank !== selectedBank
    ) {
      // 1. find ID of hovered and selected bank and memoize
      this.hoveredBankId = _.findIndex(this.data, {
        Name: hoveredBank,
      });
      this.selectedBankId = _.findIndex(this.data, {
        Name: selectedBank,
      });

      // 2. customize color function to provide for different opacities
      this.colorBy = (hoveredBankId => {
        return datum => {
          let color = 'rgba(255, 255, 255, 1)';
          let opacity = 1;
          switch (datum.id) {
            case 'Tax':
              color = COLOR_TAX;
              break;

            case 'Profit':
              color = COLOR_PROFIT;
              break;

            case 'Turnover':
              color = COLOR_TURNOVER;
              break;

            default:
              break;
          }

          // hover, selected, what? opacity is an ugly beast...
          if (selectedBank !== '*') {
            if (datum.indexValue !== selectedBank) opacity -= 0.8;
            if (hoveredBankId >= 0 && datum.index === hoveredBankId)
              opacity += 0.4;
          } else {
            if (hoveredBankId >= 0 && datum.index !== hoveredBankId)
              opacity -= 0.4;
          }

          color = color.replace('1)', opacity + ')');

          return color;
        };
      })(this.hoveredBankId);

      // 3. memoize
      this.hoveredBank = hoveredBank;
      this.selectedBank = selectedBank;
    }

    // const operationsClassName = classNames('chart-operations', {
    //   'is-selected': selectedBank === '*',
    //   'is-not-scroll-top': this.state.isNotScrollTop,
    //   'is-not-scroll-bottom': this.state.isNotScrollBottom,
    // });

    return (
      <Scrollbar
        className="chart-operations"
        maximalThumbSize={120}
        noScrollX={true}
        style={{ height: '600px' }}
        /*onUpdate={scrollValues => {
          const sv = scrollValues;
          const isAtTop = sv.scrollTop <= 0;
          const isAtBottom =
            sv.scrollTop >= sv.contentScrollHeight - sv.clientHeight;
          this.setState({
            isNotScrollTop: !isAtTop,
            isNotScrollBottom: !isAtBottom,
          });
        }}*/
      >
        <BarWithLabels
          className="chart-operations__chart"
          data={this.data}
          keys={['Tax', 'Profit', 'Turnover']}
          indexBy="Name"
          colors={this.colorBy}
          height={this.props.height}
          axisTop={true}
          format="num"
          onMouseEnter={this.props.onHover}
          onMouseLeave={this.props.onOut}
          onClick={this.props.onClick}
        />
        {/*<div
          className="chart-operations__pointer"
          style={{
            top: this._getTop(this.selectedBankId + 1, this.data.length),
          }}
        />*/}
      </Scrollbar>
    );
  }
}

export default AllBanksBar;
