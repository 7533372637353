import React from 'react';

// https://docs.google.com/document/d/16rabgVr0K8lPNo53fNn0Gv8idap9d00ebUR2hKxS3vE/edit
const rates = [
  {
    cur: 'GBP',
    '2015': 1.3782,
    '2016': 1.2239,
    '2017': 1.1413,
    '2018': 1.304,
    '2019': 1.1399,
  },
  {
    cur: 'USD',
    '2015': 0.9016,
    '2016': 0.9039,
    '2017': 0.887,
    '2018': 0.8476,
    '2019': 0.8934,
  },
  {
    cur: 'DKK',
    '2015': 0.134072,
    '2016': 0.13432,
    '2017': 0.134433,
    '2018': 0.134171,
    '2019': 0.133939,
  },
  {
    cur: 'CHF',
    '2015': 0.9374,
    '2016': 0.9174,
    '2017': 0.9006,
    '2018': 0.8661,
    '2019': 0.8992,
  },
  {
    cur: 'PLN',
    '2015': 0.2391,
    '2016': 0.2292,
    '2017': 0.2349,
    '2018': 0.2347,
    '2019': 0.2327,
  },
  {
    cur: 'SEK',
    '2015': 0.1069,
    '2016': 0.1057,
    '2017': 0.10381,
    '2018': 0.09752,
    '2019': 0.09446,
  },
  {
    cur: 'CZK',
    '2015': 0.03666,
    '2016': 0.03699,
    '2017': 0.038,
    '2018': 0.03899,
    '2019': 0.03896,
  },
  {
    cur: 'RUB',
    '2015': 0.01486,
    '2016': 0.01357,
    '2017': 0.01521,
    '2018': 0.01353,
    '2019': 0.01381,
  },
  {
    cur: 'HUF',
    '2015': 0.003226,
    '2016': 0.00321,
    '2017': 0.003234,
    '2018': 0.003137,
    '2019': 0.003127,
  },
  {
    cur: 'HRK',
    '2015': 0.1313,
    '2016': 0.1328,
    '2017': 0.134,
    '2018': 0.1348,
    '2019': 0.1348,
  },
  {
    cur: 'NOK',
    '2015': 0.1119,
    '2016': 0.1077,
    '2017': 0.1073,
    '2018': 0.10421,
    '2019': 0.10154,
  },
];

const CurrencyTable = () => {
  return (
    <table className="table is-narrow is-hoverable is-fullwidth">
      <caption>
        <a href="https://www.ecb.europa.eu/stats/policy_and_exchange_rates/euro_reference_exchange_rates/html/eurofxref-graph-usd.en.html">
          ECB annual averages
        </a>
      </caption>
      <thead>
        <tr>
          <th />
          <th>2015</th>
          <th>2016</th>
          <th>2017</th>
          <th>2018</th>
          <th>2019</th>
        </tr>
      </thead>
      <tbody>
        {rates.map(rate => (
          <tr key={rate.cur}>
            <td>{rate.cur}</td>
            <td>{rate['2015']}</td>
            <td>{rate['2016']}</td>
            <td>{rate['2017']}</td>
            <td>{rate['2018']}</td>
            <td>{rate['2019']}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default CurrencyTable;
