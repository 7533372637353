import React, { Component } from 'react';
import { ResponsiveBar } from '@nivo/bar';

import Tooltip from '../../components/Tooltip';
import Info from '../../components/Info';
import _ from '../../utils/lodashImporter';
import { formatNum, formatPerc, formatLabel } from '../../utils/helpers';
import {
  COLOR_PROFITABILITY,
  COLOR_PRODUCTIVITY,
  STIFFNESS,
  DUMPING,
  NIVO_THEMED,
  KEYS,
} from '../../VARS';

class Top10Bar extends Component {
  constructor(props) {
    super(props);

    // store computed
    this.year = null;
    this.data = { '*': [] };
    this.infinity = { '*': [] };
  }

  _mapTop10(data, type /* productivity|profitability */, selectedBank) {
    const divisor = type === 'Productivity' ? 'Employees' : 'Turnover';
    const selector = selectedBank === '*' ? 'Totals' : 'datapoints';

    let iterateOver = data;
    if (selectedBank !== '*') {
      iterateOver = _.find(data, { bank: selectedBank }).countries;
    }

    iterateOver.sort((a, b) => {
      const aValue = a[selector].Profit / a[selector][divisor];
      const bValue = b[selector].Profit / b[selector][divisor];

      // First, push all Infinity to the bottom
      if (!isFinite(aValue) && !isFinite(bValue)) return 0;
      if (!isFinite(aValue)) return 1;
      if (!isFinite(bValue)) return -1;

      return (
        b[selector].Profit / b[selector][divisor] -
        a[selector].Profit / a[selector][divisor]
      );
    });

    let top10 = iterateOver.slice(0, 10);

    top10 = top10.map(country => {
      return {
        country: country.country,
        [type]: country[selector].Profit / country[selector][divisor],
      };
    });

    // Discard negative values so the bars don't appear to float
    top10 = top10.filter(country => country[type] > 0);

    return top10;
  }

  _mapInfinity(data, type /* productivity|profitability */, selectedBank) {
    const divisor = type === 'Productivity' ? 'Employees' : 'Turnover';
    const selector = selectedBank === '*' ? 'Totals' : 'datapoints';

    let iterateOver = data;
    if (selectedBank !== '*') {
      iterateOver = _.find(data, { bank: selectedBank }).countries;
    }

    let infinity = iterateOver.filter(
      value => value[selector][divisor] === 0 && value[selector].Profit !== 0
    );
    infinity = infinity.map(country => {
      return {
        country: country.country,
        Profit: country[selector].Profit,
      };
    });

    return infinity;
  }

  render() {
    const { year, dataByCountry, dataByBank, type, selectedBank } = this.props;

    if (!dataByCountry) return null;

    // memoize data on first render, and on year change
    if (
      !this.data[selectedBank] ||
      !this.data[selectedBank].length ||
      this.year !== year
    ) {
      this.data[selectedBank] = this._mapTop10(
        selectedBank === '*' ? dataByCountry : dataByBank,
        type,
        selectedBank
      );
      this.infinity[selectedBank] = this._mapInfinity(
        selectedBank === '*' ? dataByCountry : dataByBank,
        type,
        selectedBank
      );
      this.year = year;
    }

    const tooltips = [
      'Labour productivity means the amount of profits (before tax) a bank or a corporation makes per employee in a certain jurisdiction. Comparing the productivity between different operations can reveal discrepancies in real economic activities.',
      'Profitability of the operations in a jurisdiction is measured by the amount of profits a bank or a corporation makes in comparison to their turnover. As the current country-by-country reports only provide information on pre-tax profits, this ratio is calculated by dividing profits made in a selected jurisdiction by the overall turnover in that jurisdiction. Extremely high profits compared to the turnover in a jurisdiction, and low ones in another, may indicate profit shifting or other corporate arrangements.',
    ];
    const disclaimers = [
      'The following countries cannot be visualized, as the bank declares profits without having any employees in them: ',
      'The following countries cannot be visualized, as the bank declares profits without paying any taxes: ',
    ];

    if (selectedBank === '*') {
      tooltips[0] = tooltips[0].replace(
        'a bank or a corporation makes',
        'the banks make'
      );
      tooltips[1] = tooltips[1].replace(
        'a bank or a corporation makes',
        'the banks make'
      );
      disclaimers[0] = disclaimers[0].replace(
        'the bank declares',
        'the banks declare'
      );
      disclaimers[1] = disclaimers[1].replace(
        'the bank declares',
        'the banks declare'
      );
    }

    return (
      <div className="extra-bar">
        <div className="extra-bar__title chart-title">
          <div>
            {type === 'Productivity'
              ? 'Top 10 Most Productive Locations'
              : 'Top 10 Most Profitable Locations'}
            <br />
            <Info
              position="left"
              text={type === 'Productivity' ? tooltips[0] : tooltips[1]}
            />
          </div>
        </div>
        <div style={{ height: '250px' }}>
          <ResponsiveBar
            data={this.data[selectedBank]}
            keys={[type, '']}
            indexBy="country"
            colors={
              type === 'Productivity' ? COLOR_PRODUCTIVITY : COLOR_PROFITABILITY
            }
            padding={0.35}
            margin={{
              top: 0,
              right: 0,
              bottom: 130,
              left: 65,
            }}
            axisLeft={null}
            axisBottom={{
              orient: 'bottom',
              tickSize: 0,
              tickPadding: 15,
              tickRotation: -45,
              tickValueFormat: formatLabel,
              legend: '',
              legendPosition: 'middle',
              legendOffset: 36,
            }}
            enableGridY={false}
            enableLabel={false}
            motionStiffness={STIFFNESS}
            motionDamping={DUMPING}
            theme={NIVO_THEMED}
            tooltip={({ data }) => (
              <Tooltip title={data.country}>
                <div className="columns">
                  {KEYS.map((key, i) => {
                    if (!data[key]) return null;

                    const formatFn =
                      ['Profitability'].indexOf(key) >= 0
                        ? formatPerc
                        : formatNum;
                    const suffix =
                      ['Turnover', 'Profit', 'Tax'].indexOf(key) >= 0
                        ? ' M€'
                        : '';

                    return (
                      <div className="column" key={i}>
                        <div className={`charttip__figure is-${key}`}>
                          {formatFn(data[key])} {suffix}
                        </div>
                        <div className="charttip__caption">
                          {key === 'Productivity' ? key + ' (M€/FTE)' : key}
                        </div>
                      </div>
                    );
                  })}
                </div>
                {data.country.indexOf('Channel') === 0 ? (
                  <div className="charttip__caption">
                    <span>
                      <i>
                        The bank aggregates multiple
                        <br /> jurisdictions in its report
                      </i>
                    </span>
                  </div>
                ) : null}
              </Tooltip>
            )}
          />
        </div>
        {this.infinity[selectedBank].length ? (
          <div className={`infinity-disclaimer is-${type}`}>
            {type === 'Productivity' ? disclaimers[0] : disclaimers[1]}
            {this.infinity[selectedBank].map((item, i) => (
              <span
                key={item.country}
                className="tooltip is-tooltip-top"
                data-tooltip={`${formatNum(
                  item.Profit
                )} M€ in profit declared here.`}
              >
                <b>{item.country}</b>
                {this.infinity[selectedBank].length === i + 1 ? '.' : ','}
              </span>
            ))}
          </div>
        ) : null}
      </div>
    );
  }
}

export default Top10Bar;
