import React from 'react';
import TrayCarousel from '../../../components/TrayCarousel';

const Carousel2015 = ({ handleClick }) => {
  return (
    <TrayCarousel>
      <div className="did-you-know__trivia">
        <button className="a" onClick={handleClick}>
          Barclays
        </button>{' '}
        did not pay any corporate tax on its profits of €1,558m in the UK and
        €780.83m in Luxembourg. The bank did not pay any tax on its income in
        seven other jurisdictions.
      </div>
      <div className="did-you-know__trivia">
        <button className="a" onClick={handleClick}>
          Standard Chartered
        </button>{' '}
        did not pay any corporate tax on its UK profits of €795.13m.
      </div>
      <div className="did-you-know__trivia">
        <button className="a" onClick={handleClick}>
          KfW
        </button>{' '}
        paid effective rate of 0.01% on its profits.
      </div>
      <div className="did-you-know__trivia">
        <button className="a" onClick={handleClick}>
          Barclays
        </button>{' '}
        paid only 5.2% of its profits in corporate income tax.
      </div>
      <div className="did-you-know__trivia">
        <button className="a" onClick={handleClick}>
          RBS
        </button>{' '}
        paid zero income tax on its €197m of profit in the Netherlands. It has
        paid no corporate taxes in the Netherlands since 2015.
      </div>
      <div className="did-you-know__trivia">
        <button className="a" onClick={handleClick}>
          RBS
        </button>{' '}
        did not pay any tax on almost €1 billlion of its profits.
      </div>
    </TrayCarousel>
  );
};

export default Carousel2015;
