import React from 'react';
import TrayCarousel from '../../../components/TrayCarousel';

const Carousel2015 = ({ handleClick }) => {
  return (
    <TrayCarousel>
      <div className="did-you-know__trivia">
        <button className="a" onClick={handleClick}>
          Barclays
        </button>{' '}
        made €651m profits in Luxembourg, while reporting only 42 employees in
        the country. If these 42 employees had performed like the bank's average
        employee, Barclays would have made €3m in profits there.
      </div>
      <div className="did-you-know__trivia">
        <button className="a" onClick={handleClick}>
          Deutsche Bank
        </button>{' '}
        made nearly two billion euros in profits (€1953m) in Luxembourg.
        However, if the 512 employees reported in Luxembourg had been as
        productive as the Deutsche Bank’s average, the profits would have been
        only €13m.
      </div>
      <div className="did-you-know__trivia">
        <button className="a" onClick={handleClick}>
          HSBC
        </button>{' '}
        reported the highest turnover out of the EU based banks, totalling
        nearly 56 billion euros.
      </div>
      <div className="did-you-know__trivia">
        <button className="a" onClick={handleClick}>
          DZ Bank
        </button>{' '}
        operated in Jersey and The Cayman Islands without paying any taxes, nor
        employing anyone. The bank still managed to generated €23m of profits in
        The Cayman Islands and €3m of profits in Jersey.
      </div>
      <div className="did-you-know__trivia">
        The operations of{' '}
        <button className="a" onClick={handleClick}>
          RBS
        </button>{' '}
        had profits equal to its €54m turnover in Saudi Arabia. The bank did not
        report any employees, nor paid any taxes in the country.
      </div>
      <div className="did-you-know__trivia">
        <button className="a" onClick={handleClick}>
          Intesa Sanpaolo
        </button>{' '}
        turned €329m of its €399m turnover in their US operations into profits.
        The bank paid only €14m of taxes on these profits.
      </div>
    </TrayCarousel>
  );
};

export default Carousel2015;
